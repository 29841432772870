import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
    if(payload[0]?.payload) {
        return <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          fontSize: "14px",
        }}
      >
        <p
          className="label"
          style={{ marginBottom: "5px" }}
        >{`Дата (м/д): ${payload[0].payload.date}`}</p>
        <p
          className="intro"
          style={{ color: "gray" }}
        >{`Кол-во: ${payload[0].payload.count}`}</p>
      </div> 
    }

    return null
}

export function ChartData({ data, field }) {
  const [chart, setChart] = useState([]);

  useEffect(() => {
    if (!data) return;
  
    const newData = data.reduce((array, currentValue) => {
      const date = currentValue[field].slice(5, 10);
  
      const existingEntry = array.find((entry) => entry.date === date);
  
      if (existingEntry) {
        existingEntry.count += 1; 
      } else {
        array.push({ date, count: 1 });
      }
  
      return array;
    }, []);
  
    setChart(newData);
  }, [data]);

  return (
    <div style={{ width: "100%", height: 200 }}>
      <ResponsiveContainer>
        <LineChart
          data={chart}
          margin={{ top: 5, right: 10, left: -20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line
            type="monotone"
            dataKey="count"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
