import { useEffect, useState } from "react";
import { isAdmin } from "../../../App";
import appAPI from "../../../api/service";

export function useProducts(filters, currentStep, search) {

    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([]);
    const [stop, setStop] = useState(false)

    async function getProducts(page, article = "", status = "all", currentStep = "all") {
        if(stop || isLoading) return 
        setIsLoading(true)
        try {
            if(page === 1) setOrders([])
            const response = await appAPI.getAdminOrdersUser(page, 20, {
                article,
                currentStep,
                status,
                isAdmin
            });
            if(response.data.length < 20) setStop(true)
                setOrders(prev => prev.concat(response.data));
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setStop(false);
        setPage(1);
    }, [filters, currentStep, search]);

    
    useEffect(() => {
        getProducts(page, search || "", filters, currentStep);
    }, [page, filters, currentStep, search, stop]); 
    
      
    return {orders, isLoading, setPage}
}