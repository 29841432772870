export const renderElement = (element) => {
    switch (element.type) {
      case 'paragraph':
        return <p style={{fontWeight: element.bold === true ? "bold": ""}}>{renderChildren(element.children)}</p>;
      case 'heading':
        const HeadingTag = `h${element.level || 1}`; // уровень заголовка (например, h1, h2)
        return <HeadingTag>{renderChildren(element.children)}</HeadingTag>;
      default:
        return null;
    }
  };

  const renderChildren = (children, noFirst = false) => {
    return children.map((child, index) => {
      switch (child.type) {
        case 'text':
          if((String(child.text).includes("\n" ) ||  child.text === "") && !noFirst) return <div style={{height:"15px"}}></div>
          return <span style={{fontWeight: child.bold === true ? "bold": ""}} key={index}>{child.text}</span>;
          case 'link':
            return <span style={{fontWeight: child.bold === true ? "bold": "", color:"#0088cc", cursor:'pointer'}} onClick={() => window.open(child.url)} key={index}>{renderChildren(child.children, true)}</span>;
        default:
          return null;
      }
    });
  };