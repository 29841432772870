import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Task1 from "./tasks/task1";
import Task2 from "./tasks/task2";
import Task3 from "./tasks/task3";
import Task4 from "./tasks/task4";
import Task5 from "./tasks/task5";
import Task6 from "./tasks/task6";
import Task7 from "./tasks/task7";
import appAPI, { baseURL } from "../../api/service";
import Loader from "../helpers/loader/loader";
import Back from "../helpers/back";
import AlreadyPurchased from "../compoents/AlreadyPurchased";
import { Avatar, Button, Cell, List, Modal, Text } from "@telegram-apps/telegram-ui";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { SecondaryButton } from "../../shared/telegram-elements/MainButton";
import { FooterBlock } from "./components/FooterBlock";

const Deal = ({ userInfo }) => {
  const { id } = useParams();
  const nav = useNavigate();
  const [taskNow, setTask] = useState(null);
  const [orderInfo, setOrder] = useState(null);

  const [wantBack, setWantBack] = useState(false);
  const [wantReturn, setWantRertun] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleCancel = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await appAPI.returnOrder(orderInfo.id);

      if (response.success === false) {
        return toast.error("Попробуйте позже");
      }
      nav("/");
      return toast.success("Сделка успешно отменена");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await appAPI.cancelOrder(window.Telegram.WebApp.initDataUnsafe.user.id, orderInfo.id);

      if (!response.success || response.success === false) {
        return toast.error("Попробуйте позже");
      }

      toast.success("Сделка успешно удалена");
      
      return setTimeout(() => {
        nav("/");
        window.location.reload()
      }, 1000)
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetch() {
      const response = await appAPI.getOrder(id, window.Telegram.WebApp.initDataUnsafe.user.id);
      if (response.success === false) {
        return toast.error("Ошибка при получении заказа");
      }
      if (response.data === null) {
        return setOrder("error");
      }
      setOrder(response.data);
      setTask(response.data.attributes.step);
      if (response.data.attributes.status == "На доработке") {
        return nav(`/orders/edit/${orderInfo?.id}`);
      }
      if (response.data.attributes.step === 8) {
        return nav(`/result/${id}`);
      }
    }
    fetch();
  }, []);

  let modalDelete = (
    <Modal
      style={{ zIndex: "1000000000" }}
      header={<ModalHeader>Подтвердите удаление</ModalHeader>}
      open={wantReturn}
      onOpenChange={(open) => {
        if (!open) setWantRertun(null);
      }}
    >
      <>
        <List style={{ paddingBottom: "30px" }}>
          <div>
            <Text>
              Данное действие приведет к удалению данной сделки. После данного
              действия управлять сделкой будет невозможно. Вы уверены, что
              хотите продолжить?
            </Text>
          </div>

          <Button onClick={handleDelete} stretched>
            Подтверждаю
          </Button>
        </List>
      </>
    </Modal>
  );

  const DeleteOrderComponent = {
    delete: (
      <>
        <FooterBlock
          cancelType={"reject"}
          cancelFunction={() => setWantRertun(true)}
          order={orderInfo}
        />
      </>
    ),
    back: (
      <>
        <FooterBlock
          cancelType={"return"}
          cancelFunction={() => setWantBack(true)}
          order={orderInfo}
          step={taskNow}
        />
      </>
    ),
  }[
    taskNow <= 5 ? "delete" : 
    orderInfo.attributes.status !== "Оплачено" ? "back" : 
    ""
  ];

  if (orderInfo === null) return <Loader />;

  if (orderInfo === "error")
    return (
      <div className="order__404" style={{ textAlign: "center" }}>
        <Back />
        Данный товар не найден
        {modalDelete}
        <FooterBlock
          cancelType={"404"}
          cancelFunction={() => setWantRertun(true)}
          order={orderInfo}
        />
      </div>
    );

  if (
    orderInfo.attributes.offer.data.attributes.inCatalog !== true &&
    parseInt(orderInfo.attributes.step) <= 5
  )
    return (
      <div className="order__404" style={{ textAlign: "center" }}>
        <Back />
        Данный товар снят в раздачи
        {modalDelete}
        <FooterBlock
          cancelType={"404"}
          cancelFunction={() => setWantRertun(true)}
          order={orderInfo}
        />
      </div>
    );

  const uniqueBrands = userInfo.attributes.orders.data.reduce((acc, order) => {
    const brand = order.attributes.offer.data.attributes.brandName;
    if (brand && !acc.includes(brand) && order.attributes.step5Date) {
      acc.push(brand);
    }
    return acc;
  }, []);

  const goodSellerUsername =
    orderInfo.attributes.offer.data.attributes.brandName;
  const hasUserOrderedFromSeller = uniqueBrands.some(
    (brand) => brand === goodSellerUsername
  );

  const unsuccessOrders = userInfo.attributes.orders.data.filter((order) => {
    return (
      order.attributes.status !== "Оплачено" &&
      Number(order.attributes.step) >= 6 &&
      order.attributes.offer.data.attributes.brandName === goodSellerUsername
    );
  });

  if (
    hasUserOrderedFromSeller &&
    unsuccessOrders.length > 0 &&
    Number(orderInfo.attributes.step) <= 5
  ) {
    return (
      <div className="order__404" style={{ textAlign: "center" }}>
        <Back />
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            У вас есть активная сделка у этого продавца. Как только продавец
            выплатит кэшбэк вы сможете купить другие его товары
          </div>
          <div
            onClick={() => nav("/catalog")}
            style={{
              height: "46px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "15px",
              color: "white",
              background: "#731358",
              borderRadius: "8px",
            }}
          >
            Вернуться в каталог
          </div>
        </div>
        {modalDelete}
        <FooterBlock
          cancelType={"404"}
          cancelFunction={() => setWantRertun(true)}
          order={orderInfo}
        />
      </div>
    );
  }

  if (
    (parseInt(orderInfo.attributes.offer.data.attributes.dealsLimit) <= 0 ||
      parseInt(
        orderInfo.attributes.offer.data.attributes.dealsDayLimitDisplay
      ) <= 0 ||
      orderInfo.attributes.offer.data.attributes?.owner?.data?.attributes
        ?.limitsBalance == null ||
      Number(
        orderInfo.attributes.offer.data.attributes.owner.data.attributes
          .limitsBalance
      ) <= 0) &&
    parseInt(orderInfo.attributes.step) <= 5
  )
    return (
      <AlreadyPurchased
        username={orderInfo?.attributes?.sellerUsername}
        id={orderInfo.attributes.offer.data.id}
        NeedHelp={<FooterBlock
          cancelType={"404"}
          cancelFunction={() => setWantRertun(true)}
          order={orderInfo}
        />}
      />
    );

  return (
    <>
      <Back func={() => nav("/cart")} />
      <div className="title_page">
        <div style={{ textAlign: "center" }}>№: {orderInfo.id}</div>
      </div>
      <div style={{padding: "0 16px"}}>
      <Cell
        
        before={
          <Avatar
            src={
              baseURL +
              orderInfo.attributes.offer.data.attributes.image.data.attributes
                .url
            }
          />
        }
        className="section"
        description={
          "Цена для вас: " +
          (orderInfo.attributes.priceForUserOffer
            ? orderInfo.attributes.priceForUserOffer
            : orderInfo.attributes.offer.data.attributes.priceForUser) +
          " руб"
        }
      >
        {orderInfo.attributes.offer.data.attributes.name}
      </Cell>
      </div>
      {taskNow === 1 && (
        <Task1
          setTask={setTask}
          orderInfo={orderInfo}
          DeleteOrderComponent={DeleteOrderComponent}
        />
      )}
      {taskNow === 2 && (
        <Task2
          setTask={setTask}
          orderInfo={orderInfo}
          DeleteOrderComponent={DeleteOrderComponent}
        />
      )}
      {taskNow === 3 && (
        <Task3
          setTask={setTask}
          orderInfo={orderInfo}
          DeleteOrderComponent={DeleteOrderComponent}
        />
      )}
      {taskNow === 4 && (
        <Task4
          setTask={setTask}
          orderInfo={orderInfo}
          DeleteOrderComponent={DeleteOrderComponent}
        />
      )}
      {taskNow === 5 && (
        <Task5
          setTask={setTask}
          orderInfo={orderInfo}
          DeleteOrderComponent={DeleteOrderComponent}
        />
      )}
      {taskNow === 6 && (
        <Task6
          setTask={setTask}
          orderInfo={orderInfo}
          DeleteOrderComponent={DeleteOrderComponent}
        />
      )}
      {taskNow === 7 && (
        <Task7
          setTask={setTask}
          orderInfo={orderInfo}
          DeleteOrderComponent={DeleteOrderComponent}
        />
      )}

      <Modal
        style={{ zIndex: "1000000000" }}
        header={<ModalHeader>Подтвердите возврат</ModalHeader>}
        open={wantBack}
        onOpenChange={(open) => {
          if (!open) setWantBack(null);
        }}
      >
        <>
          <List style={{ paddingBottom: "30px" }}>
            <div>
              <Text>
                Данное действие приведет к блокировке данной сделки. После
                данного действия управлять сделкой будет невозможно. Вы уверены,
                что хотите продолжить?
              </Text>
            </div>

            <Button onClick={handleCancel} stretched>
              Подтверждаю
            </Button>
          </List>
        </>
      </Modal>

      {modalDelete}
    </>
  );
};
export default Deal;
