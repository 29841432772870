import { Cell, Input, Section, Switch } from "@telegram-apps/telegram-ui";

export function CheckboxComponent({
  value,
  onChange,
  description,
  title,
  disabled = false,
  ...actions
}) {
  return (
    <Cell
      className="cell__section"
      {...actions}
      Component="label"
      after={
        <Switch
          checked={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
      description={description}
      multiline={description ? true : false}
    >
      {title}
    </Cell>
  );
}
