import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Back from "../helpers/back";
import axios from "axios";
import appAPI, { baseURL } from "../../api/service";

const Create = ({ userInfo }) => {
  const nav = useNavigate();
  const [brandStatus, setBrandStatus] = useState(false);
  const [image, setImage] = useState(null);
  const [values, setValues] = useState({
    title: "",
    category: null, //null
    brand: "",
    brandName: "",
    price_site: 0, //null
    price_client: 0, //null
    photo: null,
    amount: 0,
    dealsDayLimit: 10,
    commentRules: "",
    keywords: "",
    articul: "",
    link: "-",
    conditionals: "-",
    description_3: "0",
    cashbackType: "", //null
    description: "-", //"",
    test: false,
    withInstruction: false,
  });
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  function getQueryParams(data) {
    return new URLSearchParams(location.search).get(data)
      ? new URLSearchParams(location.search).get(data)
      : "";
  }

  const handleSetImage = (image) => {
    if (!image) return;
    const fileReader = new FileReader(image);
    fileReader.onload = () => {
      setImage({ img: fileReader.result, file: image });
      setValues({ ...values, photo: image });
    };
    fileReader.readAsDataURL(image);
  };

  const handleSubmit = async () => {
    if (loading === true) return;
    const isAllFieldsFilled = Object.entries(values).every(([key, val]) => {
      if (values.withInstruction) {
        if (["keywords", "commentRules"].includes(key)) {
          return true;
        }
      }
      return (
        key === "withInstruction" ||
        key === "test" ||
        (val !== "" && val !== null)
      );
    });

    if (isAllFieldsFilled && image) {
      const formData = new FormData();
      formData.append("files", image.file);
      setLoading(true);
      try {
        toast.loading("Загрузка");
        const response = await appAPI.uploadImage(formData);
        if (!response[0]) {
          return toast.error("Ошибка при загрузке картинки");
        }

        const data = {
          name: values.title,
          brand: values.brand,
          brandName: values.brandName,
          article: values.articul,
          image: response[0].id, //432
          wbLink: values.link,
          keywords: values.keywords,
          owner: userInfo.id,
          dealsLimit: values.amount,
          dealsDayLimit: values.dealsDayLimit,
          dealsDayLimitDisplay: values.dealsDayLimit,
          priceOnSite: values.price_site,
          priceForUser: values.price_client,
          categories: values.category,
          description: values.conditionals,
          howSearch: values.description_3,
          commentRules: values.commentRules,
          cashbackType: values.cashbackType,
          description: values.description,
          withInstruction: !values.withInstruction,
          Seller: {
            telegramId: window.Telegram.WebApp.initDataUnsafe.user.id,
          },
          creatorID: String(window.Telegram.WebApp.initDataUnsafe.user.id),
          test: values.test,
        };

        if (getQueryParams("ref_create") !== "") {
          data["refID"] = getQueryParams("ref_create");
        }

        let array = ["keywords", "commentRules"];
        if (values.withInstruction) {
          for (let i = 0; i <= array.length; i++) {
            console.log(array[i]);
            data[array[i]] = "";
          }

          data["test"] = false;
        }

        const res = await appAPI.createOffer(data);
        if (!res || res.data === null) {
          return toast.error("Ошибка при создании");
        }
        toast.dismiss();
        toast.success("Успешно создан");
        return nav("/admin/offers/"+res.data.id);
      } catch (error) {
        toast.dismiss();
        console.error(error);
        toast.error("Ошибка при создании");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Заполните все поля");
    }
  };

  return (
    <>
      <Back />
      <div
        className="title_page"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        <div
          onClick={() => nav("/")}
          style={{
            width: "fit-content",
            fontSize: "13px",
            cursor: "pointer",
            fontSize: "0.75rem",
            textTransform: "uppercase",
          }}
        >
          Отменить
        </div>
        Добавить товар
        <div
          onClick={handleSubmit}
          style={{
            width: "fit-content",
            textAlign: "end",
            cursor: "pointer",
            fontSize: "0.75rem",
            color: "#731358",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          Отправить
        </div>
      </div>
      <div className="container create_good">
        {/*<div className="input_question_block">
        <label className="input_brand">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={values.withInstruction}
            onChange={(e) =>
              setValues({
                ...values,
                withInstruction: e.target.checked
              })
            }
          />
          <span></span>
          <div className="brand_question">
            Товар без инструкции
            <br />
            <div className="require_question">
              Поставьте галочку, чтобы товар был без инструкции
            </div>
          </div>
        </label>
      </div>*/}

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Название товара
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите название товара"
              value={values.title}
              onChange={(e) => setValues({ ...values, title: e.target.value })}
            />
          </label>
        </div>

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Бренд товара
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите бренд товара"
              value={values.brandName}
              onChange={(e) =>
                setValues({ ...values, brandName: e.target.value })
              }
            />
          </label>
        </div>

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Артикул<div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите артикул"
              value={values.articul}
              onChange={(e) =>
                setValues({ ...values, articul: e.target.value })
              }
            />
          </label>
        </div>
        <div className="hint_block" style={{ marginBottom: "10px" }}>
          Внимательно вводите номер артикула. По нему будет проводится
          подтверждение товара
        </div>

        <div className="image_question_block">
          <label className="input_question">
            <div>
              Фото товара<div className="require_question">Обязательное</div>
            </div>
            <label>
              <div>
                <div className="image_picker">
                  <div className="image_picker_info">
                    {image && <img className="image_select" src={image.img} />}
                    <div className="text_file_image">
                      {image ? image.file.name : "Выберите изображение..."}
                    </div>
                  </div>
                  <div>
                    {image && (
                      <svg
                        onClick={(e) => {
                          e.preventDefault();
                          setImage(null);
                          setValues({ ...values, photo: null });
                        }}
                        className="image_picker_close"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" fill="none" />
                        <path
                          d="M7 17L16.8995 7.10051"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 7.00001L16.8995 16.8995"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleSetImage(e.target.files[0])}
                placeholder="Выберите изображение..."
                style={{ display: "none" }}
              />
            </label>
          </label>
        </div>

        {
          <div className="input_question_block">
            <label className="input_question">
              <div>
                Категория <div className="require_question">Обязательное</div>
              </div>
              <select
                value={values.category}
                onChange={(e) =>
                  setValues({ ...values, category: e.target.value })
                }
              >
                <option value="">—</option>
                <option value="Женщинам">Женщинам</option>
                <option value="Мужчинам">Мужчинам</option>
                <option value="Обувь">Обувь</option>
                <option value="Детям">Детям</option>
                <option value="Дом">Дом</option>
                <option value="Новый год">Новый год</option>
                <option value="Красота">Красота</option>
                <option value="Аксессуары">Аксессуары</option>
                <option value="Электроника">Электроника</option>
                <option value="Игрушки">Игрушки</option>
                <option value="Мебель">Мебель</option>
                <option value="Товары для взрослых">Товары для взрослых</option>
                <option value="Бытовая техника">Бытовая техника</option>
                <option value="Зоотовары">Зоотовары</option>
                <option value="Спорт">Спорт</option>
                <option value="Автотовары">Автотовары</option>
                <option value="Ювелирные изделия">Ювелирные изделия</option>
                <option value="Для ремонта">Для ремонта</option>
                <option value="Сад и дача">Сад и дача</option>
                <option value="Здоровье">Здоровье</option>
                <option value="Канцтовары">Канцтовары</option>
              </select>
            </label>
          </div>
        }

        {!values.withInstruction && (
          <>
            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Ключевое слово
                  <div className="require_question">Обязательное</div>
                </div>
                <input
                  type="text"
                  placeholder={`Например "рубашка в клетку"`}
                  value={values.keywords}
                  onChange={(e) =>
                    setValues({ ...values, keywords: e.target.value })
                  }
                />
              </label>
            </div>
          </>
        )}

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Общий план по выкупам
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="number"
              placeholder="Общий план по выкупам"
              value={values.amount}
              onChange={(e) => setValues({ ...values, amount: e.target.value })}
            />
          </label>
        </div>

        {
          <div className="input_question_block">
            <label className="input_question">
              <div>
                План выкупов на сутки
                <div className="require_question">Обязательное</div>
              </div>
              <input
                type="number"
                placeholder="План выкупов на сутки"
                value={values.dealsDayLimit}
                onChange={(e) =>
                  setValues({ ...values, dealsDayLimit: e.target.value })
                }
              />
            </label>
          </div>
        }

        {
          <div className="input_question_block">
            <label className="input_question">
              <div>
                Цена на вб (руб)
                <div className="require_question">Обязательное</div>
              </div>
              <input
                type="number"
                placeholder="Укажите цену на вб"
                value={values.price_site}
                onChange={(e) =>
                  setValues({ ...values, price_site: e.target.value })
                }
              />
            </label>
          </div>
        }
        {
          <div className="input_question_block">
            <label className="input_question">
              <div>
                Цена для покупателя
                <div className="require_question">Обязательное</div>
              </div>
              <input
                type="number"
                placeholder="Цена для покупателя"
                value={values.price_client}
                onChange={(e) =>
                  setValues({ ...values, price_client: e.target.value })
                }
              />
            </label>
          </div>
        }

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Телеграм ник для связи
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder={`Например "ruslanamantayev"`}
              value={values.brand}
              onChange={(e) => setValues({ ...values, brand: e.target.value })}
            />
          </label>
        </div>

        <div className="hint_block">
          Введенное имя преобразуется в ссылку для связи с вами:
          <br />
          <b>https://t.me/ruslanamantayev</b>
        </div>

        {/*<div className="input_question_block">
          <label className="input_brand">
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={values.writeToSeller}
              onChange={(e) =>
                setValues({
                  ...values,
                  writeToSeller: e.target.checked,
                })
              }
            />
            <span></span>
            <div className="brand_question">
              Показывать контакты покупателю
              <br />
              <div className="require_question">
                Поставьте галочку, чтобы ваши контакты отображались для покупателя
              </div>
            </div>
          </label>
        </div>*/}

        {
          <div className="input_question_block">
            <label className="input_question">
              <div>
                Когда выплата{" "}
                <div className="require_question">Обязательное</div>
              </div>
              <select
                value={values.cashbackType}
                onChange={(e) =>
                  setValues({ ...values, cashbackType: e.target.value })
                }
              >
                <option value="">—</option>
                <option value="После публикации отзыва">После отзыва</option>
                <option value="После получения товара">
                  После получения товара
                </option>
                <option value="После получения товара через 15 дней">
                  На 15й день
                </option>
              </select>
            </label>
          </div>
        }

        {/*<div className="input_question_block">
          <label className="input_question">
            <div>
              Дополнительные условия
            </div>
            <textarea
              type="text"
              placeholder={`Дополнительные условия`}
              value={values.description}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
            />
          </label>
        </div>*/}

        {!values.withInstruction && (
          <>
            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Требования к отзыву
                  <div className="require_question">Обязательное</div>
                </div>
                <textarea
                  type="text"
                  placeholder={`Опишите ваши требования к фото/видео/тексту/оценке/ `}
                  value={values.commentRules}
                  onChange={(e) =>
                    setValues({ ...values, commentRules: e.target.value })
                  }
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_brand">
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={values.test}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      test: e.target.checked,
                    })
                  }
                />
                <span></span>
                <div className="brand_question">
                  Согласовать отзыв
                  <br />
                  <div className="require_question">
                    Поставьте галочку, чтобы c вами согласовывали отзыв
                  </div>
                </div>
              </label>
            </div>
          </>
        )}
        {/*<div className="hint_block">
          Вводите ключевые слова через , как показано в примере: рубашка в
          клетку, рубашка в клетку мужская, рубашка, мужская в клетку, клетчатая
          рубашка
        </div>*/}

        {/*< <div className="hint_block">
          Вводите условия для отзыва через / как показано в примере: отзыв с
          фото/количество звезд 5
        </div>
        <div className="input_question_block">
          <label className="input_brand">
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={values.description_3 === "0" ? false : true}
              onChange={(e) =>
                setValues({
                  ...values,
                  description_3: e.target.checked ? "1" : "0",
                })
              }
            />
            <span></span>
            <div className="brand_question">
              Отзыв с фотографией
              <br />
              <div className="require_question">
                Поставьте галочку, чтобы отзыв был с фотографиями
              </div>
            </div>
          </label>
        </div> */}

        <div
          className="buttonTo"
          onClick={handleSubmit}
          style={{ marginTop: "16px" }}
        >
          Отравить заявку
        </div>
      </div>
    </>
  );
};

export default Create;
