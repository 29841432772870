import { Button, Cell, Info, List, Section, Text } from "@telegram-apps/telegram-ui";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import { useStatistics } from "./hooks/useStatistics";
import { useExportExcel } from "./hooks/useExportExcel";
import Loader from "../helpers/loader/loader";
import Back from "../helpers/back";
import { splitNumber } from "../../helpers/splitNumbers";


export function Statistics() {
    const {data, loading} = useStatistics()
    const {exportData, loading: exportLoading} = useExportExcel()


    if(loading) return <Loader />
    return (
        <List>
            <Back />
            <Text weight="2" style={{display:"block"}}>Статистика сделок</Text>
            <Button stretched onClick={() => exportData()} loading={exportLoading}>Экспорт сделок в Excel</Button>
            <Section className="section">
                <SectionHeader style={{padding:"0px"}}>Статистика по оплатам</SectionHeader>
                <Cell style={{padding:"0px"}} description="Общая сумма к оплате" after={<Info className="green" subtitle="рублей" type="text">{splitNumber(data?.paymentsAmount)}</Info>}>
                    Общая сумма выплат
                </Cell>
                <Cell style={{padding:"0px"}} description="Уже оплатили" after={<Info subtitle="рублей" type="text">{splitNumber(data?.paymentsAmountSuccess)}</Info>}>
                    Оплачено
                </Cell>
                <Cell style={{padding:"0px"}} description="Нужно оплатить" after={<Info className="red" subtitle="рублей" type="text">{splitNumber(data?.paymentsAmountPending)}</Info>}>
                    Не оплачено
                </Cell>
            </Section>

            <Section>
                <SectionHeader style={{padding:"0px"}}>Статистика по сделкам</SectionHeader>
                <Cell style={{padding:"0px"}} description="За все время" after={<Info className="green" subtitle="сделок" type="text">{splitNumber(data?.ordersCount)}</Info>}>
                    Кол-во сделок
                </Cell>
                <Cell style={{padding:"0px"}} description="Прошли этап оформления" after={<Info subtitle="сделок" type="text">{splitNumber(data?.ordersCountStep5)}</Info>}>
                    Оформили
                </Cell>
                <Cell style={{padding:"0px"}} description="Прошли этап подтверждения" after={<Info subtitle="сделок" type="text">{splitNumber(data?.ordersCountStep6)}</Info>}>
                    Забрали товар
                </Cell>
                <Cell style={{padding:"0px"}} description="Прошли этап написания отзыва" after={<Info subtitle="сделок" type="text">{splitNumber(data?.ordersCountStep7)}</Info>}>
                    Оставили отзыв
                </Cell>
            </Section>
        </List>
    )
}