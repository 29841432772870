import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../helpers/back";
import appAPI, { baseURL } from "../../api/service";
import Loader from "../helpers/loader/loader";
import { ImageComponent } from "../compoents/ImageComponent";
import { Section } from "@telegram-apps/telegram-ui";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import { ORDER_STEPS } from "../../shared/constants/order-steps";
import { InputComponent } from "../../shared/ui/Input";

const OrderEdit = ({ userInfo }) => {
  const nav = useNavigate();
  const { id } = useParams();

  const [orderInfo, setOrderInfo] = useState(null);

  const [values, setValues] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await appAPI.getOrder(id);
        const data = Object.entries(response.data.attributes)
          .filter(
            ([key, value]) =>
              key.includes("image") ||
              key.includes("cardNumber") ||
              key.includes("customerName") ||
              key.includes("phoneNumber") ||
              key.includes("bank") ||
              key.includes("customerCheck") ||
              key.includes("customerCheckNumber")
          )
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
        setValues(data);
        setOrderInfo(response.data);
      } catch (error) {
        console.error(error);
        toast.error("Ошибка при загрузке данных");
      }
    };

    fetchData();
  }, [id]);

  const handleSetImage = (image, name) => {
    setValues({ ...values, [name]: { ...image } });
  };

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const isAllFieldsFilled = true;
      if (isAllFieldsFilled) {
        const data = {
          ...values,
          image4: `${values?.cardNumber.trim() ?? ""}::${
            values?.phoneNumber.trim() ?? ""
          }::${values?.bank.trim() ?? ""}::${
            values?.customerName.trim() ?? ""
          }`,
        };
        for (let image in values) {
          if (
            (image.includes("image") || image == "customerCheck") &&
            !image.includes("image4")
          ) {
            if (values[image] && values[image].file) {
              const formData = new FormData();
              formData.append("files", values[image].file);
              try {
                const response = await appAPI.uploadImage(formData);

                console.log(response);

                if (response && !response[0]?.id) {
                  return toast.error("Ошибка при загрузке картинки");
                }

                data[image] = `https://api.wbcashback.ru${response[0].url}`;
              } catch (error) {
                console.error(error);
                return toast.error("Ошибка при загрузке картинки");
              }
            }
          }
        }
        try {
          const res = await appAPI.editOrder(id, {
            ...data,
            status: "Не оплачено",
          });
          if (!res || res.data === null) {
            return toast.error("Ошибка при обновлении");
          }
          toast.success("Успешно доработано");
          return nav("/cart");
        } catch (error) {
          console.error(error);
          toast.error("Ошибка при обновлении");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const additionalOffers = userInfo.attributes.work_offers.data;

  const isUserSeller =
    orderInfo?.attributes?.offer?.data?.attributes?.owner?.data?.attributes
      .uid === userInfo?.attributes?.uid ||
    additionalOffers.some(
      (offer) => offer.id == orderInfo?.attributes?.offer?.data?.id
    );

  const isUserBuyer = orderInfo?.attributes?.users?.data.some(
    (user) => user.attributes.uid === userInfo?.attributes?.uid
  );

  if (!orderInfo) return <Loader />;
  if (!isUserBuyer && !isUserSeller)
    return (
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        Данный товар не найден
      </div>
    );

  const needCheck =
    orderInfo.attributes.offer.data.attributes.needCheck === true;
  return (
    <>
      <Back />
      <div
        className="title_page"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        <div
          onClick={() => window.history.back()}
          style={{
            width: "fit-content",
            fontSize: "13px",
            cursor: "pointer",
            fontSize: "0.75rem",
            textTransform: "uppercase",
          }}
        >
          Отменить
        </div>
        Редактировать сделку
        <div
          onClick={handleSubmit}
          style={{
            width: "fit-content",
            textAlign: "end",
            cursor: "pointer",
            fontSize: "0.75rem",
            color: "#731358",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          Обновить
        </div>
      </div>
      <div className="container create_good">
        <SectionHeader className="section__header">Реквизиты</SectionHeader>
        <Section>
          <>
            <div className="input_question_block" style={{ marginTop: "5px" }}>
              <label className="input_question">
                <div>
                  Номер карты
                  <div className="require_question">Обязательное</div>
                </div>
                <input
                  style={{ fontSize: "18px" }}
                  type="text"
                  placeholder="Введите номер карты"
                  value={values?.cardNumber ?? ""}
                  onChange={(e) =>
                    setValues({ ...values, cardNumber: e.target.value })
                  }
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Номер телефона
                  <div className="require_question">Обязательное</div>
                </div>
                <input
                  style={{ fontSize: "18px" }}
                  type="text"
                  placeholder="Введите номер телефона"
                  value={values?.phoneNumber ?? ""}
                  onChange={(e) =>
                    setValues({ ...values, phoneNumber: e.target.value })
                  }
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Фамилия и имя
                  <div className="require_question">Обязательное</div>
                </div>
                <input
                  style={{ fontSize: "18px" }}
                  type="text"
                  placeholder="Введите вашу фамилию и имя"
                  value={values?.customerName ?? ""}
                  onChange={(e) =>
                    setValues({ ...values, customerName: e.target.value })
                  }
                />
              </label>
            </div>

            <div className="input_question_block">
              <label className="input_question">
                <div>
                  Выберите банк{" "}
                  <div className="require_question">Обязательное</div>
                </div>
                <select
                  style={{ fontSize: "18px", height: "auto" }}
                  value={values?.bank ?? ""}
                  onChange={(e) =>
                    setValues({ ...values, bank: e.target.value })
                  }
                >
                  <option value="">—</option>
                  <option value="Тинькофф">Тинькофф</option>
                  <option value="Сбербанк">Сбербанк</option>
                  <option value="Газпром банк">Газпром банк</option>
                  <option value="Рнкб">Рнкб</option>
                  <option value="ВТБ">ВТБ</option>
                  <option value="Райффайзен банк">Райффайзен банк</option>
                  <option value="Озон банк">Озон банк</option>
                  <option value="Открытие">Открытие</option>
                  <option value="Альфа банк">Альфа банк</option>
                  <option value="Хоум кредит">Хоум кредит</option>
                  <option value="УБРиР">УБРиР</option>
                  <option value="Яндекс">Яндекс</option>
                </select>
              </label>
            </div>
          </>
        </Section>

        <SectionHeader className="section__header">Шаги сделки</SectionHeader>
        <Section>
          {Object.entries(values).map(([key, imgValue]) => {
            if (
              key.includes("image") &&
              Number(key.replace("image", "")) <= orderInfo.attributes.step &&
              !key.includes("image4")
            ) {
              return (
                <div style={{ marginTop: "5px" }}>
                  <ImageComponent
                    image={imgValue?.img ? imgValue : imgValue}
                    setImage={(e) => handleSetImage(e, key)}
                    title={
                      `Шаг ` +
                      key.replace("image", "") +
                      `. ${ORDER_STEPS[key.replace("image", "")]}`
                    }
                  />
                </div>
              );
            }
          })}
        </Section>

        {needCheck ? (
          <>
            <SectionHeader className="section__header">Чек</SectionHeader>
            <Section>
              <div style={{ marginTop: "5px" }}>
                <ImageComponent
                  image={
                    values?.customerCheck?.img
                      ? values?.customerCheck?.img
                      : values?.customerCheck
                  }
                  setImage={(e) => handleSetImage(e, "customerCheck")}
                  title={`Шаг 7. Чек об оплате`}
                />

                <InputComponent
                  value={values?.customerCheckNumber}
                  type="input"
                  onChange={(check) =>
                    setValues({
                      ...values,
                      customerCheckNumber: check,
                    })
                  }
                  header="Номер чека"
                />
              </div>
            </Section>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default OrderEdit;
