import { useCallback, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import * as Bytescale from "@bytescale/sdk";
import appAPI, { baseURL } from "../../../api/service";
import { Loading } from "./../components/Loading";
import { Title } from "@telegram-apps/telegram-ui";
import { CheckboxComponent } from "../../../shared/ui/Checkbox";
import { FileInputComponent } from "../../../shared/ui/ImagePicker";
import { MainButton, SecondaryButton } from "../../../shared/telegram-elements/MainButton";
import { splitNumber } from "../../../helpers/splitNumbers";

const Task6 = ({ setTask, orderInfo, DeleteOrderComponent }) => {
  const { id } = useParams();
  const [accept, setAccept] = useState(false);
  const [responseLoad, setResponseLoad] = useState(false);
  const [load, setLoad] = useState(false);

  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);

  const handleSubmit = async () => {
    if (responseLoad == true) return;
    if (!video || !image) return toast.error("Загрузите необходимые файлы");
    setResponseLoad(true);
    try {
      const formDataVideo = new FormData();
      formDataVideo.append("files", video.file);
      const responseVideo = await appAPI.uploadImage(formDataVideo);
      if (!responseVideo[0]) {
        return toast.error("Ошибка при загрузке картинки");
      }
      console.log(video?.file, responseVideo);

      const formData = new FormData();
      formData.append("files", image.file);
      const response = await appAPI.uploadImage(formData);
      if (!response[0]) {
        return toast.error("Ошибка при загрузке картинки");
      }

      const fileUrl = `${baseURL}${response[0].url}`;
      const fileVideoUrl = `${baseURL}${responseVideo[0].url}`;

      toast.dismiss();
      if (fileUrl && fileVideoUrl) {
        const res = await appAPI.updateOrder(
          7,
          fileUrl,
          fileVideoUrl,
          orderInfo.id,
          orderInfo.attributes.offer.data.id
        );
        if (res.success === false) {
          if (res.error) {
            return toast.error(res.error);
          }
          return toast.error("Попробуйте позже");
        }
        toast.success("Успешно отправленно");
        setTask(7);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setResponseLoad(false);
    }
  }

  console.log(orderInfo.attributes.cashbackAmount)

  return (
    <>
    {responseLoad && <Loading />}
      <div className="container">
        <Title weight="1">Получение товара</Title>
        <div className="conditions_ol" style={{paddingLeft:"16px"}}>
          <div>Заберите товар как обычно, сделайте скрин раздела «доставки» из личного кабинета, где указана дата получения и статус "Доставлено". После этого разрежьте штрихкод и сделайте фото разрезанного штрихкода на фоне товара без упаковки</div>
          <br />
          <b>Ваш кэшбэк: {splitNumber(Number(orderInfo.attributes.cashbackAmount))} руб.</b>
        </div>

        <CheckboxComponent
          title={"Забрал(а) товар"}
          description={"Отметьте, если забрали товар"}
          value={accept}
          onChange={setAccept}
        />

        {accept && (
          <>
            <FileInputComponent
              header="Скрин доставки"
              value={image ? image?.file?.name : "Выберите скрин"}
              onChange={setImage}
            />
            <FileInputComponent
              header="Скрин фото разрезанных штрихкодов на фоне товара"
              value={video ? video?.file?.name : "Выберите фото"}
              onChange={setVideo}
            />
          </>
        )}

        {/*accept && image && !load && video ? (
          !responseLoad ? (
            <div
              className="buttonTo"
              onClick={handleSubmit}
              style={{ marginTop: "16px" }}
            >
              Продолжить
            </div>
          ) : (
            <div className="buttonTo" style={{ marginTop: "16px" }}>
              Загрузка...
            </div>
          )
        ) : (
          <div
            className="buttonTo buttonTo__notactive"
            style={{ marginTop: "16px" }}
          >
            Продолжить
          </div>
        )*/}
        <SecondaryButton text="Проверить продавца" onClick={() => window.Telegram.WebApp?.openTelegramLink(`https://t.me/bigblacklist_bot?start=${orderInfo.attributes.offer.data.attributes.brand}`)}/>

        <MainButton onClick={() => handleSubmit()} disabled={!(accept && image && !load && video)} loading={responseLoad} />
        <iframe src="https://vkvideo.ru/video_ext.php?oid=216729851&id=456239027&hd=2&hash=d4836c62ef471d3e" className="video__iframe" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
        {DeleteOrderComponent}

       {/*<div
          className="button_ship"
          onClick={() => {
            window.open("https://t.me/+MUr6jMOE3hs5MzQ0");
          }}
        >
          Подписаться на канал
        </div>

        <div
          className="wrohgs"
          onClick={() =>
            window.open("https://telegra.ph/Voznikla-problema-06-19")
          }
        >
          Нужна помощь
        </div>*/}
      </div>
    </>
  );
};
export default Task6;
