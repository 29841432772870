import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import appAPI from "../../api/service";
import Loader from "../helpers/loader/loader";
import Back from "../helpers/back";

const Relation = () => {
  const { id } = useParams();
  const nav = useNavigate();

  return (
    <>
      <div className="title_page">
        <div style={{ textAlign: "center" }}>Добавить товар</div>
      </div>
      <div className="container">
        <p>
          <b>Свяжитесь с админом для публикации товара</b>
          <br />
          <div
            className="buttonTo"
            onClick={() => window.open("https://t.me/ruslanamantayev")}
            style={{ marginTop: "16px" }}
          >
            Связаться с админом
          </div>
        </p>

        <div
          className="buttonTo"
          onClick={() => nav("/")}
          style={{
            marginTop: "16px",
            background: "none",
            color: "black",
            border: "1px solid #731358",
          }}
        >
          Вернуться на главную
        </div>
      </div>
    </>
  );
};
export default Relation;
