import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../helpers/loader/loader";
import appAPI, { baseURL } from "../../api/service";
import Back from "../helpers/back";
import { Link, Section, SegmentedControl } from "@telegram-apps/telegram-ui";
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import { getOrderStep } from "./helpers/getCurrentStep";
const Cart = ({userInfo}) => {
  const nav = useNavigate();
  const [search, setSearch] = useState("");
  const [filteredGoods, setFilteredGoods] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  //const [userInfo, setUserInfo] = useState(null);

  /*useEffect(() => {
    async function fetch() {
      try {
        const response = await appAPI.getUser(window.Telegram.WebApp.initDataUnsafe.user.id);
        setUserInfo(response.data[0]);
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, []);
*/
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (!userInfo) return;
    if (!userInfo.attributes.orders) {
      setFilteredGoods([]);
      return;
    }

    const sortedOrders = userInfo.attributes.orders.data
      .slice()
      .sort((a, b) => {
        const dateA = new Date(a.attributes.createdAt);
        const dateB = new Date(b.attributes.createdAt);
        return dateB - dateA;
      });

    setFilteredGoods(sortedOrders);
  }, [userInfo]);
  /*
  useEffect(() => {
    if (!userInfo) return;

    if (!search) {
      setFilteredGoods(userInfo.attributes?.orders.data || []);
    } else {
      let timer;
      if (!timer) {
        timer = setTimeout(() => {
          setFilteredGoods(
            (userInfo.attributes?.orders.data || []).filter((work) =>
              String(work.id).includes(search.toUpperCase())
            ).slice().sort((a, b) => {
              const dateA = new Date(a.attributes.offer.data.attributes.orders.data);
              const dateB = new Date(b.attributes.offer.data.attributes.createdAt);
              return dateB - dateA; 
            })
          );
        }, 10);
      }
      return () => clearTimeout(timer);
    }
  }, [search, userInfo]);

  */
  if (filteredGoods === null || !userInfo) return <Loader />;
  return (
    <>
      <Back />
      <Section header="ВАШИ ОБЪЯВЛЕНИЯ" className="tabs">
      <SegmentedControl>
          <SegmentedControlItem
              onClick={() => {
                nav("/catalog")
              }}
          >
              Каталог
          </SegmentedControlItem>
          <SegmentedControlItem
              selected={true}
          >
              Мои покупки
          </SegmentedControlItem>
      </SegmentedControl>
      </Section>
      <div className="container">
        {/*userInfo && (
          <div className="buttonTo" onClick={() => nav("/")}>
            <div>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="1.056"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13 15C13 14.0572 13 13.5858 13.2929 13.2929C13.5858 13 14.0572 13 15 13H17C17.9428 13 18.4142 13 18.7071 13.2929C19 13.5858 19 14.0572 19 15V17C19 17.9428 19 18.4142 18.7071 18.7071C18.4142 19 17.9428 19 17 19H15C14.0572 19 13.5858 19 13.2929 18.7071C13 18.4142 13 17.9428 13 17V15Z" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M13 7C13 6.05719 13 5.58579 13.2929 5.29289C13.5858 5 14.0572 5 15 5H17C17.9428 5 18.4142 5 18.7071 5.29289C19 5.58579 19 6.05719 19 7V9C19 9.94281 19 10.4142 18.7071 10.7071C18.4142 11 17.9428 11 17 11H15C14.0572 11 13.5858 11 13.2929 10.7071C13 10.4142 13 9.94281 13 9V7Z" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M5 15C5 14.0572 5 13.5858 5.29289 13.2929C5.58579 13 6.05719 13 7 13H9C9.94281 13 10.4142 13 10.7071 13.2929C11 13.5858 11 14.0572 11 15V17C11 17.9428 11 18.4142 10.7071 18.7071C10.4142 19 9.94281 19 9 19H7C6.05719 19 5.58579 19 5.29289 18.7071C5 18.4142 5 17.9428 5 17V15Z" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M5 7C5 6.05719 5 5.58579 5.29289 5.29289C5.58579 5 6.05719 5 7 5H9C9.94281 5 10.4142 5 10.7071 5.29289C11 5.58579 11 6.05719 11 7V9C11 9.94281 11 10.4142 10.7071 10.7071C10.4142 11 9.94281 11 9 11H7C6.05719 11 5.58579 11 5.29289 10.7071C5 10.4142 5 9.94281 5 9V7Z" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
            </div>
            Перейти к товарам
          </div>
        )*/}
        <div className="hint_block">
          Нажмите на карточку чтобы открыть инструкцию 👇👇👇
        </div>
        <div style={{ margin: "5px 0" }}>
          {/*<div>
            Пропала сделка?{" "}
            <span
              style={{ color: "#0088cc" }}
              onClick={() => {
                window.open("https://t.me/m/hz913FpBNzdi");
              }}
            >
              Напишите нам
            </span>
          </div>*/}
          <Link onClick={() => window.Telegram.WebApp?.openTelegramLink("https://t.me/HelpWBcashback_bot")}>Техподдержка</Link>
        </div>
        <div className="header" style={{ marginTop: "16px" }}>
          <h1>Мои покупки</h1>
        </div>
        {/*<div className="search_block">
          <label
            className={`input_search ${isFocused && "active_input"}`}
            tabIndex="0"
            style={{ marginRight: 0 }}
          >
            <div className={`search_icon`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Поиск"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {search !== "" && (
              <div
                className="close_icon"
                onClick={(e) => {
                  handleFocus(e);
                  setSearch("");
                }}
              >
                <xml version="1.0" encoding="utf-8" />
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" fill="none" />
                  <path
                    d="M7 17L16.8995 7.10051"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 7.00001L16.8995 16.8995"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </label>
          </div>*/}

        {/*userInfo.works.map(i => {
                return (
                    <div className="cart_v2" onClick={()=> nav("/deal/1")}>
                        <img src="/good.webp"/>
                        <div className="text">
                            <div className="good_id">НОМЕР СДЕЛКИ: {i.id}</div>
                            <div className="title_v2">{i.title}</div>
                            <div className="price_v2">Цена для вас: {i.price} руб</div>
                        </div>
                        <svg  className="arrow_icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#b8b8b8"></path> </g></svg>
                    </div>
                )
            })*/}
        {filteredGoods && filteredGoods.length > 0 ? (
          <div className="catalog_v2" style={{ marginTop: "10px" }}>
            {filteredGoods.map((i) => {
              return (
                <div className="cart_v2" onClick={() => {
                  if(Number(i.attributes.step) == 8) {
                    return nav(`/result/${i.id}`)
                  } else {
                    return nav(`/deal/${i.id}`)
                  }
                }}>
                  {i.attributes.offer?.data?.attributes?.image?.data && (
                    <img
                      src={
                        baseURL +
                        i.attributes.offer.data.attributes.image.data.attributes
                          .url
                      }
                    />
                  )}
                  <div className="text">
                    <div className="good_id">НОМЕР СДЕЛКИ: {i.id}</div>
                    <div className="title_v2">
                      {i.attributes.offer?.data?.attributes?.name}
                    </div>
                    <div className="price_v2">
                      Цена для вас:{" "}
                      {i.attributes.priceForUserOffer
                        ? i.attributes.priceForUserOffer
                        : i.attributes.offer.data.attributes.priceForUser}{" "}
                      руб
                    </div>
                    <div className="price_v2">
                      Текущий шаг: {getOrderStep(Number(i.attributes.step))}
                    </div>
                  </div>
                  <svg
                    className="arrow_icon"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#000000"
                    stroke-width="0.00024000000000000003"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                        fill="#b8b8b8"
                      ></path>{" "}
                    </g>
                  </svg>
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            Покупки не найдены
          </div>
        )}
      </div>
    </>
  );
};

export default Cart;
