import { useNavigate, useParams } from "react-router-dom"
import Loader from "../helpers/loader/loader";
import React, { useEffect, useState } from "react";
import appAPI, { baseURL } from "../../api/service";
import { ChannelCreatePage } from "../ChannelCreatePage/ChannelCreatePage";
import { renderElement } from "../../helpers/renderElementRichText";


export const ChannelPage = ({userInfo}) => {
    const nav = useNavigate()
    const {id} = useParams()

    const [loading, setLoading] = useState(false);
    const [channel, setChannel] = useState(null);

    useEffect(() => {
        async function fetch() {
            const response = await appAPI.getChannel(id);
            if (response.data === null) {
                return setChannel("error");
            }
            setChannel(response.data);
        }
        fetch();
    }, [id]);


    if (channel === "error") return (
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        Данный канал не найден
      </div>
    );


    if(channel == null) return <Loader />
    return (
        <div className="channel__page">
            <div>
                {channel && <div className="channel" onClick={() => window.open(`https://t.me/${channel.attributes.channelUsername.replace('@', "")}`)}>
                    <div className="channel__block">
                        <img src={`${baseURL}${channel.attributes.channelImage.data.attributes.url}`} className="channel__info"/>
                        <div className="channel__text">
                            <div className="channel__text_t">{channel.attributes.title}</div>
                            <div className="channel__text_v">{channel.attributes.channelUsername}</div>
                        </div>
                    </div>
                </div>}
                <div className="price__list">
                    {channel.attributes.info.map((element, index) => (
                        <React.Fragment key={index}>
                          {renderElement(element)}
                        </React.Fragment>
                    ))}
                </div>
                
            </div>

           <ChannelCreatePage channel={channel} userInfo={userInfo}/>
        </div>
    )
}