import React, { useEffect } from "react";

const Back = ({func}) => {
  useEffect(() => {
    if(window.history.length > 0) {
      const goBack = () => {
        if(func) return func()
        window.history.back();
      };
      
      window.Telegram.WebApp.BackButton.onClick(goBack);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(goBack);
      };

    }
  }, [func]);

  return <></>;
};

export default Back;
