import { Cell, List, Text } from "@telegram-apps/telegram-ui"
import { useNavigate } from "react-router-dom"
import Back from "../helpers/back"

const Cabinet = ({userInfo}) => {
    const nav = useNavigate()
    
    return (
        <>
        <Back />
            <List>
                <Text weight="2">Кабинет продавца</Text><br/><br/>
                <Text style={{marginBottom:"12px", display:"block"}}>ВБКЭШБЭК - сервис для управления раздачами товара за кэшбэк</Text>
                <Cell className="cell" description="Доступное количество раздач" onClick={() => nav("/balance")} after={<Text>{Number(userInfo.attributes.limitsBalance)}</Text>}>
                    Баланс
                </Cell>
                <Cell className="cell" description="Товары по раздачам" onClick={() => nav("/admin/offers")}>
                    Мои товары
                </Cell>
                {(userInfo.attributes?.offers?.data?.length > 0 || userInfo.attributes?.work_offers?.data?.length > 0) && <>
                <Cell className="cell" description="Оплаты, сделки, отчеты" onClick={() => nav("/statistics")}>
                    Статистика
                </Cell>
                <Cell className="cell" description="Сделки по всем товарам" onClick={() => nav("/admin/orders")}>
                    Выплаты
                </Cell>
                <Cell className="cell" description="Раздел для закупки рекламы" onClick={() => nav("/admin/channels")}>
                    Каналы и группы раздач
                </Cell></>}
                <Cell className="cell" description="Опепативно ответим на все вопросы" onClick={() => window.open("https://t.me/ruslanamantayev")} before={<>
                    <img src="/teh.svg" />
                </>}
                >
                    Техподдержка
                </Cell>
            </List>
        </>
    )
}

export default Cabinet