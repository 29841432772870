import { List, Cell, Text, Button } from "@telegram-apps/telegram-ui"
import Back from "../helpers/back"


const BalancePage = ({userInfo}) => {
    return (
        <>
            <Back />
            <List>
                <Cell className="cell" description="Доступное количество раздач" after={<Text>{Number(userInfo.attributes.limitsBalance)}</Text>}>
                    Баланс
                </Cell>
                <Text style={{marginBottom:"12px", display:"block"}}>Чтобы пополнить кабинет, свяжитесь с админом</Text>
                <Button stretched size="m" onClick={() => window.open("https://t.me/ruslanamantayev")}>
                    Написать администратору
                </Button>
            </List>
        </>
    )
}

export default BalancePage