import { Cell, Section } from "@telegram-apps/telegram-ui";
import { useResult } from "../hooks/useResult";
import { Icon20ChevronRight } from "@vkontakte/icons";
import { useNavigate } from "react-router-dom";

export function FooterBlock({isUserBuyer, cancelText = "Оформить возврат товара", cancelFunction, order, step }) {
  const { button, modal } = useResult(order);
  const nav = useNavigate()
  return (
    <div style={{paddingTop:"12px"}}>
      <Section className="section">
        {button}
        {isUserBuyer && <Cell className="cell__red cell" onClick={() => cancelFunction()}>
          {cancelText}
        </Cell>}
        {step == 6 && <Cell
          after={<Icon20ChevronRight />}
          onClick={() => nav(`/orders/edit/${order.id}`)}
          className="cell"
        >
          Редактировать сделку
        </Cell>}
        <Cell
          after={<Icon20ChevronRight />}
          onClick={() => {
            window.open("https://t.me/+MUr6jMOE3hs5MzQ0");
          }}
          className="cell"
        >
          Подписаться на канал
        </Cell>
        <Cell
          after={<Icon20ChevronRight />}
          onClick={() =>
            window.open("https://telegra.ph/Voznikla-problema-06-19")
          }
          className="cell"
        >
          Нужна помощь
        </Cell>
        {modal}
      </Section>
    </div>
  );
}
