import { useNavigate, useParams } from "react-router-dom"
import Loader from "../helpers/loader/loader";
import { useEffect, useState } from "react";
import appAPI, { baseURL } from "../../api/service";
import Back from "../helpers/back";
import toast from "react-hot-toast";
import axios from "axios";


export const ChannelCreatePage = ({userInfo, channel}) => {
    const nav = useNavigate();

  const [image, setImage] = useState(null);

  const [check, setCheck] = useState(null);

  const [values, setValues] = useState({
    title: "",
    contactUsername: "",
    price_site: 0, 
    price_client: 0, 
    photo: null,
    check: null,
    articul: "-",
    commentForAdmin: ""
  });
  const [loading, setLoading] = useState(false)

  const handleSetImage = (image) => {
    console.log(image)
    if (!image) return;

    if (image.type !== 'image/jpeg' && image.type !== 'image/jpg') {
        setImage(null)
        return toast.error("Проверьте формат изоображения");
    }

    const fileReader = new FileReader(image);
    fileReader.onload = () => {
      setImage({ img: fileReader.result, file: image });
      setValues({ ...values, photo: image });
    };
    fileReader.readAsDataURL(image);
};


  const handleSetCheck = (image) => {
    if (!image) return;
    const fileReader = new FileReader(image);
    fileReader.onload = () => {
      setCheck({ img: fileReader.result, file: image });
      setValues({ ...values, check: image });
    };
    fileReader.readAsDataURL(image);
  };

  const handleSubmit = async () => {
    if(loading === true) return 
    
    const isAllFieldsFilled = Object.values({...values, commentForAdmin: "-"}).every(
      (val) => val !== "" && val !== null
    );

    if (isAllFieldsFilled && image && check ) {
      const formData = new FormData();
      formData.append("files", image.file);

      setLoading(true)
      try {
        toast.loading("Загрузка")
        const response = await appAPI.uploadImage(formData)
        if (!response[0]) {
          return toast.error("Ошибка при загрузке картинки");
        }

        const formData1 = new FormData();
        formData1.append("files", check.file);

        const response1 = await appAPI.uploadImage(formData)

        if (!response1[0]) {
            return toast.error("Ошибка при загрузке картинки");
        }


        console.log(channel)
        

        const data = {
            ownerChannel: channel.id,
            title: values.title,
            article: values.articul,
            image: response[0].id,
            check: response1[0].id,
            telegramUsername: values.contactUsername,
            owner: userInfo.id,
            price_wb: Number(values.price_site),
            price_user: Number(values.price_client),
            commentForAdmin: values.commentForAdmin,
            channelInfo : channel,
            userID: window.Telegram.WebApp.initDataUnsafe.user.id,
            //checkImg: "https://api.wbcashback.ru/uploads/photo_2024_07_31_12_40_06_9589e3efc9.jpeg",
            //photoImg: "https://api.wbcashback.ru/uploads/photo_2024_07_31_12_40_06_9589e3efc9.jpeg",
            photoImg: `${baseURL}${response[0].url}`,
            checkImg: `${baseURL}${response1[0].url}`,
            adminUsernameFrontend: window?.Telegram?.WebApp?.initDataUnsafe?.user?.username || "test"
        };

        

        const res = await appAPI.createPost(data);
        if (!res || res.data === null) {
          return toast.error("Ошибка при создании");
        }
        toast.dismiss()
        return toast.success("Успешно создан");
      } catch (error) {
        console.error(error);
        toast.error("Ошибка при создании");
      } finally {
        setLoading(false)
      }
    } else {
      toast.error("Заполните все поля");
    }
  };
  return (
    <>
      <div className="container create_good create_post">
      <div
        className="title_page"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width:"100%"
        }}
      >
        <div style={{color:"transparent"}}>Создать</div>
        <div>Создать пост</div>
        <div
          onClick={handleSubmit}
          style={{
            width: "fit-content",
            textAlign: "end",
            cursor: "pointer",
            fontSize: "0.75rem",
            color: "#731358",
            fontWeight: "600",
            textTransform: "uppercase",
            background:"white",
            padding:'10px',
            borderRadius:"10px"
          }}
        >
          Создать
        </div>
      </div>
        <div className="input_question_block">
          <label className="input_question">
            <div>
              Название товара
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите название товара"
              value={values.title}
              onChange={(e) => setValues({ ...values, title: e.target.value })}
            />
          </label>
        </div>

        {/*<div className="input_question_block">
          <label className="input_question">
            <div>
              Артикул<div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите артикул"
              value={values.articul}
              onChange={(e) =>
                setValues({ ...values, articul: e.target.value })
              }
            />
          </label>
        </div>

        <div className="hint_block" style={{marginBottom:"10px"}}>
          Внимательно вводите номер артикула. По нему будет проводится
          подтверждение товара
        </div>*/}

        <div className="image_question_block">
          <label className="input_question">
            <div>
              Фото товара (в формате JPG)<div className="require_question">Обязательное</div>
            </div>
            <label>
              <div>
                <div className="image_picker">
                  <div className="image_picker_info">
                    {image && <img className="image_select" src={image.img} />}
                    <div className="text_file_image">
                      {image ? image.file.name : "Выберите изображение..."}
                    </div>
                  </div>
                  <div>
                    {image && (
                      <svg
                        onClick={(e) => {
                          e.preventDefault();
                          setImage(null);
                          setValues({ ...values, photo: null });
                        }}
                        className="image_picker_close"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" fill="none" />
                        <path
                          d="M7 17L16.8995 7.10051"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 7.00001L16.8995 16.8995"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleSetImage(e.target.files[0])}
                placeholder="Выберите изображение..."
                style={{ display: "none" }}
              />
            </label>
          </label>
        </div>





        <div className="image_question_block" style={{marginTop:"10px"}}>
          <label className="input_question">
            <div>
              Скрин оплаты<div className="require_question">Обязательное</div>
            </div>
            <label>
              <div>
                <div className="image_picker">
                  <div className="image_picker_info">
                    {check && <img className="image_select" src={check.img} />}
                    <div className="text_file_image">
                      {check ? check.file.name : "Выберите изображение..."}
                    </div>
                  </div>
                  <div>
                    {check && (
                      <svg
                        onClick={(e) => {
                          e.preventDefault();
                          setCheck(null);
                          setValues({ ...values, check: null });
                        }}
                        className="image_picker_close"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" fill="none" />
                        <path
                          d="M7 17L16.8995 7.10051"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 7.00001L16.8995 16.8995"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleSetCheck(e.target.files[0])}
                placeholder="Выберите изображение..."
                style={{ display: "none" }}
              />
            </label>
          </label>
        </div>


        {<div className="input_question_block">
          <label className="input_question">
            <div>
              Цена на вб (руб)
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="number"
              placeholder="Укажите цену на вб"
              value={values.price_site}
              onChange={(e) =>
                setValues({ ...values, price_site: e.target.value })
              }
            />
          </label>
        </div>}

        {<div className="input_question_block">
          <label className="input_question">
            <div>
              Цена для покупателя<div className="require_question">Обязательное</div>
            </div>
            <input
              type="number"
              placeholder="Цена для покупателя"
              value={values.price_client}
              onChange={(e) =>
                setValues({ ...values, price_client: e.target.value })
              }
            />
          </label>
        </div>}

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Телеграм ник для связи
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder={`Например "durov"`}
              value={values.contactUsername}
              onChange={(e) => setValues({ ...values, contactUsername: e.target.value })}
            />
          </label>
        </div>
        
        <div className="hint_block">
          Покупатели будут обращаться за инструкцией на указанный контакт
        </div>

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Комментарий для админа
            </div>
            <textarea
              type="text"
              placeholder={`Дополнительные условия`}
              value={values.commentForAdmin}
              onChange={(e) =>
                setValues({ ...values, commentForAdmin: e.target.value })
              }
            />
          </label>
        </div>

        <div className="hint_block">
          Дата и время публикации, количество дней публикации и тд
        </div>

        <div
            className="buttonTo"
            onClick={handleSubmit}
            style={{ marginTop: "16px" }}
          >
            Отравить заявку
          </div>
      </div>
    </>
    )
}