import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Filter = ({ isFilter, setIsFilter, setFilters, filters }) => {
  const nav = useNavigate();
  

  const filter = useRef();

  const handleClearAll = () => {
    setFilters({
      typeFilter: {
        Женщинам: false,
        Мужчинам: false,
        Обувь: false,
        Детям: false,
        Дом: false,
        "Новый год": false,
        Красота: false,
        Аксессуары: false,
        Электроника: false,
        Игрушки: false,
        Мебель: false,
        "Товары для взрослых": false,
        "Бытовая техника": false,
        Зоотовары: false,
        Спорт: false,
        Автотовары: false,
        "Ювелирные изделия": false,
        "Для ремонта": false,
        "Сад и дача": false,
        Здоровье: false,
        Канцтовары: false,
      },
      selectedFilters: [],
    });
    setSel([])
    toast.success("Фильтры успешно очищены")
    return handleClose()
  };

  const [sel,setSel] = useState([])

  useEffect(() => {
    setSel(Object.entries(filters.typeFilter)
    .filter(([key, value]) => value)
    .map(([key]) => key));
  }, []);

  const handleTypeChange = (event) => {
    const { name, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      typeFilter: {
        ...prevFilters.typeFilter,
        [name]: checked,
      },
    }));
    updateSelectedFilters(name, checked);
  };

  const updateSelectedFilters = (name, checked) => {
    let updatedFilters = [...sel]

    if (checked) {
      if (!updatedFilters.includes(name)) {
        updatedFilters.push(name);
      }
    } else {
      updatedFilters = updatedFilters.filter((filter) => filter !== name);
    }

    setSel(updatedFilters);
  };

  const handleClose = () => {
    filter.current.classList.add("close_animation");
    setTimeout(() => {
      filter?.current?.classList?.remove("close_animation");
      setIsFilter(false);
    }, 200);
  };

  const handleSubmit = () => {
    handleClose();
    setFilters({ ...filters, selectedFilters: sel });
    return toast.success("Фильтры сохранены");
  };
  return (
    <div className="filter" onClick={handleClose}>
      <div
        className="filter_block"
        ref={filter}
        onClick={(e) => e.stopPropagation()}
      >
        <svg
          onClick={handleClose}
          className="close_icon_filter"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" fill="none" />
          <path
            d="M7 17L16.8995 7.10051"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 7.00001L16.8995 16.8995"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="filter_title">Фильтры</div>
        <div className="filter_overflow">
          <div className="filter_info">
            <div className="filter_info_title">Категория {sel.length > 0 ? <div className="select_filters_count">{sel.length}</div>:""}</div>
            {Object.entries(filters.typeFilter).map(([key, value]) => (
              <label className="filter_info_option">
                <div>{key}</div>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  name={key}
                  checked={filters.typeFilter[key]}
                  onChange={handleTypeChange}
                />
                <span></span>
              </label>
            ))}
            {/*<label className="filter_info_option">
              <div>Женщинам</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="women"
                checked={filters.typeFilter.women}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Мужчинам</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="men"
                checked={filters.typeFilter.men}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Обувь</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="shoes"
                checked={filters.typeFilter.shoes}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Детям</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="kids"
                checked={filters.typeFilter.kids}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Дом</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="home"
                checked={filters.typeFilter.home}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Новый год</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="newYear"
                checked={filters.typeFilter.newYear}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Красота</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="beauty"
                checked={filters.typeFilter.beauty}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Аксессуары</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="accessories"
                checked={filters.typeFilter.accessories}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Электроника</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="electronics"
                checked={filters.typeFilter.electronics}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Игрушки</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="toys"
                checked={filters.typeFilter.toys}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Мебель</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="furniture"
                checked={filters.typeFilter.furniture}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Товары для взрослых</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="adultGoods"
                checked={filters.typeFilter.adultGoods}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Бытовая техника</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="appliances"
                checked={filters.typeFilter.appliances}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Зоотовары</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="petSupplies"
                checked={filters.typeFilter.petSupplies}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Спорт</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="sport"
                checked={filters.typeFilter.sport}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Автотовары</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="autoGoods"
                checked={filters.typeFilter.autoGoods}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Ювелирные изделия</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="jewelry"
                checked={filters.typeFilter.jewelry}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Для ремонта</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="repair"
                checked={filters.typeFilter.repair}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Сад и дача</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="garden"
                checked={filters.typeFilter.garden}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Здоровье</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="health"
                checked={filters.typeFilter.health}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>
            <label className="filter_info_option">
              <div>Канцтовары</div>
              <input
                type="checkbox"
                className="custom-checkbox"
                name="stationery"
                checked={filters.typeFilter.stationery}
                onChange={handleTypeChange}
              />
              <span></span>
            </label>*/}
          </div>
        </div>
        <div className="filters_buttons">
          <div onClick={handleClearAll}>Очистить все</div>
          <div onClick={handleSubmit}>Сохранить</div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
