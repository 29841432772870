import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import appAPI, { baseURL } from "../../../api/service";
import { Loading } from "./../components/Loading";
import { Button, Link, Text, Title } from "@telegram-apps/telegram-ui";
import { CheckboxComponent } from "../../../shared/ui/Checkbox";
import { FileInputComponent } from "../../../shared/ui/ImagePicker";
import { InputComponent } from "../../../shared/ui/Input";
import { MainButton, SecondaryButton } from "../../../shared/telegram-elements/MainButton";

const Task7 = ({ orderInfo, DeleteOrderComponent }) => {
  const { id } = useParams();
  const [accept, setAccept] = useState(false);
  const nav = useNavigate();
  const [responseLoad, setResponseLoad] = useState(false);

  const [image, setImage] = useState(null);
  const [check, setCheck] = useState(null);
  const [customerCheckNumber, setCustomerCheckNumber] = useState("");

  const needCheck =
    orderInfo.attributes.offer.data.attributes.needCheck === true;

  const handleSubmit = async () => {
    if (responseLoad) return;
    if (!image || (needCheck && (!check || !customerCheckNumber.trim()))) {
      return toast.error("Введите все данные");
    }


    if(needCheck && customerCheckNumber.length <= 10) {
      return toast.error("Введите корректный номер чека")
    }

    setResponseLoad(true);

    try {
      const files = [image];
      const filesUrl = { fileUrl1: "" };

      if (needCheck) {
        files.push(check);
        filesUrl.fileUrl2 = "";
      }

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append("files", files[i].file);

        const response = await appAPI.uploadImage(formData);

        if (!response[0]) {
          throw new Error("Ошибка при загрузке картинки");
        }

        filesUrl[`fileUrl${i + 1}`] = `${baseURL}${response[0].url}`;
      }

      const additionalParams = needCheck
        ? `&customerCheck=${filesUrl.fileUrl2}&customerCheckNumber=${customerCheckNumber}`
        : "";

      const response = await appAPI.updateOrder(
        8,
        filesUrl.fileUrl1,
        null,
        orderInfo.id,
        orderInfo.attributes.offer.data.id,
        additionalParams
      );

      if (response.success) {
        toast.success("Отчет успешно отправлен");
        nav(`/result/${orderInfo.id}`);
      } else {
        toast.error(response.error || "Попробуйте позже");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Произошла ошибка");
    } finally {
      setResponseLoad(false);
    }
  }

  return (
    <>
    {responseLoad && <Loading />}
      <div className="container">
        <Button
          style={{ marginBottom: "10px" }}
          onClick={() =>
            window.open(
              `https://t.me/${orderInfo.attributes.offer.data.attributes.brand}`
            )
          }
          stretched
          before={<img src="/tg.svg" className="icon_tg" />}
        >
          Написать продавцу
        </Button>
        <Title weight="1">Отчет об отзыве</Title>
        <ul className="conditions_ol">
          {orderInfo.attributes.offer.data.attributes.test === true && (
            <li>Согласовать отзыв с продавцом</li>
          )}
          {orderInfo.attributes.offer.data.attributes.commentRules && (
            <>
              {orderInfo.attributes.offer.data.attributes.commentRules
                .split("/")
                .map((item, index) => (
                  <li key={index}>
                    {item.trim().charAt(0).toUpperCase() + item.trim().slice(1)}
                  </li>
                ))}
            </>
          )}
        </ul>

        <CheckboxComponent
          title={"Оставил(а) отзыв"}
          description={"Отметьте, если забрали товар"}
          value={accept}
          onChange={setAccept}
        />

        {accept && (
          <>
            <FileInputComponent
              header="Загрузите скрин отзыва"
              value={image ? image?.file?.name : "Выберите скрин"}
              onChange={setImage}
            />

            {needCheck && (
              <div>
                 <div>
                <FileInputComponent
                  header="Загрузите скрин электронного чека"
                  value={check ? check?.file?.name : "Выберите скрин"}
                  onChange={setCheck}
                />
                <InputComponent
                  header="Номер чека"
                  placeholder={"Введите номер чека"}
                  value={customerCheckNumber}
                  onChange={setCustomerCheckNumber}
                />
                </div>

                <div style={{padding: "10px", cursor:"pointer"}}>
                  <Link onClick={() => window.Telegram.WebApp.openLink("https://vkvideo.ru/video_ext.php?oid=216729851&id=456239032&hd=2&hash=c539ba8b7e0aa991")}>Где взять номер чека</Link>
                </div>
              </div>
            )}
          </>
        )}

        <SecondaryButton text="Проверить продавца" onClick={() => window.Telegram.WebApp?.openTelegramLink(`https://t.me/bigblacklist_bot?start=${orderInfo.attributes.offer.data.attributes.brand}`)}/>
        <MainButton onClick={() => handleSubmit()} disabled={!(accept && image && (needCheck ? customerCheckNumber && check : true))} loading={responseLoad} />

      <iframe
        src="https://vkvideo.ru/video_ext.php?oid=216729851&id=456239026&hd=2&hash=9529d22303cb7787"
        className="video__iframe"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
        frameborder="0"
        allowfullscreen
      ></iframe>

      {/*needCheck && <iframe
        src="https://vkvideo.ru/video_ext.php?oid=216729851&id=456239032&hd=2&hash=c539ba8b7e0aa991"
        className="video__iframe"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
        frameborder="0"
        allowfullscreen
      ></iframe>*/}
      </div>
      
      
      {DeleteOrderComponent}
    </>
  );
};
export default Task7;
