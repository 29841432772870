import { useEffect, useState } from "react";
import appAPI from "../../../api/service";



export function useStatistics() {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getData() {
            try {
                const res = await appAPI.getAdminStatistics()
                if(!res || res.error) {
                    throw new Error(res?.error)
                }
                setData(res?.data)
            } catch (e) {
                console.log(e)
                return setError(e.message || "Попробуйте позже")
            } finally {
                setLoading(false)
            }
        }

        getData()

    }, [])

    return {
        data,
        loading,
        error
    }
}