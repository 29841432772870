import { useState } from "react";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import Back from "../helpers/back";
import appAPI from "../../api/service";
import {
  Button,
  Cell,
  Image,
  Input,
  List,
  Section,
  Switch,
  Text,
  Textarea,
} from "@telegram-apps/telegram-ui";

const SendPostToBot = ({ userInfo }) => {
  const nav = useNavigate();
  const [image, setImage] = useState(null);
  const [values, setValues] = useState({
    content: "",
    link: "",
    buttonText: "",
    onlyForSellers: false
  });
  const [loading, setLoading] = useState(false);

  const handleSetImage = (image) => {
    if (!image) return;
    const fileReader = new FileReader(image);
    fileReader.onload = () => {
      setImage({ img: fileReader.result, file: image });
      setValues({ ...values, photo: image });
    };
    fileReader.readAsDataURL(image);
  };

  const handleSubmit = async () => {
    if (loading === true) return;

    const isAllFieldsFilled = Object.entries(values).every(
      ([key, val]) => key === "test" || (val !== "" && val !== null) 
    );

    if (isAllFieldsFilled && image) {
      const formData = new FormData();
      formData.append("files", image.file);
      setLoading(true);
      try {
        toast.loading("Загрузка");
        const response = await appAPI.uploadImage(formData);
        if (!response[0]) {
          return toast.error("Ошибка при загрузке картинки");
        }

        const data = {
          ...values,
          imageLink: `https://api.wbcashback.ru${response[0].url}` //"https://api.wbcashback.ru/uploads/photo_2025_02_06_20_40_02_6ead295251.jpeg"
        };

        const res = await appAPI.sendPostToBot(data);
        if (!res || res.data === null) {
          return toast.error("Ошибка при создании");
        }
        window.location.reload()
        return toast.success("Успешно отправлен");
      } catch (error) {
        console.error(error);
        toast.error("Ошибка при создании");
      } finally {
        setLoading(false);
        toast.dismiss();
      }
    } else {
      toast.error("Заполните все поля");
    }
  };

  if (String(window.Telegram.WebApp.initDataUnsafe.user.id) !== "336286853") return <Navigate to={"/"} />;
  return (
    <Section>
      <Back />
      <div
        className="title_page"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        <div
          onClick={() => nav("/")}
          style={{
            width: "fit-content",
            fontSize: "13px",
            cursor: "pointer",
            fontSize: "0.75rem",
            textTransform: "uppercase",
          }}
        >
          Отменить
        </div>
        Отправить пост
        <div
          onClick={handleSubmit}
          style={{
            width: "fit-content",
            textAlign: "end",
            cursor: "pointer",
            fontSize: "0.75rem",
            color: "#731358",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          Отправить
        </div>
      </div>

      <List>
        <label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleSetImage(e.target.files[0])}
            placeholder="Выберите изображение..."
            style={{ display: "none" }}
          />
          <Cell
            style={{ padding: "0px" }}
            description="в формате JPG, PNG"
            before={<Image size={48} src={image ? image.img : ""} />}
          >
            Картинка поста
          </Cell>
        </label>
        <div>
          <div style={{ paddingBottom: "5px" }}>
            <Text>Рассылка только для продавцов?</Text>
          </div>

          <Switch 
            value={values.onlyForSellers} 
            onChange={(e) => setValues(
              {
                ...values,
                onlyForSellers: e.target.checked,
              }
            )}
          />

        </div>
        <div>
          <div style={{ paddingBottom: "5px" }}>
            <Text>Контент</Text>
          </div>

          <Textarea
            placeholder={`Введите текст поста`}
            value={values.content}
            onChange={(e) =>
              setValues({
                ...values,
                content: e.target.value,
              })
            }
            type="text"
          />
        </div>
        <div>
          <div style={{ paddingBottom: "5px" }}>
            <Text>Текст кнопки</Text>
          </div>

          <Input
            placeholder={`Введите текст`}
            value={values.buttonText}
            onChange={(e) =>
              setValues({
                ...values,
                buttonText: e.target.value,
              })
            }
            type="text"
          />
        </div>
        <div>
          <div style={{ paddingBottom: "5px" }}>
            <Text>Ссылка в кнопке</Text>
          </div>

          <Input
            placeholder={`Введите текст`}
            value={values.link}
            onChange={(e) =>
              setValues({
                ...values,
                link: e.target.value,
              })
            }
            type="text"
          />
        </div>

        {!loading && (
          <Button onClick={() => handleSubmit()} stretched>
            Отправить пост
          </Button>
        )}
      </List>
    </Section>
  );
};

export default SendPostToBot;
