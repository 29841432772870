import { Input, Section, Text, Textarea } from "@telegram-apps/telegram-ui";

export function InputComponent({
  value,
  onChange,
  header = "Текстовое поле",
  footer,
  placeholder,
  after,
  type = "text",
  readOnly = false,
  disabled = false,
  ...actions
}) {
  const renderInputElement = {
    text: (
      <Input
        after={after}
        {...actions}
        header={header}
        footer={footer}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder ? placeholder : "Введите данные"}
        readOnly={readOnly}
        disabled={disabled}
      />
    ),
    input: (
      <div className="input_question_block">
          <label className="input_question">
            <div>
              {header}
              <div className="require_question">Обязательное</div>
            </div>
            <input
              style={{ fontSize: "18px" }}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={placeholder ? placeholder : "Введите данные"}
              readOnly={readOnly}
              disabled={disabled}
            />
          </label>
        </div>
    ),
    custom: (
      <div
        className="conditions_ol"
        style={{paddingLeft:"12px", color:"black"}}
        after={after}
        {...actions}
        header={header}
        footer={footer}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder ? placeholder : "Введите данные"}
        readOnly={readOnly}
        disabled={disabled}
      >
        <Text style={{wordBreak:"break-all"}}>{value}</Text>
      </div>
    )
  };

  return (header || footer) && type != "input" ? (
    <Section header={header} footer={footer}>{renderInputElement[type]}</Section>
  ) : (
    renderInputElement[type]
  );
}
