import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import appAPI from "../../../api/service";
import { Button, Section, Title } from "@telegram-apps/telegram-ui";
import { InputComponent } from "../../../shared/ui/Input";
import { FileInputComponent } from "../../../shared/ui/ImagePicker";
import { MainButton } from "../../../shared/telegram-elements/MainButton";
import { Loading } from "./../components/Loading";

const Task1 = ({ setTask, orderInfo, DeleteOrderComponent }) => {
  const nav = useNavigate();
  const [responseLoad, setResponseLoad] = useState(false);

  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);

  const handleSubmit = async () => {
    if (responseLoad == true) return;
    setResponseLoad(true);
    try {
      const formData = new FormData();
      formData.append("files", image.file);
      const response = await appAPI.uploadImage(formData);
      if (!response[0]) {
        return toast.error("Ошибка при загрузке картинки");
      }

      const formData1 = new FormData();
      formData1.append("files", image1.file);
      const response1 = await appAPI.uploadImage(formData1);
      if (!response1[0]) {
        return toast.error("Ошибка при загрузке картинки");
      }

      const fileUrl = `https://api.wbcashback.ru${response[0].url}`;
      const fileUrl1 = `https://api.wbcashback.ru${response1[0].url}`;

      const res = await appAPI.updateOrder(2, fileUrl, fileUrl1, orderInfo.id);
      if (res.success === true) {
        toast.success("Успешно отправленно");
        return setTask(2);
      } else {
        return toast.error("Ошибка при отправке");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setResponseLoad(false);
    }
  }

  const keywords =
    orderInfo.attributes.offer.data.attributes.keywords.split(",");
  const randomKeyword =
    keywords[Math.floor(Math.random() * keywords.length)].trim();
  const capitalizedKeyword =
    randomKeyword.charAt(0).toUpperCase() + randomKeyword.slice(1);

  return (
    <>
    {responseLoad && <Loading />}
      <div className="container">
        <Title weight="1">Загрузите скрин корзины</Title>
        <ul className="conditions_ol">
          <li>ВАЖНО! Оформление заказа на 5м шаге</li>
          <li>Пока не ищите наш товар </li>
          <li>Напишите ключевое поле в поисковик сайта</li>
          <li>Смотрите товары других продавцов</li>
          <li>Добавьте несколько товаров в корзину</li>
          <li>Сделайте скрин корзины и загрузите отчет</li>
        </ul>
        
        <InputComponent
          onClick={() => {
            if (capitalizedKeyword.split("#")[1])
              window.open(capitalizedKeyword.split("#")[1]);
            return;
          }}
          readOnly
          type="custom"
          header="Ключевое слово"
          value={
            capitalizedKeyword.split("#").length == 2
              ? capitalizedKeyword.split("#")[0]
              : capitalizedKeyword
          }
        />

        <FileInputComponent header="Скрин поискового запроса" value={image1 ? image1?.file?.name : "Выберите скрин"} onChange={setImage1}/>
        <FileInputComponent header="Скрин корзины" value={image ? image?.file?.name : "Выберите скрин"} onChange={setImage}/>

        {/*image && image1 ? (
          responseLoad ? (
            <div className="buttonTo" style={{ marginTop: "16px" }}>
              Загрузка...
            </div>
          ) : (
            <div
              className="buttonTo"
              onClick={handleSubmit}
              style={{ marginTop: "16px" }}
            >
              Продолжить
            </div>
          )
        ) : (
          <div
            className="buttonTo buttonTo__notactive"
            style={{ marginTop: "16px" }}
          >
            Продолжить
          </div>
        )*/}

        {<MainButton onClick={() => handleSubmit()} disabled={!image || !image1 || responseLoad} loading={responseLoad} />}
        <iframe src="https://vkvideo.ru/video_ext.php?oid=216729851&id=456239025&hd=2&hash=88e90aacfc040b73" className="video__iframe" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
        {DeleteOrderComponent}
      </div>
    </>
  );
};
export default Task1;
