import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import appAPI from "../../../api/service";
import { Section, Title } from "@telegram-apps/telegram-ui";
import { CheckboxComponent } from "../../../shared/ui/Checkbox";
import {
  MainButton,
  SecondaryButton,
} from "../../../shared/telegram-elements/MainButton";
import { Loading } from "./../components/Loading";

const Task3 = ({ setTask, orderInfo, DeleteOrderComponent }) => {
  const [accept, setAccept] = useState(false);
  const nav = useNavigate();
  const [responseLoad, setResponseLoad] = useState(false);

  const handleSubmit = async () => {
    if (responseLoad == true) return;
    setResponseLoad(true);
    try {
      const response = await appAPI.updateOrder(4, "", null, orderInfo.id);
      if (response.success) {
        toast.success("Успешно отправленно");
        setTask(4);
      } else {
        toast.error(response.error ?? "Неизвестная ошибка");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setResponseLoad(false);
    }
  }

  return (
    <>
      {responseLoad && <Loading />}
      <div className="container">
        <Title weight="1">Добавить товар в избранное</Title>
        <ul className="conditions_ol">
          <li>
          Добавьте товар в избранное
          </li>
          <li>
          Добавьте бренд в избранное
          </li>
        </ul>
        <Section className="section">
          <CheckboxComponent
            title={"Добавил(а) в товар в избранное"}
            description={"Отметьте, если товар добавлен в избранное"}
            value={accept}
            onChange={setAccept}
          />
        </Section>

        {/*accept ? (
          responseLoad ? (
            <div className="buttonTo" style={{ marginTop: "16px" }}>
              Загрузка...
            </div>
          ) : (
            <div
              className="buttonTo"
              onClick={handleSubmit}
              style={{ marginTop: "16px" }}
            >
              Продолжить
            </div>
          )
        ) : (
          <div
            className="buttonTo buttonTo__notactive"
            style={{ marginTop: "16px" }}
          >
            Продолжить
          </div>
        )*/}

        <MainButton
          onClick={() => handleSubmit()}
          disabled={!accept}
          loading={responseLoad}
        />
        <iframe
          src="https://vkvideo.ru/video_ext.php?oid=216729851&id=456239030&hd=2&hash=3420a8eff25ed895"
          className="video__iframe"
          allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
          frameborder="0"
          allowfullscreen
        ></iframe>
        {DeleteOrderComponent}
      </div>
    </>
  );
};
export default Task3;
