import { useEffect, useState } from "react";
import appAPI from "../../../api/service";
import toast from "react-hot-toast";


export function useStatistics() {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    async function getStatisticsData() {
        try {
            const response = await appAPI.getStatistics() 
            if(response.success == false) {
                return toast.error(response.error)
            }
            setData(response.data)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getStatisticsData()
    }, [])

    return {
        loading,
        data
    }
}