import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";

const Contact = () => {
  const nav = useNavigate();
  const [values, setValues] = useState({
    username: "",
    phone: "",
    email: "",
  });

  const handleSubmit = () => {
    const isValid = Object.values(values).every((val) => val !== "");

    if (!isValid) {
      toast.error("Заполните все поля");
    } else {
      toast.success("Успешно сохранено");
    }
  };
  return (
    <>
      <div className="title_page" style={{ textAlign: "center" }}>
        Добавить товар
      </div>
      <div className="container create_good">
        <div className="input_question_block">
          <label className="input_question">
            <div>
              Как вас зовут?<div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите ваше имя"
              value={values.title}
              onChange={(e) =>
                setValues({ ...values, username: e.target.value })
              }
            />
          </label>
        </div>
        <div className="input_question_block">
          <label className="input_question">
            <div>
              Ваш номер телефона
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите ваш номер"
              value={values.title}
              onChange={(e) => setValues({ ...values, phone: e.target.value })}
            />
          </label>
        </div>
        <div className="input_question_block">
          <label className="input_question">
            <div>
              Ваша почта<div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите вашу почту"
              value={values.title}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
          </label>
        </div>
      </div>

      <div className="filters_buttons">
        <div onClick={() => nav("/")}>Отменить</div>
        <div onClick={handleSubmit}>Сохранить</div>
      </div>
    </>
  );
};

export default Contact;
