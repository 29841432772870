import { useCallback, useRef } from "react";


export function useInfinityScroll(onIntersect) {
    const unsubscribe = useRef(() => {})

    return useCallback((el) => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    onIntersect()
                    observer.disconnect()
                }
            })
        }, {
            threshold: 0.1
        })
   

    if (el) {
        observer.observe(el);
        unsubscribe.current = () => observer.unobserve;
    } else {
        unsubscribe.current();
    }

    }, [])
}