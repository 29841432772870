import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import appAPI, { baseURL } from "../../../api/service";
import { Loading } from "./../components/Loading";
import { Title } from "@telegram-apps/telegram-ui";
import { CheckboxComponent } from "../../../shared/ui/Checkbox";
import { FileInputComponent } from "../../../shared/ui/ImagePicker";
import { MainButton, SecondaryButton } from "../../../shared/telegram-elements/MainButton";
const Task5 = ({ setTask, orderInfo, DeleteOrderComponent }) => {
  const [accept, setAccept] = useState(false);
  const [responseLoad, setResponseLoad] = useState(false);
  const [image, setImage] = useState(null);

  const handleSubmit = async () => {
    if (responseLoad == true) return;
    setResponseLoad(true);
    try {
      const formData = new FormData();
      formData.append("files", image.file);
      const response = await appAPI.uploadImage(formData);
      if (!response[0]) {
        return toast.error("Ошибка при загрузке картинки");
      }

      const fileUrl = `https://api.wbcashback.ru${response[0].url}`; //"https://api.wbcashback.ru/uploads/photo_2025_02_06_20_40_02_6ead295251.jpeg"

      const res = await appAPI.updateOrder(
        6,
        fileUrl,
        null,
        orderInfo.id,
        orderInfo.attributes.offer.data.id
      );
      if (res.success === false) {
        if (res.error) {
          return toast.error(res.error);
        }
        return toast.error("Попробуйте позже");
      }
      toast.success("Успешно отправленно");
      setTask(6);
    } catch (e) {
      console.log(e.message);
    } finally {
      setResponseLoad(false);
    }
  }

  return (
    <>
    {responseLoad && <Loading />}
      <div className="container">
        <Title weight="1">Оформление заказа</Title>

        <ul className="conditions_ol">
          <li>Оформите заказ</li>
          <li>Сделайте скрин из раздела “Доставки” в личном кабинете</li>
          <li>
            <b>На скрине обязательно должна быть цена</b>
          </li>
          <li>Загрузите скрин заказа в отчет</li>
        </ul>

        {parseInt(orderInfo.attributes.offer.data.attributes.dealsLimit) > 0 &&
        parseInt(
          orderInfo.attributes.offer.data.attributes.dealsDayLimitDisplay
        ) > 0 ? (
          <CheckboxComponent
            title={"Оформил(а) заказ"}
            description={"Отметьте, если оформили заказ"}
            value={accept}
            onChange={setAccept}
          />
        ) : (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            Раздача по данному товару закончилась. Проверьте наличие завтра
          </div>
        )}
        <SecondaryButton text="Проверить продавца" onClick={() => window.Telegram.WebApp?.openTelegramLink(`https://t.me/bigblacklist_bot?start=${orderInfo.attributes.offer.data.attributes.brand}`)}/>

        {accept && (
          <FileInputComponent header="Скрин корзины" value={image ? image?.file?.name : "Выберите скрин"} onChange={setImage}/>
        )}

        {/*accept && image ? (
          responseLoad ? (
            <div className="buttonTo" style={{ marginTop: "16px" }}>
              Загрузка...
            </div>
          ) : (
            <div
              className="buttonTo"
              onClick={handleSubmit}
              style={{ marginTop: "16px" }}
            >
              Продолжить
            </div>
          )
        ) : (
          <div
            className="buttonTo buttonTo__notactive"
            style={{ marginTop: "16px" }}
          >
            Продолжить
          </div>
        )*/}


        <MainButton onClick={() => handleSubmit()} disabled={!(accept && image)} loading={responseLoad} />
        <iframe src="https://vkvideo.ru/video_ext.php?oid=216729851&id=456239028&hd=2&hash=b45a06583d85c854" className="video__iframe" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
        {DeleteOrderComponent}
      </div>
    </>
  );
};
export default Task5;
