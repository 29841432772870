import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import "moment/locale/ru";
import "moment-timezone";

moment.locale("ru");

const MOSCOW_TIMEZONE = "Europe/Moscow";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const formattedDate = moment
      .tz(label, MOSCOW_TIMEZONE)
      .format("D MMMM YYYY г.");
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          fontSize: "14px",
        }}
      >
        <p
          className="label"
          style={{ marginBottom: "5px" }}
        >{`${formattedDate}`}</p>
        <p
          className="intro"
          style={{ color: "gray" }}
        >{`Выкуплено, шт: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const OrdersChart = ({ orders }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const aggregateOrders = () => {
      const aggregatedData = {};
      const last30Days = Array.from({ length: 30 }, (_, i) =>
        moment.tz(MOSCOW_TIMEZONE).subtract(i, "days").format("YYYY-MM-DD")
      );

      last30Days.forEach((date) => {
        aggregatedData[date] = 0;
      });

      orders.forEach((order) => {
        const date = moment
          .tz(order.attributes.step5Date, MOSCOW_TIMEZONE)
          .format("YYYY-MM-DD");
        if (aggregatedData[date] !== undefined) {
          aggregatedData[date] += 1;
        }
      });

      const formattedData = Object.keys(aggregatedData).map((date) => ({
        date,
        orders: aggregatedData[date],
      }));

      setData(formattedData.reverse());
    };

    aggregateOrders();
  }, [orders]);

  return (
    <div style={{ width: "100%", height: 200 }}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{ top: 5, right: 10, left: -20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line
            type="monotone"
            dataKey="orders"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OrdersChart;
