import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Main from './components/main/main';
import Cart from './components/cart/cart';
import Good from './components/good/good';
import Create from './components/create/create';
import { Toaster } from 'react-hot-toast';
import Deal from './components/deal/deal';
import Result from './components/result/result';
import Loader from './components/helpers/loader/loader';
import Contact from './components/contact/contact';
import appAPI from './api/service';
import Relation from './components/relation/relation';
import Page from './components/page/Page';
import Offers from './components/offers/offers';
import Orders from './components/orders/orders';
import AdminOffers from './components/admin-offers/admin-offers';
import AdminOffer from './components/admin-offer/admin-offer';
import AdminEdit from './components/admin-edit/edit';
import Earn from './components/earn/Earn';
import { ChannelPage } from './components/ChannelPage/ChannelPage';
import { ChannelCreatePage } from './components/ChannelCreatePage/ChannelCreatePage';
import { BlogerCreate } from './components/BlogerCreate/BlogerCreate';
import SendPostToBot from './components/SendPostToBot/SendPostToBot';
import SendPostToOrders from './components/SendPostToOrders/SendPostToOrders';
import ChannelsWithOffers from './components/ChannelWithOffers/ChannelWithOffers';
import {AdminOrders} from './components/admin-orders/admin-orders';
import AdminOrderEdit from './components/admin-order/admin-order';
import Cabinet from './components/cabinet/Cabinet';
import BalancePage from './components/BalancePage/BalancePage';
import { Statistics } from './components/statistics/Statistics';
import OrderEdit from './components/order-edit/order-edit';
import { AdminStatistics } from './components/admin-statistics/admin-statistics';

export let isAdmin = String(window.Telegram.WebApp.initDataUnsafe.user.id) === "408851642" || String(window.Telegram.WebApp.initDataUnsafe.user.id) === "336286853" 
//window.Telegram.WebApp.initDataUnsafe.user.id
function App() {
  const [userInfo, setUserInfo] = useState(null)


  const location = useLocation()

  function getQueryParams(data) {
    return new URLSearchParams(location.search).get(data) ? new URLSearchParams(location.search).get(data) : ""
  }


  useEffect(() => {
    window.Telegram.WebApp.expand()
    window.Telegram.WebApp.disableVerticalSwipes()
    window.Telegram.WebApp.setBottomBarColor("#FFFFFF")
    window.Telegram.WebApp.setBackgroundColor("#FFFFFF")
    window.Telegram.WebApp.setHeaderColor("#FFFFFF")
    //if (Object.keys(window.Telegram.WebApp.initDataUnsafe).length > 0) {
    async function fetch() {
      try {
        const response = await appAPI.getUser(window.Telegram.WebApp.initDataUnsafe.user.id, getQueryParams("ref") || "") // window.Telegram.WebApp.initDataUnsafe.user.id 
        if (response.data.length === 0) {
          const response = await appAPI.createUser(window.Telegram.WebApp.initDataUnsafe.user.id, getQueryParams("ref") || "")
          if (response.success === true) {
            const response = await appAPI.getUser(window.Telegram.WebApp.initDataUnsafe.user.id, getQueryParams("ref") || "")
            setUserInfo(response.data[0])
          }
        } else {
          setUserInfo(response.data[0])
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch()
    //}
  }, [])

  if (!userInfo) return <Loader />

  if(userInfo.isBan) return 
  return (
   
      <div className="app light">
        <Toaster />
        <Routes>
          <Route path="/" element={<Page userInfo={userInfo} />} />
          <Route path="/catalog" element={<Main userInfo={userInfo} />} />
          <Route path="/offers" element={<Offers userInfo={userInfo} />} />
          <Route path="/statistics" element={<Statistics userInfo={userInfo} />} />
          <Route path="/admin/statistics" element={<AdminStatistics userInfo={userInfo} />} />
          <Route path="/admin/offers" element={<AdminOffers userInfo={userInfo} />} />
          <Route path="/admin/orders" element={<AdminOrders userInfo={userInfo} />} />
          <Route path="/admin/channels" element={<ChannelsWithOffers userInfo={userInfo} />} />

          <Route path="/admin/offers/:id" element={<AdminOffer userInfo={userInfo} />} />
          
          
          <Route path="/orders/edit/:id" element={<OrderEdit userInfo={userInfo} />} />
          <Route path="/admin/orders/edit/:id" element={<AdminOrderEdit userInfo={userInfo} />} />
          <Route path="/admin/offers/edit/:id" element={<AdminEdit userInfo={userInfo} />} />

          <Route path="/orders/:id" element={<Orders userInfo={userInfo} />} />
          <Route path="/good/:id" element={<Good userInfo={userInfo}/>} />
          <Route path="/deal/:id" element={<Deal userInfo={userInfo}/>} />
          <Route path="/result/:id" element={<Result userInfo={userInfo} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cart" element={<Cart userInfo={userInfo} />} />
          <Route path="/cabinet" element={<Cabinet userInfo={userInfo} />} />
          <Route path="/balance" element={<BalancePage userInfo={userInfo} />} />
          <Route path="/create" element={<Create userInfo={userInfo} />} />
          <Route path="/relation" element={<Relation />} />
          <Route path="/earn" element={<Earn userInfo={userInfo}/>} />
          <Route path="/create/bloger" element={<BlogerCreate userInfo={userInfo}/>} />
          <Route path="/channel/:id" element={<ChannelPage userInfo={userInfo}/>} />
          <Route path="/channel/:id/create" element={<ChannelCreatePage userInfo={userInfo}/>} />
          <Route path="/send/post/subcribers" element={<SendPostToBot userInfo={userInfo}/>} />
          <Route path="/send/post/orders" element={<SendPostToOrders userInfo={userInfo}/>} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    
  );
}

export default App;
