import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import appAPI, { baseURL } from "../../api/service";
import toast from "react-hot-toast";
import Back from "../helpers/back";

const AlreadyPurchased = ({ username, id, NeedHelp }) => {
  const [offers, setOffers] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    console.log(username)
    if (!username) return;

    async function fetchOffers() {
      try {
        const response = await appAPI.getGoodsUser(username);
        if (response.data.length > 0) {
          setOffers(response.data.filter((work) => (work.id != id) && (Number(work.attributes.owner.data.attributes.limitsBalance) > 0)));
        } else {
          setOffers([]); 
        }
      } catch (error) {
        console.error(error);
        toast.error("Ошибка загрузки товаров");
      }
    }

    fetchOffers();
  }, [username]);

  return (
    <div style={{ width: "100%", height: "100vh", padding: "20px" }}>
        <Back func={() => nav("/catalog")} />
      <div style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
        Этот товар уже разобрали
      </div>

      <div
        onClick={() => nav("/catalog")}
        style={{
          height: "46px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
          color: "white",
          background: "#731358",
          borderRadius: "8px",
          cursor: "pointer", // Добавляем курсор-указатель для кликабельности
        }}
      >
        Перейти в каталог
      </div>

      { NeedHelp ? NeedHelp : <div
        className="wrohgs"
        style={{ position: "static", width: "100%", margin: "30px 0", cursor: "pointer" }}
        onClick={() => window.open("https://telegra.ph/Voznikla-problema-06-19")}
      >
        Нужна помощь
      </div>}

      {offers.length > 0 && (
        <div>
          <div style={{ fontWeight: "bold", fontSize: "20px", marginBottom: "10px" }}>
            Другие товары этого продавца
          </div>
          <div className="catalog">
            {offers.map((work) => {
              const { dealsLimit, dealsDayLimitDisplay, image, priceForUser, priceOnSite, name } = work.attributes;
              const isAvailable = dealsLimit > 0 && dealsDayLimitDisplay > 0;
              return (
                <div
                  key={work.id}
                  className="cart"
                  onClick={() => {
                    if (isAvailable) {
                      nav(`/good/${work.id}`);
                    } else {
                      toast.error("Будет завтра");
                    }
                  }}
                >
                  {image?.data && (
                    <img
                      src={`${baseURL}${image.data.attributes.url}`}
                      alt={name} // Добавляем alt для изображений
                      style={{ maxWidth: "100%", borderRadius: "8px" }} // Добавляем стили для изображения
                    />
                  )}

                  {isAvailable ? (
                    <div className="info">
                      <div className="price_catalog">
                        <div>{priceForUser} ₽</div>
                        <div className="price_catalog_wb">{priceOnSite} ₽</div>
                      </div>
                      <div className="title">{name}</div>
                    </div>
                  ) : (
                    <div style={{ marginTop: "10px", textAlign: "center" }}>
                      Будет завтра
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AlreadyPurchased;
