import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Filter from "./blocks/filter";
import appAPI, { baseURL } from "../../api/service";
import Loader from "../helpers/loader/loader";
import toast from "react-hot-toast";
import Back from "../helpers/back";
import { Section, SegmentedControl } from "@telegram-apps/telegram-ui";
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import Good from "../good/good";

const Main = ({userInfo}) => {
  const nav = useNavigate();
  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadedFilters, setLoadingFilters] = useState(false);


  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [stop, setStop] = useState(false);

  const [goods, setGoods] = useState(null);
  const [filteredGoods, setFilteredGoods] = useState(null);

  const [selectedGood, setSelectedGood] = useState(null)

  const [filters, setFilters] = useState({
    typeFilter: {
      Женщинам: false,
      Мужчинам: false,
      Обувь: false,
      Детям: false,
      Дом: false,
      "Новый год": false,
      Красота: false,
      Аксессуары: false,
      Электроника: false,
      Игрушки: false,
      Мебель: false,
      "Товары для взрослых": false,
      "Бытовая техника": false,
      Зоотовары: false,
      Спорт: false,
      Автотовары: false,
      "Ювелирные изделия": false,
      "Для ремонта": false,
      "Сад и дача": false,
      Здоровье: false,
      Канцтовары: false,
    },
    selectedFilters: [],
  });

  const lastElement = useRef(null);

  useEffect(() => {
    if (!filteredGoods) return;
    localStorage.setItem(
      "WBCASHBACKFILTERS",
      JSON.stringify(filters.typeFilter)
    );
  }, [filters]);

  useEffect(() => {
    if (localStorage.getItem("WBCASHBACKFILTERS")) {
      const loadedFilters = JSON.parse(
        localStorage.getItem("WBCASHBACKFILTERS")
      );

      const typeFilterKeys = Object.entries(loadedFilters)
        .filter(([key, value]) => value)
        .map(([key]) => key);

      setFilters({
        ...filters,
        typeFilter: loadedFilters,
        selectedFilters: typeFilterKeys,
      });
    }
  }, []);

  const location = useLocation();

  function getQueryParams(data) {
    return new URLSearchParams(location.search).get(data)
      ? new URLSearchParams(location.search).get(data)
      : "";
  }

  useEffect(() => {
    if (!userInfo || stop) return;
    setLoading(true);
    console.log("fetch")
    async function fetch() {
      try {
        
        const uniqueIds = userInfo.attributes.orders.data.reduce((acc, order) => {
          const id = order.attributes?.offer?.data?.id;
          if (id && !acc.includes(id)) {
              acc.push(id);
          }
          return acc;
        }, []);


        const uniqueBrands = userInfo.attributes.orders.data.reduce((acc, order) => {
          const brand = order.attributes.offer?.data?.attributes?.brandName;
          const unsuccessOrders = userInfo.attributes.orders.data.filter(
            (order) => {
              return (
                order.attributes.status !== "Оплачено" && Number(order.attributes.step) >= 6 &&
                order.attributes.offer.data.attributes.brandName === brand
              )
            }      
          );

          console.log(brand, unsuccessOrders)
          if (unsuccessOrders.length > 0 && brand && !acc.includes(brand) && order.attributes.step >= 6) {
              acc.push(brand);
          }
          return acc;
      }, []);

      console.log(uniqueBrands)

      const validUsername =
            userInfo.attributes.username &&
            /^[^\d]*$/.test(userInfo.attributes.username)

        if(getQueryParams("ownerUsername").trim() != "") {
          const response = await appAPI.getGoodsUser(getQueryParams("ownerUsername").trim(), filters.selectedFilters, page, limit, uniqueBrands, uniqueIds, !validUsername);
          if (response.data.length < limit) setStop(true);
          setGoods((prev) => (prev ? [...prev, ...response.data] : response.data));
        } else {
          const response = await appAPI.getGoods(filters.selectedFilters, page, limit, uniqueBrands, uniqueIds, !validUsername)
          if (response.data.length < limit) setStop(true);
          setGoods((prev) => (prev ? [...prev, ...response.data] : response.data));
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, [page]);

  useEffect(() => {
    if (!filters.selectedFilters) return;
    setLoadingFilters(true);
    setPage(1);
    setGoods([]);
    setTimeout(() => setLoadingFilters(false), 2000);
  }, [filters.selectedFilters]);

  useEffect(() => {
    if (!goods || goods.length === 0 || !lastElement.current || stop) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    const currentElement = lastElement.current;

    observer.observe(currentElement);

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [goods, lastElement, stop]);

  if (goods === null) return <Loader />;

  return (
    <>
      <div className={`main_container `}>
      {!selectedGood && <Back /> }
      {selectedGood && <div className="good_page__catalog"><Good selectedGood={selectedGood} setSelectedGood={setSelectedGood} catalogScroll={true} userInfo={userInfo} /></div>}
      {isFilter && (
        <Filter
          filters={filters}
          setFilters={setFilters}
          isFilter={isFilter}
          setIsFilter={setIsFilter}
        />
      )}
      <Section header="ВАШИ ОБЪЯВЛЕНИЯ" className="tabs">
      <SegmentedControl>
          <SegmentedControlItem
              selected={true}
          >
              Каталог
          </SegmentedControlItem>
          <SegmentedControlItem
              onClick={() => {
                nav("/cart")
              }}
              selected={false}
          >
              Мои покупки
          </SegmentedControlItem>
      </SegmentedControl>
      </Section>
      <div className={`container`}>
        <div className="header">
          {/*<h1>Актуальные товары</h1>*/}
          <div
            onClick={() => nav("/admin/offers")}
            style={{ display: "block", marginLeft: "auto", marginBottom:"16px" }}
          >
            Разместить товар
          </div>
        </div>
        {/*<div className="search_block">
          <label
            className={`input_search ${isFocused && "active_input"}`}
            tabIndex="0"
          >
            <div className={`search_icon`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Поиск"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {search !== "" && (
              <div
                className="close_icon"
                onClick={(e) => {
                  handleFocus(e);
                  setSearch("");
                }}
              >
                <xml version="1.0" encoding="utf-8" />
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" fill="none" />
                  <path
                    d="M7 17L16.8995 7.10051"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 7.00001L16.8995 16.8995"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </label>
          <div className="filter_search" onClick={() => setIsFilter(true)}>
            <xml version="1.0" encoding="utf-8" />
            <svg
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 7C3 6.44772 3.44772 6 4 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7ZM6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12ZM9 17C9 16.4477 9.44772 16 10 16H14C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18H10C9.44772 18 9 17.5523 9 17Z"
                fill="#000000"
              />
            </svg>
          </div>
        </div>*/}
        <div className={`catalog`}>
          {goods && goods.length !== 0 ? (
            goods.map((work, index) => {
              if (
                !work.attributes.dealsLimit ||
                Number(work.attributes.dealsLimit) <= 0 
              ) return;
              return (
                <div
                  className="cart"
                  ref={ index == goods.length - 1 ? lastElement : null}
                  onClick={() => {
                    if (
                      work.attributes.dealsLimit &&
                      Number(work.attributes.dealsLimit) > 0 &&
                      Number(work.attributes.dealsDayLimitDisplay) > 0
                    ) {
                      setSelectedGood(work.id)
                      //nav(`/good/${work.id}`);
                    } else {
                      toast.error("Будет завтра");
                    }
                  }}
                >
                  {work.attributes.image.data && (
                    <img
                      src={`${baseURL}${work.attributes.image.data.attributes.url}`}
                    />
                  )}

                  {work.attributes.dealsLimit &&
                  Number(work.attributes.dealsLimit) > 0 &&
                  Number(work.attributes.dealsDayLimitDisplay) > 0 ? (
                    <div className="info">
                      <div className="price_catalog">
                        <div>{work.attributes.priceForUser} ₽</div>
                        <div className="price_catalog_wb">
                          {work.attributes.priceOnSite} ₽
                        </div>
                      </div>
                      <div className="title">{work.attributes.name}</div>
                    </div>
                  ) : (
                    <>
                      <div style={{ marginTop: "10px", textAlign: "center" }}>
                        Будет завтра
                      </div>
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <div
              style={{ textAlign: "center", marginTop: "30px", width: "100%" }}
            >
                Каталог товаров пуст
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default Main;
