import { useState } from "react"
import toast from "react-hot-toast"
import appAPI from "../../../api/service"


export function useExportExcel() {
    const [loading, setLoading] = useState(false)

    async function exportData() {
        if(loading === true) return  
        setLoading(true)
        try {
            const response = await appAPI.exportData() 
            if(response.success == false) {
                return toast.error(response.error)
            }
            toast.success("Ваш отчет в чате с ботом")
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    return {
        exportData,
        loading
    }
}