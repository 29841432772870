import { useEffect, useState } from "react";
import { isAdmin } from "../../../App";
import appAPI from "../../../api/service";

export function useProducts(filters, search) {

    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true)
    const [offers, setOffers] = useState([]);
    const [stop, setStop] = useState(false)

    async function getProducts(page, article = "", status = "") {
        if(stop) return 
        console.log("fetch")
        setIsLoading(true)
        try {
            if(page === 1) setOffers([])
            const response = await appAPI.getAdminGoodsUser(page, 20, {
                article,
                status,
                isAdmin
            });
            if(response.data.length < 20) setStop(true)
            setOffers(prev => prev.concat(response.data));
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setStop(false);
        setPage(1);
    }, [filters, search]);

    
    useEffect(() => {
        getProducts(page, search || "", filters);
    }, [page, filters, search, stop]); 
    
      
    return {offers, getProducts, setOffers, isLoading, isStop: stop, setPage}
}