import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import appAPI from "../../../api/service";
import { Title } from "@telegram-apps/telegram-ui";
import { InputComponent } from "../../../shared/ui/Input";
import { CheckboxComponent } from "../../../shared/ui/Checkbox";
import { SelectComponent } from "../../../shared/ui/Select";
import { MainButton } from "../../../shared/telegram-elements/MainButton";
import { Loading } from "./../components/Loading";

const Task4 = ({ setTask, orderInfo, DeleteOrderComponent }) => {
  const [accept, setAccept] = useState(false);
  const [responseLoad, setResponseLoad] = useState(false);

  const banks = [
    { value: "", title: "—" },
    { value: "Тинькофф", title: "Тинькофф" },
    { value: "Сбербанк", title: "Сбербанк" },
    { value: "Газпром банк", title: "Газпром банк" },
    { value: "Рнкб", title: "Рнкб" },
    { value: "ВТБ", title: "ВТБ" },
    { value: "Райффайзен банк", title: "Райффайзен банк" },
    { value: "Озон банк", title: "Озон банк" },
    { value: "Открытие", title: "Открытие" },
    { value: "Альфа банк", title: "Альфа банк" },
    { value: "Хоум кредит", title: "Хоум кредит" },
    { value: "УБРиР", title: "УБРиР" },
    { value: "Яндекс", title: "Яндекс" },
  ];

  const [card, setCard] = useState("");
  const [text, setText] = useState("");
  const [bank, setBank] = useState("");
  const [name, setName] = useState("");

  const handleCardChange = (text) => {
    const value = text.replace(/\D/g, "");
    setCard(value);
  };

  const handleTextChange = (text) => {
    const value = text.replace(/\D/g, "");
    setText(value);
  };

  const handleSubmit = async () => {
    if (responseLoad == true) return;
    setResponseLoad(true);
    if (!card || !text || !bank || !name)
      return toast.error("Введите необходимые значения");
    let queryAddition = `&card=${card.trim()}&phone=${text.trim()}&bank=${bank.trim()}&name=${name}`;
    const formattedText = `${card.trim()}::${text.trim()}::${bank.trim()}::${name.trim()}`;
    try {
      const response = await appAPI.updateOrder(
        5,
        formattedText,
        null,
        orderInfo.id,
        null,
        queryAddition
      );
      if (response.success) {
        toast.success("Успешно отправленно");
        setTask(5);
      } else {
        toast.error(response.error ?? "Неизвестная ошибка");
      }
    } catch (e) {
      console.log(e.message);
      toast.error(e.message ?? "Неизвестная ошибка");
    } finally {
      setResponseLoad(false);
    }
  }

  return (
    <>
    {responseLoad && <Loading />}
      <div className="container">
        <Title weight="1">Реквизиты для перевода кэшбэка</Title>

        <InputComponent
          header="Номер карты"
          placeholder={"Введите номер карты"}
          value={card}
          onChange={handleCardChange}
        />
        <InputComponent
          header="Номер телефона"
          placeholder={"Введите номер телефона"}
          value={text}
          onChange={handleTextChange}
        />

        <InputComponent
          header="Фамилия и имя"
          placeholder={"Введите ФИ"}
          value={name}
          onChange={setName}
        />

        <SelectComponent
          value={bank}
          options={banks}
          onChange={setBank}
          placeholder="Выберите банк"
          header="Выберите банк"
        />


        <CheckboxComponent
          title={"Подтверждаю правильность реквизитов"}
          value={accept}
          onChange={setAccept}
        />

<MainButton onClick={() => handleSubmit()} disabled={!(text && accept && card && bank && name)} loading={responseLoad} />

        {/*text && accept && card && bank && name ? (
          responseLoad ? (
            <div className="buttonTo" style={{ marginTop: "16px" }}>
              Загрузка...
            </div>
          ) : (
            <div
              className="buttonTo"
              onClick={handleSubmit}
              style={{ marginTop: "16px" }}
            >
              Продолжить
            </div>
          )
        ) : (
          <div
            className="buttonTo buttonTo__notactive"
            style={{ marginTop: "16px" }}
          >
            Продолжить
          </div>
        )*/}
        <iframe
          src="https://vkvideo.ru/video_ext.php?oid=216729851&id=456239029&hd=2&hash=30f5ab8da17a76bf"
          className="video__iframe"
          allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
          frameborder="0"
          allowfullscreen
        ></iframe>
        {DeleteOrderComponent}
      </div>

     

      
    </>
  );
};

export default Task4;
