import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../helpers/back";
import axios from "axios";
import appAPI, { baseURL } from "../../api/service";
import Loader from "../helpers/loader/loader";
import { isAdmin } from "../../App";

const AdminEdit = ({ userInfo }) => {
  const nav = useNavigate();
  const { id } = useParams(); 
  const [brandStatus, setBrandStatus] = useState(false);

  const [goodInfo, setOffer] = useState(null)


  const [image, setImage] = useState(null);
  const [values, setValues] = useState({
    title: "",
    category: null,
    brand: "",
    brandName: "",
    price_site: null,
    price_client: null,
    photo: null,
    amount: null,
    dealsDayLimit: 10,
    dealsDayLimitDisplay: null,
    commentRules: "",
    keyword: "",
    articul: "",
    link: "-",
    conditionals: "-",
    description_3: "0",
    writeToSeller: false,
    cashbackType: "",
    description: "",
    test: false,
    withInstruction: false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await appAPI.getGoodAdmin(id);
        const data = response.data.attributes;
        setValues({
          title: data.name,
          category: data.categories,
          brand: data.brand,
          brandName: data.brandName,
          price_site: data.priceOnSite,
          price_client: data.priceForUser,
          photo: data.image.data.attributes.id,
          amount: data.dealsLimit,
          dealsDayLimitDisplay: data.dealsDayLimitDisplay,
          dealsDayLimit: data.dealsDayLimit,
          commentRules: data.commentRules,
          keyword: data.keywords,
          articul: data.article,
          writeToSeller: data.writeToSeller === true ? true : false,
          cashbackType: data.cashbackType,
          description : data.description,
          test: data.test,
          withInstruction: !data.withInstruction
        });
        setImage({ img: `${baseURL}${data.image.data.attributes.url}`, file: null });
        setOffer(response.data)
      } catch (error) {
        console.error(error);
        toast.error("Ошибка при загрузке данных");
      }
    };

    fetchData();
  }, [id]);

  const handleSetImage = (image) => {
    if (!image) return;
    const fileReader = new FileReader(image);
    fileReader.onload = () => {
      setImage({ img: fileReader.result, file: image });
      setValues({ ...values, photo: image });
    };
    fileReader.readAsDataURL(image);
  };

  const handleSubmit = async () => {

    const isAllFieldsFilled = Object.entries(values).every(
      ([key, val]) => (
        key === "test" || 
        (val !== "" && val !== null) ||
        (values.withInstruction ? (key === "keyword" || key === "commentRules")  : key === "withInstruction")
    ));


    if (isAllFieldsFilled) {
      let imageId = values.photo;

      if (image && image.file) {
        const formData = new FormData();
        formData.append("files", image.file);

        try {
          const response = await appAPI.uploadImage(formData)
          if (!response[0]) {
            return toast.error("Ошибка при загрузке картинки");
          }
          imageId = response[0].id;
        } catch (error) {
          console.error(error);
          return toast.error("Ошибка при загрузке картинки");
        }
      }

      const data = {
        name: values.title,
        brand: values.brand,
        brandName: values.brandName,
        article: values.articul,
        image: imageId,
        wbLink: values.link,
        keywords: values.keyword,
        dealsLimit: values.amount,
        dealsDayLimit: values.dealsDayLimit,
        dealsDayLimitDisplay: Number(values.dealsDayLimitDisplay) < Number(values.dealsDayLimit) ? values.dealsDayLimitDisplay : values.dealsDayLimit,
        priceOnSite: values.price_site,
        priceForUser: values.price_client,
        categories: values.category,
        description: values.conditionals,
        howSearch: values.description_3,
        commentRules: values.commentRules,
        writeToSeller: values.writeToSeller,
        cashbackType: values.cashbackType,
        description: values.description,
        test: values.test,
        withInstruction: !values.withInstruction
      };

      try {
        const res = await appAPI.updateOffer(id, data);
        console.log(res)
        if (!res || res.data === null) {
          return toast.error("Ошибка при обновлении");
        }
        toast.success("Успешно обновлено");
        return nav("/admin/offers/" + id)
      } catch (error) {
        console.error(error);
        toast.error("Ошибка при обновлении");
      }
    } else {
      toast.error("Заполните все поля");
    }
  };



  if(!goodInfo) return <Loader />
  
  const username = String(userInfo?.attributes?.username?.toLowerCase().trim())
  const orderBrand = String(goodInfo?.attributes?.brand?.toLowerCase().trim())
  const telegramUsername = window.Telegram?.WebApp?.initDataUnsafe?.user?.username?.toLowerCase().trim();
  const additionalOffers = userInfo.attributes.work_offers.data
  const isUserSeller = (goodInfo?.attributes?.creatorID?.toLowerCase().trim() === userInfo?.attributes.uid) || (username && username === orderBrand) || (telegramUsername && telegramUsername === orderBrand) || (additionalOffers.some((offer) => offer.id == goodInfo?.id));

  if(!isUserSeller && !isAdmin) return <div style={{ textAlign: "center", marginTop: "30px" }}>
    Данный товар не найден
  </div>

  return (
    <>
      <Back />
      <div
        className="title_page"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        <div
          onClick={() => nav("/admin/offers/" + id)}
          style={{
            width: "fit-content",
            fontSize: "13px",
            cursor: "pointer",
            fontSize: "0.75rem",
            textTransform: "uppercase",
          }}
        >
          Отменить
        </div>
        Редактировать товар
        <div
          onClick={handleSubmit}
          style={{
            width: "fit-content",
            textAlign: "end",
            cursor: "pointer",
            fontSize: "0.75rem",
            color: "#731358",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          Обновить
        </div>
      </div>

      <div className="container create_good">

      {/*<div className="input_question_block">
        <label className="input_brand">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={values.withInstruction}
            onChange={(e) =>
              setValues({
                ...values,
                withInstruction: e.target.checked
              })
            }
          />
          <span></span>
          <div className="brand_question">
            Товар без инструкции
            <br />
            <div className="require_question">
              Поставьте галочку, чтобы товар был без инструкции
            </div>
          </div>
        </label>
      </div>*/}

      <div className="input_question_block">
          <label className="input_question">
            <div>
              Название товара
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите название товара"
              value={values.title}
              onChange={(e) => setValues({ ...values, title: e.target.value })}
            />
          </label>
        </div>

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Бренд товара
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите бренд товара"
              value={values.brandName}
              onChange={(e) =>
                setValues({ ...values, brandName: e.target.value })
              }
            />
          </label>
        </div>


        <div className="input_question_block">
          <label className="input_question">
            <div>
              Артикул<div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder="Введите артикул"
              value={values.articul}
              onChange={(e) =>
                setValues({ ...values, articul: e.target.value })
              }
            />
          </label>
        </div>
        <div className="hint_block" style={{marginBottom:"10px"}}>
          Внимательно вводите номер артикула. По нему будет проводится
          подтверждение товара
        </div>

        <div className="image_question_block">
          <label className="input_question">
            <div>
              Фото товара<div className="require_question">Обязательное</div>
            </div>
            <label>
              <div>
                <div className="image_picker">
                  <div className="image_picker_info">
                    {image && <img className="image_select" src={image.img} />}
                    <div className="text_file_image">
                      {image ? image?.file?.name : "Выберите изображение..."}
                    </div>
                  </div>
                  <div>
                    {image && (
                      <svg
                        onClick={(e) => {
                          e.preventDefault();
                          setImage(null);
                          setValues({ ...values, photo: null });
                        }}
                        className="image_picker_close"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" fill="none" />
                        <path
                          d="M7 17L16.8995 7.10051"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 7.00001L16.8995 16.8995"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleSetImage(e.target.files[0])}
                placeholder="Выберите изображение..."
                style={{ display: "none" }}
              />
            </label>
          </label>
        </div>
        
        <div className="input_question_block">
          <label className="input_question">
            <div>
              Категория <div className="require_question">Обязательное</div>
            </div>
            <select
              value={values.category}
              onChange={(e) =>
                setValues({ ...values, category: e.target.value })
              }
            >
              <option value="">—</option>
              <option value="Женщинам">Женщинам</option>
              <option value="Мужчинам">Мужчинам</option>
              <option value="Обувь">Обувь</option>
              <option value="Детям">Детям</option>
              <option value="Дом">Дом</option>
              <option value="Новый год">Новый год</option>
              <option value="Красота">Красота</option>
              <option value="Аксессуары">Аксессуары</option>
              <option value="Электроника">Электроника</option>
              <option value="Игрушки">Игрушки</option>
              <option value="Мебель">Мебель</option>
              <option value="Товары для взрослых">Товары для взрослых</option>
              <option value="Бытовая техника">Бытовая техника</option>
              <option value="Зоотовары">Зоотовары</option>
              <option value="Спорт">Спорт</option>
              <option value="Автотовары">Автотовары</option>
              <option value="Ювелирные изделия">Ювелирные изделия</option>
              <option value="Для ремонта">Для ремонта</option>
              <option value="Сад и дача">Сад и дача</option>
              <option value="Здоровье">Здоровье</option>
              <option value="Канцтовары">Канцтовары</option>
            </select>
          </label>
        </div>


        <div className="input_question_block">
          <label className="input_question">
            <div>
              Ключевое слово
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder={`Например "рубашка в клетку"`}
              value={values.keyword}
              onChange={(e) =>
                setValues({ ...values, keyword: e.target.value })
              }
            />
          </label>
        </div>

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Общий план по выкупам
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="number"
              placeholder="Общий план по выкупам"
              value={values.amount}
              onChange={(e) => setValues({ ...values, amount: e.target.value })}
            />
          </label>
        </div>

        <div className="input_question_block">
          <label className="input_question">
            <div>
                План выкупов на сутки
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="number"
              placeholder="План выкупов на сутки"
              value={values.dealsDayLimit}
              onChange={(e) =>
                setValues({ ...values, dealsDayLimit: e.target.value })
              }
            />
          </label>
        </div>

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Цена на вб (руб)
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="number"
              placeholder="Укажите цену на вб"
              value={values.price_site}
              onChange={(e) =>
                setValues({ ...values, price_site: e.target.value })
              }
            />
          </label>
        </div>
        <div className="input_question_block">
          <label className="input_question">
            <div>
              Цена для покупателя<div className="require_question">Обязательное</div>
            </div>
            <input
              type="number"
              placeholder="Цена для покупателя"
              value={values.price_client}
              onChange={(e) =>
                setValues({ ...values, price_client: e.target.value })
              }
            />
          </label>
        </div>

        <div className="input_question_block">
          <label className="input_question">
            <div>
              Телеграм ник для связи
              <div className="require_question">Обязательное</div>
            </div>
            <input
              type="text"
              placeholder={`Например "ruslanamantayev"`}
              value={values.brand}
              onChange={(e) => setValues({ ...values, brand: e.target.value })}
            />
          </label>
        </div>
        
        <div className="hint_block">
          Введенное имя преобразуется в ссылку для связи с вами:
          <br />
          <b>https://t.me/ruslanamantayev</b>
          <br />
          Не указывайте знак @
        </div>

        {/*<div className="input_question_block">
          <label className="input_brand">
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={values.writeToSeller}
              onChange={(e) =>
                setValues({
                  ...values,
                  writeToSeller: e.target.checked,
                })
              }
            />
            <span></span>
            <div className="brand_question">
              Показывать контакты покупателю
              <br />
              <div className="require_question">
                Поставьте галочку, чтобы ваши контакты отображались для покупателя
              </div>
            </div>
          </label>
        </div>*/}


        <div className="input_question_block">
          <label className="input_question">
            <div>
              Когда выплата <div className="require_question">Обязательное</div>
            </div>
            <select
              value={values.cashbackType}
              onChange={(e) =>
                setValues({ ...values, cashbackType: e.target.value })
              }
            >
              <option value="">—</option>
              <option value="После публикации отзыва">После отзыва</option>
              <option value="После получения товара">После получения товара</option>
              <option value="После получения товара через 15 дней">На 15й день</option>
            </select>
          </label>
        </div>

        {isAdmin && <div className="input_question_block">
          <label className="input_question">
            <div>
              Дополнительные условия
              <div className="require_question">Обязательное</div>
            </div>
            <textarea
              type="text"
              placeholder={`Дополнительные условия`}
              value={values.description}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
            />
          </label>
        </div>}
  
        
        <div className="input_question_block">
          <label className="input_question">
            <div>
            Требования к отзыву
              <div className="require_question">Обязательное</div>
            </div>
            <textarea
              type="text"
              placeholder={`Опишите ваши требования к фото/видео/тексту/оценке/ `}
              value={values.commentRules}
              onChange={(e) =>
                setValues({ ...values, commentRules: e.target.value })
              }
            />
          </label>
        </div>

        <div className="input_question_block">
          <label className="input_brand">
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={values.test}
              onChange={(e) =>
                setValues({
                  ...values,
                  test: e.target.checked
                })
              }
            />
            <span></span>
            <div className="brand_question">
            Согласовать отзыв
              <br />
              <div className="require_question">
                Поставьте галочку, чтобы c вами согласовывали отзыв
              </div>
            </div>
          </label>
        </div>

        <div className="input_question_block">
          <label className="input_brand">
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={values.writeToSeller}
              onChange={(e) =>
                setValues({
                  ...values,
                  writeToSeller: e.target.checked,
                })
              }
            />
            <span></span>
            <div className="brand_question">
              Показывать контакты покупателю
              <br />
              <div className="require_question">
                Поставьте галочку, чтобы ваши контакты отображались для покупателя
              </div>
            </div>
          </label>
        </div>
      </div>
    </>
  );
};

export default AdminEdit;



