export const baseURL = process.env.REACT_APP_BASE_URL 
export const USER_TOKEN = window.Telegram.WebApp.initDataUnsafe.user.id

let token;

if(process.env.NODE_ENV === "development") {
    token = process.env.REACT_APP_TOKEN
} else {
    token = window.Telegram?.WebApp?.initData
}


async function fetchData(url, json = null, method = "POST", token_addition, options) {
    const { contentType } = options || {};

    if(!json) {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": contentType ?? "application/json",
                "x-tg-data": token
            }
        }

        if (token_addition) {
            options.headers["Authorization"] = `Bearer ${token_addition}`;
        }
        
        const res = await fetch(url, options).then(res => res.json())

        return res
    } else {
        const options = {
            method,
            body: !contentType && contentType != "" ? JSON.stringify(json) : json,
            headers: {
                "x-tg-data": token
            }
        }

        if (token_addition) {
            options.headers["Authorization"] = `Bearer ${token_addition}`;
        }

        if(!contentType && contentType != "") {
            options.headers["Content-Type"] = "application/json"
        }
        
        const res = await fetch(url, options).then(res => res.json())

        return res
    }
}

const appAPI = {
    async getUser(id, ref) {
        return fetchData(`${baseURL}/api/app-users?filters[uid][$eq]=${id}&populate=orders,orders.offer,orders.offer.image,offers, offers.image, offers.orders, work_offers, work_offers.image&ref=${ref}`)
    },
    async getAdminStatistics() {
        return fetchData(`${baseURL}/api/getAdminStatistics?userId=${USER_TOKEN}`)
    },
    async getStatistics() {
        return fetchData(`${baseURL}/api/getStatistics?userId=${USER_TOKEN}`)
        try {
            const option = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token
                }
            }
            const res = await fetch(`${baseURL}/api/getStatistics?userId=${USER_TOKEN}`, option).then(res => res.json())
            return res
        } catch (err) {
            console.log(err)
        }
    },
    async exportData() {
        return fetchData(`${baseURL}/api/exportData?userId=${USER_TOKEN}`)
        try {
            const option = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token
                }
            }
            const res = await fetch(`${baseURL}/api/exportData?userId=${USER_TOKEN}`, option).then(res => res.json())
            return res
        } catch (err) {
            console.log(err)
        }
    },
    async getChannels() {
        return fetchData(`${baseURL}/api/app-users?filters[isChannel][$eq]=true&pagination[pageSize]=1000&pagination[page]=1&populate[referrals_orders][fields][0]=step5Date&populate[referrals_orders][fields][1]=step6Date`)
        try {
            const option = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token
                }
            }
            const res = await fetch(`${baseURL}/api/app-users?filters[isChannel][$eq]=true&pagination[pageSize]=1000&pagination[page]=1&populate[referrals_orders][fields][0]=step5Date&populate[referrals_orders][fields][1]=step6Date`, option)
                .then(res => res.json());
            return res;
        } catch (err) {
            console.log(err);
        }
    },        
    async createUser(id, ref) {
        return fetchData(`${baseURL}/api/user?uid=${id}&ref=${ref}`)
        try {
            const option = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            }
            const res = await fetch(`${baseURL}/api/user?uid=${id}&ref=${ref}`, option).then(res => res.json())
            return res
        } catch (err) {
            console.log(err)
        }
    },
    async sendRevision(id, revisionOwner, comment) {
        return fetchData(`${baseURL}/api/revisionOrder?orderId=${id}&revisionOwner=${revisionOwner}&comment=${comment}`)
    },
    async getGoods(categories, page, limits, uniqueBrands, uniqueIds, limit = false) {
        let categoryFilters = '';
        let brandFilter = '';
        let idFilter = '';
        let limitFilter = '';

        // Обработка категорий
        if (categories && categories.length > 0) {
            categoryFilters = categories.map((category, index) => `&filters[categories][$contains]=${category}`).join('');
        }

        // Обработка уникальных брендов
        if (uniqueBrands && uniqueBrands.length > 0) {
            brandFilter = uniqueBrands.map((brand, index) => `&filters[brandName][$notIn][${index}]=${encodeURIComponent(brand)}`).join('');
        }

        // Обработка уникальных идентификаторов
        if (uniqueIds && uniqueIds.length > 0) {
            idFilter = uniqueIds.map((id, index) => `&filters[id][$notIn][${index}]=${id}`).join('');
        }

        if(limit == true) {
            limitFilter = `&filters[priceOnSite][$lte]=700`
        }
    
        return fetchData(`${baseURL}/api/offers?populate=owner,Seller,image&filters[owner][limitsBalance][$gt]=0&filters[$or][0][onlyRef][$eq]=false&filters[$or][1][onlyRef][$null]=true${categoryFilters}&filters[dealsLimit][$gt]=0&filters[inCatalog][$eq]=true&pagination[pageSize]=${limits}&pagination[page]=${page}&sort[0]=dealsDayLimitDisplay:desc${brandFilter}${idFilter}`)
        try {
            const option = {
                method: "GET",
                headers: {
                    // добавьте нужные заголовки, если необходимо
                }
            }
            const res = await fetch(`${baseURL}/api/offers?populate=owner,Seller,image&filters[owner][limitsBalance][$gt]=0&filters[$or][0][onlyRef][$eq]=false&filters[$or][1][onlyRef][$null]=true${categoryFilters}&filters[dealsLimit][$gt]=0&filters[inCatalog][$eq]=true&pagination[pageSize]=${limits}&pagination[page]=${page}&sort[0]=dealsDayLimitDisplay:desc${brandFilter}${idFilter}`, option).then(res => res.json())

            return res
        } catch (err) {
            console.log(err)
        }
    },

    async getAdminGoodsUser(page, limit = 20, {
        article,
        status,
        isAdmin
    }) {

        let string = "&filters[$and][0][$or][0][onlyRef][$eq]=false&filters[$and][0][$or][1][onlyRef][$null]=true";

        string += {
            0: `&filters[$and][1][$or][0][owner][uid][$eq]=${USER_TOKEN}&filters[$and][1][$or][1][workers_lists][uid][$in]=${USER_TOKEN}`,
            1: ""
        }[isAdmin ? 1 : 0]

        if(status) {
            string += {
                "active": "&filters[dealsLimit][$ne]=0&filters[inCatalog][$eq]=true",
                "archive": "&filters[dealsLimit][$eq]=0&filters[$and][2][$or][0][inCatalog][$ne]=true&filters[$and][2][$or][1][inCatalog][$eq]=null",
                "all": ""
            }[status]
        }

        if(article) {
            string += `&filters[name][$contains]=${article}`
        }

        string += "&sort[0]=createdAt:desc";

        return fetchData(`${baseURL}/api/offers?populate=owner,Seller,image${string}&pagination[pageSize]=${limit}&pagination[page]=${page}`)
        try {
            const option = {
                method: "GET"
            }
            const res = await fetch(`${baseURL}/api/offers?populate=owner,Seller,image${string}&pagination[pageSize]=${limit}&pagination[page]=${page}`, option).then(res => res.json())
            return res
        } catch (err) {
            console.log(err)
        }
    },

    async getAdminOrdersUser(page, limit = 20, {
        article,
        status,
        currentStep,
        isAdmin
    }) {

        let string;

        string += {
            "all": "&filters[step][$gte]=6",
            "book": "&filters[step][$eq]=6",
            "receive": "&filters[step][$eq]=7",
            "feedback": "&filters[step][$eq]=8",
        }[currentStep]

        string += {
            0: `&filters[$and][1][$or][0][offer][owner][uid][$eq]=${USER_TOKEN}&filters[$and][1][$or][1][offer][workers_lists][uid][$in]=${USER_TOKEN}`,
            1: ""
        }[isAdmin ? 1 : 0]

        if(status) {
            string += {
                "active": "&filters[status][$eq]=Оплачено",
                "archive": "&filters[status][$eq]=Не оплачено",
                "all": ""
            }[status]
        }

        if(article) {
            string += `&filters[id][$contains]=${article}`
        }

        string += "&sort[0]=step6Date:desc";

        return fetchData(`${baseURL}/api/orders?populate=offer,offer.owner,offer.image${string}&pagination[pageSize]=${limit}&pagination[page]=${page}`)
        try {
            const option = {
                method: "GET"
            }
            const res = await fetch(`${baseURL}/api/orders?populate=offer,offer.owner,offer.image${string}&pagination[pageSize]=${limit}&pagination[page]=${page}`, option).then(res => res.json())
            return res
        } catch (err) {
            console.log(err)
        }
    },

    async getGoodsUser(userName, categories = null, page = 1, limits = 1000, uniqueBrands = [], uniqueIds = []) {
        let categoryFilters = '';
        let brandFilter = '';
        let idFilter = '';

        if (categories && categories.length > 0) {
            categoryFilters = categories.map((category, index) => `&filters[categories][$contains]=${category}`).join('');
        }

        if (uniqueBrands && uniqueBrands.length > 0) {
            brandFilter = uniqueBrands.map((brand, index) => `&filters[brandName][$notIn][${index}]=${encodeURIComponent(brand)}`).join('');
        }

        if (uniqueIds && uniqueIds.length > 0) {
            idFilter = uniqueIds.map((id, index) => `&filters[id][$ne][${uniqueBrands.length + index}]=${id}`).join('');
        }

        return fetchData(`${baseURL}/api/offers?populate=owner,Seller,image&filters[owner][limitsBalance][$gt]=0&filters[brand][$eq]=${userName}&filters[$or][0][onlyRef][$eq]=false&filters[$or][1][onlyRef][$null]=true${categoryFilters}&filters[dealsLimit][$gt]=0&filters[inCatalog][$eq]=true&pagination[pageSize]=${limits}&pagination[page]=${page}&sort[0]=dealsDayLimitDisplay:desc${brandFilter}${idFilter}`)
    },


    async getOrders(id) {
        return fetchData(`${baseURL}/api/offers/${id}?populate=image,orders,orders.offer,orders.offer.image, orders.users`)
    },

    async getAdminOrders(filters, step = 1, limit = 20) {
        try {
            let filterString = ""

            if(filters) {
                if(filters.orderId) {
                    filterString += `&filters[id][$eq]=${filters.orderId}`
                }
            }

            return fetchData(`${baseURL}/api/orders/?populate=offer,users&pagination[pageSize]=${limit}&pagination[page]=${step}${filterString}`)
        } catch (err) {
            console.log(err)
        }
    },

    async getGood(id) {
        return fetchData(`${baseURL}/api/offers/${id}?populate=*`)
    },
    async getChannel(id) {
        return fetchData(`${baseURL}/api/channels/${id}?populate=*`)
    },
    async getGoodAdmin(id) {
        return fetchData(`${baseURL}/api/offers/${id}?populate=Seller, image`)
    },
    async getOrder(offerId, userId) {
        return fetchData(`${baseURL}/api/orders/${offerId}?populate=offer,offer.image,users, offer.owner`)
    },
    async setOrder(userId, offerId, refId) {
        return fetchData(`${baseURL}/api/order?uid=${userId}&offer=${offerId}&ref=${refId}`)
    },
    async updateOffer(id, data) {
        return fetchData(`${baseURL}/api/offers/${id}?populate=Seller`, {data}, "PUT")
    },

    async editOrder(id, data) {
        return fetchData(`${baseURL}/api/orders/${id}`, { data: data }, "PUT")
    },

    /*async changeImage(id, headers) {
        return fetchData(`${baseURL}/api/upload/files/${id}`)
        try {
            const option = {
                method: "DELETE",
                headers: headers
            };
            const res = await fetch(`${baseURL}/api/upload/files/${id}`, option).then(res => res.json())
            return res
        } catch (err) {
            console.log(err)
        }
    },*/

    async uploadImage(data) {
        return fetchData(`https://api.wbcashback.ru/api/upload`, data, "POST", process.env.REACT_APP_UPLOAD_IMAGE, {
            contentType: ""
        })
    },

    async updateOrder(step, imageURL, videoURL, orderId, offer = null, queryAddition = "") {
        try {
            let videoFilter = '';
            if (videoURL && (step === 7 || step === 2)) {
                videoFilter = `&video=${videoURL}`
            }
            return fetchData(`${baseURL}/api/updateOrder?step=${step}&image=${imageURL}&order=${orderId}${videoFilter}${offer ? "&offer=" + offer : ""}${queryAddition}`)
        } catch (err) {
            console.log(err)
        }
    },
    async updateOrder2(step, orderId) {
        return fetchData(`${baseURL}/api/updateOrder?step=${step}&order=${orderId}`)
    },
    async cancelOrder(userId, orderId) {
        return fetchData(`${baseURL}/api/cancelOrder?userId=${userId}&orderid=${orderId}`)
    },
    async paymentDone(orderId, check, owner) {
        return fetchData(`${baseURL}/api/payment?orderId=${orderId}${check ? `&paymentCheck=${check}` : ""}&paymentOwner=${owner}`)
    },

    async returnOrder(orderId) {
        return fetchData(`${baseURL}/api/returnOrder?orderId=${orderId}`)
    },

    async sendNotificationToSeller(data){
        return fetchData(`${baseURL}/api/sendNotificationToSeller`, data)
    },
    async createOffer(data) {
        return fetchData(`${baseURL}/api/offers`, {data: data})
    },
    async createPost(data) {
        return fetchData(`${baseURL}/api/posts`, {data: data})
    },
    async sendPostInformation(data) {
        return fetchData(`${baseURL}/api/sendPostInforamtion`, data, "POST")
    },
    async sendPostToBot(data) {
        return fetchData(`${baseURL}/api/sendPostToBot`, data, "POST", process.env.REACT_APP_SEND_POST_TO_BOT)
    },
    async sendPostToOrder(data) {
        return fetchData(`${baseURL}/api/sendPostToOrders`, data, "POST", process.env.REACT_APP_SEND_POST_TO_ORDERS)
    },
    async createBloger(data) {
        return fetchData(`${baseURL}/api/blogers`, { data: data })
    }
}

export default appAPI