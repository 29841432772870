import { useEffect, useState } from "react";
import appAPI, { baseURL } from "../../api/service";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../helpers/loader/loader";
import Back from "../helpers/back";
import toast from "react-hot-toast";

const Orders = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [orders, setOrders] = useState(null);

  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [filteredGoods, setFilteredGoods] = useState(null);

  const [filtersStatus, setFiltersStatus] = useState([]);
  const [filtersStep, setFiltersStep] = useState([]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleStatusFilterClick = (status) => {
    setFiltersStatus((prevFilters) =>
      prevFilters.includes(status)
        ? prevFilters.filter((s) => s !== status)
        : [...prevFilters, status]
    );
  };

  const handleStepFilterClick = (step) => {
    setFiltersStep((prevFilters) =>
      prevFilters.includes(step)
        ? prevFilters.filter((s) => s !== step)
        : [...prevFilters, step]
    );
  };

  useEffect(() => {
    if (!userInfo) return;

    let filteredOrders = orders.attributes.orders.data || [];

    if (search) {
      filteredOrders = filteredOrders.filter((order) =>
        String(order.id).includes(search.toUpperCase())
      );
    }

    if (filtersStatus.length > 0) {
      filteredOrders = filteredOrders.filter((order) =>
        filtersStatus.includes(
          order.attributes.status === null
            ? "Не назначено"
            : order.attributes.status === "Оплачено"
            ? "Оплачено"
            : "Не оплачено"
        )
      );
    }

    if (filtersStep.length > 0) {
      filteredOrders = filteredOrders.filter((order) =>
        filtersStep.includes(
          order.attributes.step === 6
            ? "Заказ оформлен"
            : order.attributes.step === 7
            ? "Товар получен"
            : "Отзыв оставлен"
        )
      );
    }

    setFilteredGoods(
      filteredOrders
        .slice()
        .sort((a, b) => {
          const step6DateA = a.attributes.step6Date ? new Date(a.attributes.step6Date) : null;
          const step6DateB = b.attributes.step6Date ? new Date(b.attributes.step6Date) : null;
        
          if (step6DateA && step6DateB) {
            return step6DateA - step6DateB;
          } else if (step6DateA) {
            return -1; // Если у `a` есть `step6Date`, а у `b` нет
          } else if (step6DateB) {
            return 1; // Если у `b` есть `step6Date`, а у `a` нет
          }   
        })
    );
    

  }, [search, userInfo, filtersStatus, filtersStep, orders]);

  useEffect(() => {
    if (!id) return;
    async function fetch() {
      try {
        const response = await appAPI.getUser(window.Telegram.WebApp.initDataUnsafe.user.id);
        const responseOrders = await appAPI.getOrders(id);
        setOrders(responseOrders.data);
        setUserInfo(response.data[0]);
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [id]);

  if (!userInfo || orders == null) return <Loader />;
  console.log(orders);
  return (
    <>
      <Back />
      <div className="container">
        <div className="header" style={{ marginTop: "16px" }}>
          <h1>Сделки по товару № {orders.id}</h1>
        </div>
        <div className="search_block">
          <label
            className={`input_search ${isFocused && "active_input"}`}
            tabIndex="0"
            style={{ marginRight: 0 }}
          >
            <div className={`search_icon`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Поиск"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {search !== "" && (
              <div
                className="close_icon"
                onClick={(e) => {
                  handleFocus(e);
                  setSearch("");
                }}
              >
                <xml version="1.0" encoding="utf-8" />
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" fill="none" />
                  <path
                    d="M7 17L16.8995 7.10051"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 7.00001L16.8995 16.8995"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </label>
        </div>

        <div className="pretitle__orders">Статус оплаты</div>
        <div className="pretitle__orders_buttons">
          <div
            className={`${
              filtersStatus.includes("Оплачено")
                ? "active__orders_button"
                : "orders_button"
            }`}
            onClick={() => handleStatusFilterClick("Оплачено")}
          >
            Оплачено
          </div>
          <div
            className={`${
              filtersStatus.includes("Не оплачено")
                ? "active__orders_button"
                : "orders_button"
            }`}
            onClick={() => handleStatusFilterClick("Не оплачено")}
          >
            Не оплачено
          </div>
        </div>

        <div className="pretitle__orders">Текущий шаг</div>
        <div className="pretitle__orders_buttons">
          <div
            className={`${
              filtersStep.includes("Заказ оформлен")
                ? "active__orders_button"
                : "orders_button"
            }`}
            onClick={() => handleStepFilterClick("Заказ оформлен")}
          >
            Заказ оформлен
          </div>
          <div
            className={`${
              filtersStep.includes("Товар получен")
                ? "active__orders_button"
                : "orders_button"
            }`}
            onClick={() => handleStepFilterClick("Товар получен")}
          >
            Товар получен
          </div>
          <div
            className={`${
              filtersStep.includes("Отзыв оставлен")
                ? "active__orders_button"
                : "orders_button"
            }`}
            onClick={() => handleStepFilterClick("Отзыв оставлен")}
          >
            Отзыв оставлен
          </div>
        </div>

        {filteredGoods && filteredGoods.length > 0 ? (
          <div className="catalog_v2" style={{ marginTop: "10px" }}>
            {filteredGoods.map((i) => {
              if (i.attributes.step <= 5 || i.attributes?.users?.data[0]?.attributes?.isBan == true) return;
              return (
                <div className="cart_v2" onClick={() => nav(`/result/${i.id}`)}>
                  {/*i.attributes.offer.data.attributes.image.data && (
                    <img
                      src={
                        baseURL +
                        i.attributes.offer.data.attributes.image.data.attributes
                          .url
                      }
                    />
                  )*/}
                  <div className="text">
                    <div className="good_id">НОМЕР СДЕЛКИ: {i.id}</div>
                    <div className="title_v2">
                      {i.attributes.offer.data.attributes.name}
                    </div>
                    <div className="price_v2">
                      Цена для покупателя:{" "}
                      {i.attributes.offer.data.attributes.priceForUser} руб
                    </div>
                    <div className="price_v2">
                      Текущий шаг:{" "}
                      {i.attributes.step === 6
                        ? "Заказ оформлен"
                        : i.attributes.step === 7
                        ? "Товар получен"
                        : "Отзыв оставлен"}
                    </div>
                    <div className="price_v2">
                      Статус оплаты:{" "}
                      {i.attributes.status === null
                        ? "Не оплачено"
                        : i.attributes.status === "Оплачено"
                        ? "Оплачено"
                        : "Не оплачено"}
                    </div>
                  </div>
                  <svg
                    className="arrow_icon"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#000000"
                    stroke-width="0.00024000000000000003"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                        fill="#b8b8b8"
                      ></path>{" "}
                    </g>
                  </svg>
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            Сделки по товару не найдены
          </div>
        )}
      </div>
    </>
  );
};

export default Orders;
