import { FileInput, Section } from "@telegram-apps/telegram-ui";

export function FileInputComponent({
  value,
  onChange,
  header,
  footer,
  placeholder,
  type = "image",
  readOnly = false,
  disabled = false,
  ...actions
}) {
  const handleSetImage = (image) => {
    if (!image) return;
    if (!image.type.startsWith("image/")) {
      alert("Выберите файл изображения!");
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      onChange({ img: fileReader.result, file: image });
    };
    fileReader.readAsDataURL(image);
  };

  const renderInputElement = {
    image: (
      <FileInput
        {...actions}
        accept="image/*"
        className="file__picker"
        label={value}
        onChange={(e) => handleSetImage(e.target.files[0])}
        disabled={disabled}
      />
    ),
  };

  return header || footer ? (
    <Section header={header} footer={footer}>
      {renderInputElement[type]}
    </Section>
  ) : (
    renderInputElement[type]
  );
}
