import { Title } from "@telegram-apps/telegram-ui";
import { useEffect, useState } from "react";

export function Loading() {
    const [points, setPoints] = useState(".");

    useEffect(() => {
        const timer = setInterval(() => {
            setPoints(prev => (prev.length === 3 ? "." : prev + "."));
        }, 400);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="loader__deal__container">
            {/*<img src="/confetti.webp" className="loader__deal"/>*/}
            <Title weight="1">Отправляем запрос{points}</Title>
        </div>
    );
}
