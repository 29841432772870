import { useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import appAPI, { baseURL } from "../../../api/service";
import { Button, Title } from "@telegram-apps/telegram-ui";
import { InputComponent } from "../../../shared/ui/Input";
import { MainButton } from "../../../shared/telegram-elements/MainButton";
import { Loading } from "./../components/Loading";

const Task2 = ({ setTask, orderInfo, DeleteOrderComponent }) => {
  const [accept, setAccept] = useState(false);
  const [article, setArticle] = useState("");
  const [responseLoad, setResponseLoad] = useState(false);

  useEffect(() => {
    if (article === orderInfo.attributes.offer.data.attributes.article) {
      setAccept(true);
    } else {
      setAccept(false);
    }
  }, [article, orderInfo]);

  const handleSubmit = async () => {
    if (responseLoad) return;
    setResponseLoad(true);
    try {
      const response = await appAPI.updateOrder2(3, orderInfo.id);
      if (response.success) {
        toast.success("Успешно отправленно");
        setTask(3);
      } else {
        toast.error(response.error ?? "Неизвестная ошибка");
      }
    } finally {
      setResponseLoad(false);
    }
  }

  return (
    <div className="container">
      {responseLoad && <Loading />}
      <Title weight="1">Найдите наш товар</Title>
      <ul className="conditions_ol" >
          <li>Найдите наш товар</li>
          <li>Используйте ключевое слово</li>
          <li>Фото товара ниже</li>
          <li>Вставьте артикул товара для проверки</li>
          <li>Если артикул правильный вы перейдете на следующий шаг</li>
        </ul>
      <InputComponent
        header="Артикул товара"
        placeholder="Введите артикул"
        after={
          <img
            src="/actions_24.svg"
            onClick={async () => {
              const text = await navigator.clipboard.readText();
              setArticle(text);
            }}
          />
        }
        value={article}
        onChange={setArticle}
      />

      {article === "" ? (
        <div className="hint_block_gray">
          <img src="/exclamation.svg" />
          Найдите артикул товара и вставьте его для проверки
        </div>
      ) : accept === false ? (
        <div className="hint_block_gray">
          <img src="/exclamation.svg" />
          Неправильный артикул. Попробуйте еще раз
        </div>
      ) : (
        <div className="hint_block_gray">
          <img src="/exclamation.svg" />
          Артикул правильный
        </div>
      )}

<MainButton onClick={handleSubmit} disabled={!accept || responseLoad} loading={responseLoad} />

      {orderInfo.attributes.offer.data.attributes.image.data && (
        <img
          src={
            baseURL +
            orderInfo.attributes.offer.data.attributes.image.data.attributes.url
          }
          className="image_task"
        />
      )}

      <iframe
        src="https://vkvideo.ru/video_ext.php?oid=216729851&id=456239031&hd=2&hash=74513cfaa66f72e3"
        className="video__iframe"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
        frameBorder="0"
        allowFullScreen
      ></iframe>

      {DeleteOrderComponent}
    </div>
  );
};

export default Task2;
