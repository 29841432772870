import { Section, Select } from "@telegram-apps/telegram-ui";

export function SelectComponent({
  value,
  onChange,
  header = "Текстовое поле",
  footer,
  options,
  placeholder = "Выберие опцию",
  type = "select",
  disabled = false,
  ...actions
}) {
  const renderInputElement = {
    select: (
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        {...actions}
        disabled={disabled}
      >
        {options && options.map(({value, title}) => <option value={value}>{title}</option>)}
      </Select>
    ),
  };

  return header || footer ? (
    <Section header={header} footer={footer}>
      {renderInputElement[type]}
    </Section>
  ) : (
    renderInputElement[type]
  );
}
