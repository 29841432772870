import React from "react";
import style from "./stories.module.css";
import { useNavigate } from "react-router-dom";

export const Stories = ({ stories }) => {
  const navigate = useNavigate();

  const clickStory = (link) => {
      window.open(link);
  };
  return (
    <div className={style.container}>
      {/*<div
        onClick={() => clickStory("https://telegra.ph/O-servise-04-12")}
        className={style.container_item}
      >
        <div className={style.text}>О сервисе</div>
        <img
          src={
            "/wb.jpg"
          }
          className={style.story}
        />
      </div>
      <div
        onClick={() => clickStory("https://telegra.ph/Kak-rabotaet-VB-KEHSHBEHK-02-06")}
        className={style.container_item}
      >
        <div className={style.text}>Инструкция для покупателей</div>
        <div className={style.story}>
          <div className={style.color}></div>
        </div>
      </div>
      <div
        onClick={() => clickStory("https://t.me/bookshaloba")}
        className={style.container_item}
      >
        <div className={style.text}>Книга жалоб и предложений</div>
        <div className={style.story}>
          <div className={style.color_black}></div>
        </div>
        </div>*/}

        <img className={style.container_item} src ="/about.svg"  onClick={() => clickStory("https://telegra.ph/O-servise-04-12")}/>
        <img className={style.container_item} src ="/info.svg"  onClick={() => clickStory("https://telegra.ph/Kak-rabotaet-VB-KEHSHBEHK-02-06")}/>
        <img className={style.container_item} src ="/book.svg"  onClick={() => clickStory("https://t.me/+u1xgQDgphRNkZWFi")}/>
    </div>
  );
};
