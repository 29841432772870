import { Text } from "@telegram-apps/telegram-ui";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export function useFilters() {
  const nav = useNavigate()
  const [search, setSearch] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [filtersDeals, setFiltersDeals] = useState("archive");
  const [currentStep, setCurrentStep] = useState("feedback");

  const timer = useRef(null)

  useEffect(() => {
    if(timer.current) {
      clearTimeout(timer.current)
    }

    let newTimer = setTimeout(() => {
      setSearch(searchOption)
    }, 1500)

    timer.current = newTimer

  }, [searchOption])

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const cursor = (
    <>
      <div className="search_block">
        <label
          className={`input_search ${isFocused && "active_input"}`}
          tabIndex="0"
          style={{ marginRight: 0 }}
        >
          <div className={`search_icon`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z" />
            </svg>
          </div>
          <input
            type="text"
            placeholder="Поиск"
            value={searchOption}
            onChange={(e) => setSearchOption(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {searchOption !== "" && (
            <div
              className="close_icon"
              onClick={(e) => {
                handleFocus(e);
                setSearch("");
                setSearchOption("")
              }}
            >
              <xml version="1.0" encoding="utf-8" />
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" fill="none" />
                <path
                  d="M7 17L16.8995 7.10051"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 7.00001L16.8995 16.8995"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </label>
      </div>

      <Text weight="2">Тип сделки</Text>
      <div className="pretitle__orders_buttons">
      <div
          className={`${
            filtersDeals.includes("all")
              ? "active__orders_button"
              : "orders_button"
          }`}
          onClick={() => setFiltersDeals("all")}
        >
          Все
        </div>
        <div
          className={`${
            filtersDeals.includes("active")
              ? "active__orders_button"
              : "orders_button"
          }`}
          onClick={() => setFiltersDeals("active")}
        >
          Оплачено
        </div>
        <div
          className={`${
            filtersDeals.includes("archive")
              ? "active__orders_button"
              : "orders_button"
          }`}
          onClick={() => setFiltersDeals("archive")}
        >
          Не оплачено
        </div>
      </div>
      <Text weight="2">Текущий шаг</Text>
      <div className="pretitle__orders_buttons">
      <div
          className={`${
            currentStep.includes("all")
              ? "active__orders_button"
              : "orders_button"
          }`}
          onClick={() => setCurrentStep("all")}
        >
          Все
        </div>
        <div
          className={`${
            currentStep.includes("book")
              ? "active__orders_button"
              : "orders_button"
          }`}
          onClick={() => setCurrentStep("book")}
        >
          Заказ оформлен
        </div>
        <div
          className={`${
            currentStep.includes("receive")
              ? "active__orders_button"
              : "orders_button"
          }`}
          onClick={() => setCurrentStep("receive")}
        >
          Товар получен
        </div>
        <div
          className={`${
            currentStep.includes("feedback")
              ? "active__orders_button"
              : "orders_button"
          }`}
          onClick={() => setCurrentStep("feedback")}
        >
          Отзыв оставлен
        </div>
      </div>
    </>
  );

  return {
    filtersComponent: cursor,
    search,
    filters: filtersDeals,
    currentStep
  };
}
