import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import appAPI, { baseURL } from "../../api/service";
import Loader from "../helpers/loader/loader";
import Back from "../helpers/back";
import { format, isValid } from "date-fns";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {
  Avatar,
  Button,
  Cell,
  Link,
  List,
  Modal,
  Section,
  Text,
} from "@telegram-apps/telegram-ui";
import { ImageComponent } from "../compoents/ImageComponent";
import { isAdmin } from "../../App";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import { splitNumber } from "../../helpers/splitNumbers";
import {
  MainButton,
  SecondaryButton,
} from "../../shared/telegram-elements/MainButton";
import { FooterBlock } from "../deal/components/FooterBlock";

const Result = ({ userInfo }) => {
  const { id } = useParams();
  const nav = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [check, setCheck] = useState(null);

  const [orderInfo, setOrder] = useState(null);

  const [wantBack, setWantBack] = useState(false);
  const [revisionOrderModal, setRevisionOrderModal] = useState(false);

  const handleCancel = async () => {
    try {
      const response = await appAPI.returnOrder(orderInfo.id);

      if (response.success === false) {
        return toast.error(response?.error || "Попробуйте позже");
      }
      setWantBack(false);
      setOrder({
        ...orderInfo,
        attributes: {
          ...orderInfo.attributes,
          status: "Возврат",
        },
      });
      return toast.success("Успешно");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!id) return;
    async function fetch() {
      const response = await appAPI.getOrder(
        id,
        window.Telegram.WebApp.initDataUnsafe.user.id
      );
      if (response.data === null) {
        return setOrder("error");
      }
      setOrder(response.data);
    }
    fetch();
  }, [id]);

  const handlePayment = async () => {
    if (loading) return;
    setLoading(true);

    let filesUrl = null;

    if (check?.file) {
      const formData = new FormData();
      formData.append("files", check?.file);

      const response = await appAPI.uploadImage(formData);

      if (!response[0]) {
        throw new Error("Ошибка при загрузке картинки");
      }

      filesUrl = `${baseURL}${response[0].url}`;
    }

    try {
      const res = await appAPI.paymentDone(
        orderInfo.id,
        filesUrl || null,
        userInfo?.attributes?.username ||
          window.Telegram?.WebApp?.initDataUnsafe?.user?.username
      );
      if (res.success) {
        toast.success("Успешно обновлено");
        setIsOpen(false);
        return setOrder({
          ...orderInfo,
          attributes: {
            ...orderInfo.attributes,
            status: "Оплачено",
          },
        });
      } else {
        toast.error(res.error ?? "Попробуйте позже");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRevisionSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const formData = new FormData(e.target);

      if (!formData.get("comment").trim()) {
        return toast.error("Укажите комментарий");
      }

      const res = await appAPI.sendRevision(
        orderInfo.id,
        userInfo?.attributes?.username ||
          window.Telegram?.WebApp?.initDataUnsafe?.user?.username,
        formData.get("comment").trim()
      );

      if (res.success) {
        toast.success("Успешно отправлено на доработке");
        setRevisionOrderModal(false);
        return setOrder({
          ...orderInfo,
          attributes: {
            ...orderInfo.attributes,
            status: "На доработке",
          },
        });
      } else {
        toast.error(res.error ?? "Попробуйте позже");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  if (orderInfo === null) return <Loader />;
  if (orderInfo === "error")
    return (
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        Данный товар не найден
      </div>
    );

  const renderPaymentDetails = () => {
    if (!orderInfo.attributes.image4) return null;

    const [cardNumber, phoneNumber, bankName, name] =
      orderInfo.attributes.image4.split("::");

    return (
      <>
        <Cell
          className="section cell"
          after={<Text>{cardNumber}</Text>}
          onClick={() =>
            navigator.clipboard
              .writeText(cardNumber)
              .then(() => toast.success("Номер скопирован"))
          }
        >
          Номер карты
        </Cell>
        <Cell
          className="section cell"
          after={<Text>{phoneNumber}</Text>}
          onClick={() =>
            navigator.clipboard
              .writeText(phoneNumber)
              .then(() => toast.success("Номер скопирован"))
          }
        >
          Номер телефона
        </Cell>
        <Cell className="section cell" after={<Text>{bankName}</Text>}>
          Банк
        </Cell>
        <Cell className="section cell" after={<Text>{name}</Text>}>
          Имя покупателя
        </Cell>
      </>
    );
  };
  const username = String(userInfo?.attributes?.username?.toLowerCase().trim());
  const orderBrand = String(
    orderInfo?.attributes?.offer?.data?.attributes?.brand?.toLowerCase().trim()
  );

  const telegramUsername =
    window.Telegram?.WebApp?.initDataUnsafe?.user?.username
      ?.toLowerCase()
      .trim();

  const additionalOffers = userInfo.attributes.work_offers.data;

  const isUserSeller =
    orderInfo?.attributes?.offer?.data?.attributes?.owner?.data?.attributes
      .uid === userInfo?.attributes?.uid ||
    additionalOffers.some(
      (offer) => offer.id == orderInfo?.attributes?.offer?.data?.id
    );

  const isUserBuyer = orderInfo?.attributes?.users?.data.some(
    (user) => user.attributes.uid === userInfo?.attributes?.uid
  );

  const getPaymentDate = () => {
    const cashbackType =
      orderInfo.attributes.offer.data.attributes.cashbackType;
    if (
      cashbackType === "После получения товара" &&
      orderInfo.attributes.step6Date
    ) {
      return format(new Date(orderInfo.attributes.step6Date), "dd.MM.yyyy");
    }
    if (
      cashbackType === "После публикации отызва" &&
      orderInfo.attributes.step7Date
    ) {
      return format(new Date(orderInfo.attributes.step7Date), "dd.MM.yyyy");
    }
    if (
      cashbackType === "После получения товара через 15 дней" &&
      orderInfo.attributes.step6Date
    ) {
      return format(
        new Date(
          new Date(orderInfo.attributes.step6Date).setDate(
            new Date(orderInfo.attributes.step6Date).getDate() + 15
          )
        ),
        "dd.MM.yyyy"
      );
    }
    return null;
  };

  return (
    <>
      <Back />
      <div className="title_page">
        <div style={{ textAlign: "center" }}>Cделка: {orderInfo.id}</div>
      </div>
      <List>
        <Cell
          before={
            <Avatar
              src={
                baseURL +
                orderInfo.attributes.offer.data.attributes.image.data.attributes
                  .url
              }
            />
          }
          className="section"
          description={
            "Цена для вас: " +
            (orderInfo.attributes.priceForUserOffer
              ? orderInfo.attributes.priceForUserOffer
              : orderInfo.attributes.offer.data.attributes.priceForUser) +
            " руб"
          }
        >
          {orderInfo.attributes.offer.data.attributes.name}
        </Cell>

        { isUserBuyer && <div className="conditions_ol" style={{ paddingLeft: "16px" }}>
          <Text weight="1"> Ваш отчет передан продавцу</Text>
          <br />
          <br />
          <Text>В случае задержек оплаты и любые другие вопросы по кэшбэку решайте напрямую с продавцом.</Text>
          <br />
          <br />
          <Text>Если продавец окажется мошенникам будет создана отдельная группа куда будут добавлены все обманутые покупатели и продавец</Text>
          <br />
          <br />
          <Text weight="1">
            Ваш кэшбэк:{" "}
            {orderInfo.attributes.cashbackAmount
              ? orderInfo.attributes.cashbackAmount + " руб"
              : "-"}
          </Text>
          <br />
          <Text weight="1">
            Крайний срок:{" "}
            {isValid(orderInfo.attributes.cashbackDate)
              ? format(
                  new Date(orderInfo.attributes.cashbackDate),
                  "dd.MM.yyyy"
                )
              : getPaymentDate()}
          </Text>
          <br />
          <Text weight="1">Статус: {orderInfo.attributes.status}</Text>
          {/*<br />
          <br />
          <Link
            onClick={() => window.Telegram.WebApp.openLink("https://telegra.ph/Otvety-na-voprosy-faq-06-04")}
            style={{ fontWeight: "bold", fontSize: "17px" }}
          >
            Ответы на частые вопросы
          </Link>*/}
        </div>}

        <SecondaryButton style={{margin:"0"}} text="Проверить продавца" onClick={() => window.Telegram.WebApp?.openTelegramLink(`https://t.me/bigblacklist_bot?start=${orderBrand}`)}/>

        <Section className="section">
          <Cell
            className="section cell"
            after={
              <Link
                href={`https://t.me/${
                  orderInfo.attributes.users.data[0].attributes.username
                    ? orderInfo.attributes.users.data[0].attributes.username
                    : ""
                }`}
              >
                {orderInfo.attributes.users.data[0]?.attributes?.username || ""}
              </Link>
            }
          >
            Покупатель
          </Cell>
          <Cell
            className="section cell"
            after={
              <Link href={`https://t.me/${orderBrand}`}>
                {orderBrand || ""}
              </Link>
            }
          >
            Продавец
          </Cell>
        </Section>

        <SectionHeader className="section__header">сделка</SectionHeader>
        <Section className="section">
          <Cell
            className="section cell"
            after={
              <Text className="hint">
                {orderInfo.attributes.offer.data.attributes.cashbackType ===
                "После получения товара через 15 дней"
                  ? "На 15й день"
                  : orderInfo.attributes.offer.data.attributes?.cashbackType}
              </Text>
            }
          >
            Условия оплаты
          </Cell>
          <Cell
            className="section cell"
            after={
              <Text className="hint">
                {orderInfo.attributes.offer.data.attributes.priceOnSite} руб
              </Text>
            }
          >
            Сумма покупки
          </Cell>
          <Cell
            className="section cell"
            after={
              <Text className="hint">
                {orderInfo.attributes.priceForUserOffer
                  ? orderInfo.attributes.priceForUserOffer
                  : orderInfo.attributes.offer.data.attributes
                      .priceForUser}{" "}
                руб
              </Text>
            }
          >
            Цена покупателю
          </Cell>

          <Cell
            className="section cell"
            after={
              <Text className="hint">
                {orderInfo.attributes.cashbackAmount
                  ? orderInfo.attributes.cashbackAmount + " руб"
                  : "-"}
              </Text>
            }
          >
            Кэшбек
          </Cell>
          <Cell
            className="section cell"
            after={
              <Text className="hint">
                {isValid(orderInfo.attributes.cashbackDate)
                  ? format(
                      new Date(orderInfo.attributes.cashbackDate),
                      "dd.MM.yyyy"
                    )
                  : getPaymentDate()}
              </Text>
            }
          >
            Срок до
          </Cell>
          <Cell
            className="section cell"
            after={<Text className="hint">{orderInfo.attributes.status}</Text>}
          >
            Статус оплаты
          </Cell>
        </Section>

        {orderInfo?.attributes.image4 && (
          <>
            <SectionHeader className="section__header">РЕКВИЗИТЫ</SectionHeader>
            <Section className="section">{renderPaymentDetails()}</Section>
          </>
        )}

        {isUserSeller &&
          (orderInfo.attributes.status !== "Оплачено" && orderInfo.attributes.status !== "Возврат" ? (
            <MainButton
              text={"Провести оплату"}
              onClick={() => setIsOpen(true)}
            />
          ) : orderInfo.attributes.status === "Оплачено" ? (
            <MainButton text={"Оплачено"} />
          ) : (
            <></>
          ))}

        {isUserSeller && orderInfo.attributes.status == "Не оплачено" && (
          <SecondaryButton
            text={"Отправить на доработку"}
            onClick={() => setRevisionOrderModal(true)}
          />
        )}

        {isUserBuyer && (orderInfo.attributes.status == "На доработке" ||  orderInfo.attributes.status == "Не оплачено") && (
          <SecondaryButton
            text={orderInfo.attributes.status == "На доработке" ? "Доработать сделку" : "Редактировать сделку"}
            onClick={() => nav(`/orders/edit/${orderInfo.id}`)}
          />
        )}

        {isAdmin && (
          <SecondaryButton
            text={"Редактировать сделку"}
            onClick={() => nav(`/admin/orders/edit/${orderInfo.id}`)}
          />
        )}

        <FooterBlock
          isUserBuyer={isUserBuyer}
          cancelFunction={() => setWantBack(true)}
        />

        {/*
        {isUserBuyer && orderInfo.attributes.status === "Не оплачено" && (
          <div className="filters_buttons_deal ">
            <div
              className="filters_button_deal delete_order"
              onClick={() => setWantBack(true)}
            >
              Возврат
            </div>
          </div>
        )}


        
        {orderInfo?.attributes.image1 && (
          <div>
            <div className="image_cart_task">Шаг 1. Корзина</div>
            <img
              src={orderInfo.attributes?.image1}
              className="image_task_result"
            />
            <img
              src={orderInfo.attributes?.image3}
              className="image_task_result"
            />
          </div>
        )}
        {orderInfo?.attributes.image1 && (
          <div>
            <div className="image_cart_task">Шаг 2. Найдите наш товар</div>
          </div>
        )}
        {orderInfo?.attributes.image3 && (
          <div>
            <div className="image_cart_task">
              Шаг 3. Добавить товар в избранное
            </div>
            <img
              src={orderInfo.attributes.image3}
              className="image_task_result"
            />
          </div>
        )}
        {orderInfo?.attributes.image5 && (
          <div>
            <div className="image_cart_task">Шаг 5. Заказ оформлен</div>
            <img
              src={orderInfo.attributes.image5}
              className="image_task_result"
            />
          </div>
        )}
        {orderInfo?.attributes.image6 && orderInfo?.attributes.video6 && (
          <div>
            <div className="image_cart_task">Шаг 6. Товар получен</div>
            <img
              src={orderInfo.attributes.image6}
              className="image_task_result"
            />
          </div>
        )}

        {orderInfo?.attributes.video6 && (
          <div>
            <div className="image_cart_task">Шаг 6. Штрих коды</div>
            <img
              src={orderInfo.attributes.video6}
              className="image_task_result"
            />
          </div>
        )}

        {orderInfo?.attributes.image7 && (
          <div>
            <div className="image_cart_task">Шаг 7. Отзыв</div>
            <img
              src={orderInfo.attributes.image7}
              className="image_task_result"
            />

            {orderInfo?.attributes?.offer?.data?.attributes?.needCheck ===
              true && (
              <>
                <div className="image_cart_task">
                  Номер чека {orderInfo.attributes.customerCheckNumber}
                </div>
                <img
                  src={orderInfo.attributes.customerCheck}
                  className="image_task_result"
                />
              </>
            )}
          </div>
        )}

        */}
      </List>

      <Modal
        style={{ zIndex: "1000000000" }}
        header={<ModalHeader>Подтвердите оплату</ModalHeader>}
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) setIsOpen(false);
        }}
      >
        <List style={{ paddingBottom: "30px" }}>
          <ImageComponent
            title={"Загрузите скрин чека"}
            image={check}
            setImage={setCheck}
          />
          <Button stretched onClick={handlePayment} loading={loading}>
            Оплачено
          </Button>
        </List>
      </Modal>

      <Modal
        style={{ zIndex: "1000000000" }}
        header={<ModalHeader>Подтвердите возврат</ModalHeader>}
        open={wantBack}
        onOpenChange={(open) => {
          if (!open) setWantBack(false);
        }}
      >
        <>
          <List style={{ paddingBottom: "30px" }}>
            <div>
              <Text>
                Данное действие приведет к блокировке данной сделки. После
                данного действия управлять сделкой будет невозможно. Вы уверены,
                что хотите продолжить?
              </Text>
            </div>

            <Button onClick={handleCancel} stretched>
              Подтверждаю
            </Button>
          </List>
        </>
      </Modal>

      <Modal
        style={{ zIndex: "1000000000" }}
        header={<ModalHeader>Подтвердите отправку на доработку</ModalHeader>}
        open={revisionOrderModal}
        onOpenChange={(open) => {
          if (!open) setRevisionOrderModal(false);
        }}
      >
        <>
          <form
            className="input_question_block"
            onSubmit={handleRevisionSubmit}
          >
            <List style={{ paddingBottom: "30px" }}>
              <div>
                <label className="input_question">
                  <div>Комментарий к доработке (покажется покупателю)</div>
                  <input
                    type="text"
                    name="comment"
                    placeholder="Введите текст"
                  />
                </label>
              </div>
              <Button stretched type={"submit"} loading={loading}>
                Подтверждаю
              </Button>
            </List>
          </form>
        </>
      </Modal>
    </>
  );
};
export default Result;
