import { List, Modal, Text, Cell } from "@telegram-apps/telegram-ui";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { useState } from "react";
import { useParams } from "react-router-dom";
import appAPI from "../../../api/service";
import { Icon20ChevronRight } from "@vkontakte/icons";

export function useResult(offer) {
  const { id } = useParams();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  async function getResultData() {
    if (loading) return;
    setLoading(true);
    try {
      const response = await appAPI.getOrder(id, window.Telegram.WebApp.initDataUnsafe.user.id);
      if (response.data === null) {
        return setResult("error");
      }
      setResult(response.data);
    } finally {
      setLoading(false);
    }
  }

  let button = (
    <Cell className="cell" after={<Icon20ChevronRight />} onClick={() => getResultData()}>
      Открыть отчет
    </Cell>
  );

  let modal = (
    <Modal
      style={{ zIndex: "1000000000" }}
      header={<ModalHeader after={<div onClick={() => setResult(null)}>Закрыть</div>}>Отчет</ModalHeader>}
      open={result !== null}
      onOpenChange={(open) => {
        if (!open) setResult(null);
      }}
    >
      <List style={{ paddingBottom: "30px" }}>
        {result == "error" ? (
          <>
            <Text>Ошибка при получении отчета</Text>
          </>
        ) : (
          <>
            {result?.attributes.image1 && result?.attributes.image1 !== "" && (
              <div>
                <div className="image_cart_task">Шаг 1. Корзина</div>
                <img
                  src={result.attributes?.image1}
                  className="image_task_result"
                />
                <img
                  src={result.attributes?.image3}
                  className="image_task_result"
                />
              </div>
            )}
            {result?.attributes.image1 && result?.attributes.image1 !== ""  && (
              <div>
                <div className="image_cart_task">Шаг 2. Найдите наш товар</div>
              </div>
            )}
            {result?.attributes.image3 && result?.attributes.image3 !== ""  && (
              <div>
                <div className="image_cart_task">
                  Шаг 3. Добавить товар в избранное
                </div>
              </div>
            )}
            {result?.attributes.image5 && result?.attributes.image5 !== ""  && (
              <div>
                <div className="image_cart_task">Шаг 5. Заказ оформлен</div>
                <img
                  src={result.attributes.image5}
                  className="image_task_result"
                />
              </div>
            )}
            {result?.attributes.image6 && result?.attributes.image6 !== "" && result?.attributes.video6 && (
              <div>
                <div className="image_cart_task">Шаг 6. Товар получен</div>
                <img
                  src={result.attributes.image6}
                  className="image_task_result"
                />
              </div>
            )}

            {result?.attributes.video6 && result?.attributes.video6 !== "" && (
              <div>
                <div className="image_cart_task">Шаг 6. Штрих коды</div>
                <img
                  src={result.attributes.video6}
                  className="image_task_result"
                />
              </div>
            )}

            {result?.attributes.image7 && result?.attributes.image7 !== "" && (
              <div>
                <div className="image_cart_task">Шаг 7. Отзыв</div>
                <img
                  src={result.attributes.image7}
                  className="image_task_result"
                />

                {result?.attributes?.offer?.data?.attributes?.needCheck ===
                  true && (
                  <>
                    <div className="image_cart_task">
                      Номер чека {result.attributes.customerCheckNumber}
                    </div>
                    <img
                      src={result.attributes.customerCheck}
                      className="image_task_result"
                    />
                  </>
                )}
              </div>
            )}
          </>
        )}
      </List>
    </Modal>
  );

  return {
    button,
    modal,
  };
}
