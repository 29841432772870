

export function ImageComponent({image, setImage, title}) {
    const handleSetImage = (image) => {
        if (!image) return;
        const fileReader = new FileReader(image);
        fileReader.onload = () => {
          setImage({ img: fileReader.result, file: image });
        };
        fileReader.readAsDataURL(image);
    };

    return (
        <>
            <div
              className="image_question_block"
              style={{ marginBottom: "16px" }}
            >
              <label className="input_question">
                <div className="deal_load">{title}</div>
                <label>
                  <div>
                    <div className="image_picker">
                      <div className="image_picker_info">
                        {image && (
                          <img className="image_select" src={image.img ? image.img : image} />
                        )}
                        <div className="text_file_image">
                          {image ? image?.file ? image?.file?.name : "Изоображение" : "Выберите изображение..."}
                        </div>
                      </div>
                      <div>
                        {image?.img && (
                          <svg
                            onClick={(e) => {
                              e.preventDefault();
                              setImage(null);
                            }}
                            className="image_picker_close"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="24" height="24" fill="none" />
                            <path
                              d="M7 17L16.8995 7.10051"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 7.00001L16.8995 16.8995"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleSetImage(e.target.files[0])}
                    placeholder="Выберите изображение..."
                    style={{ display: "none" }}
                  />
                </label>
              </label>
            </div>
        </>
    )
}