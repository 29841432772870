import { useState } from "react";
import toast from "react-hot-toast";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Back from "../helpers/back";
import appAPI, { baseURL } from "../../api/service";
import { Button, List, Section, Select, Text, Textarea } from "@telegram-apps/telegram-ui";

const SendPostToOrders = ({ userInfo }) => {
  const nav = useNavigate();

  const [values, setValues] = useState({
    content: ""
  });


  const [withPost, setWithPost] = useState(false)
  const [loading, setLoading] = useState(false)


  const handleSubmit = async () => {
    if(!values.content) return 
    if(loading === true) return       
      setLoading(true)
      try {
        let data = {
          ids: values.content.split(";"),
          withPost: withPost
        }
        const res = await appAPI.sendPostToOrder(data);
        if (!res || res?.error) {
          return toast.error(res?.error);
        }
        toast.dismiss()
        setValues({
          content: "",
          link: "",
          buttonText: ""
        })
        return toast.success("Успешно отправлен");
      } catch (error) {
        console.error(error);
        toast.error("Ошибка при создании");
      } finally {
        setLoading(false)
      }
  };

  if(String(window.Telegram.WebApp.initDataUnsafe.user.id) !== "336286853") return <Navigate to={"/"}/>
  return (
    <Section>
      <Back />
      <div
        className="title_page"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        <div
          onClick={() => nav("/")}
          style={{
            width: "fit-content",
            fontSize: "13px",
            cursor: "pointer",
            fontSize: "0.75rem",
            textTransform: "uppercase",
          }}
        >
          Отменить
        </div>
        Обновить сделки
        <div
          onClick={handleSubmit}
          style={{
            width: "fit-content",
            textAlign: "end",
            cursor: "pointer",
            fontSize: "0.75rem",
            color: "#731358",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          Отправить
        </div>
      </div>

      <List>
        <Select onChange={(e) => setWithPost(e.target.value)} value={withPost}>
            <option value={true}>С уведомлением</option>
            <option value={false}>Без уведомления</option>
        </Select>

        <div>
              <div style={{paddingBottom:"5px"}}>
                  <Text>Айди</Text>
              </div>
              
              <Textarea
                  placeholder={`Введите айди`}
                  value={values.content}
                  onChange={(e) => setValues({
                      ...values,
                      content: e.target.value
                  })}
                  type="text"
              />
          </div>
          {!loading && <Button onClick={() => handleSubmit()} stretched>
          Обновить сделки
          </Button>}
      </List>
    </Section>
  );
};

export default SendPostToOrders;
