import toast from "react-hot-toast"
import Back from "../helpers/back"



const Earn = ({userInfo}) => {
    return (
        <div className="earn__page">
            <Back />
            <div className="earn__page_t">Пригласи продавца и заработай</div>
            <div className="earn__page_d">Получи за каждое приглашение по твоей
реферальной ссылке <span>20%</span>. Также <span>20%</span> скидки
получит продавец</div>

<div className="earn__page_d" style={{marginTop:"10px"}}>Минимальная выплата 1000р.</div>

        <div className="hint_block">
        Деньги начисляются после того, как продавец
        совершит покупку
        </div>

            <div className='invite__links'>
                <div className='invite__block' onClick={() => {
                    window.open(`https://t.me/share/url?url=${`https://t.me/wildberries_cashback_bot?start=${userInfo.id}_create`}&text=`)
                }}>Пригласить продавца</div>
                <div className="invite__link" onClick={() => {
                    navigator.clipboard.writeText(`https://t.me/wildberries_cashback_bot?start=${userInfo.id}_create`)
                    return toast.success("Скопировано")
                }}><img src="/link1.svg"/></div>

            </div>  
        </div>
    )
}

export default Earn