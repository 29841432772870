import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Conditions from "../conditions/conditions";
import appAPI, { baseURL } from "../../api/service";
import Loader from "../helpers/loader/loader";
import Back from "../helpers/back";
import toast from "react-hot-toast";
import AlreadyPurchased from "../compoents/AlreadyPurchased";
import { SecondaryButton } from "../../shared/telegram-elements/MainButton";

const Good = ({ userInfo, selectedGood = null , setSelectedGood, catalogScroll = false }) => {
  let id; 
  const nav = useNavigate();
  const { id: paramId } = useParams();


  useEffect(() => {
    if(selectedGood !== null) {
      id = selectedGood
    } else {
      id = paramId
    }
  }, [selectedGood, paramId])


  const [goodInfo, setGoodInfo] = useState(null);
  const [statusActive, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      const response = await appAPI.getGood(id);
      if (response.data === null) {
        return setGoodInfo("error");
      }
      setGoodInfo(response.data);
    }
    fetch();
  }, [id]);

  const location = useLocation()

  function getQueryParams(data) {
    return new URLSearchParams(location.search).get(data) ? new URLSearchParams(location.search).get(data) : ""
  }

  let ref_data = getQueryParams("ref")


  function alreadyHaveOrder() {
    nav("/cart")
    return toast.success("Перейдите на страницу с покупками")
  }

  useEffect(() => {
    if(!ref_data || !goodInfo) return

    const uniqueIds = userInfo.attributes.orders.data.reduce((acc, order) => {
      const id1 = order.attributes.offer.data.id;
      console.log(id1)
      if (!acc.includes(id1)) {
          acc.push(Number(id1));
      }
      return acc;
    }, []);

    if(uniqueIds.includes(Number(id)) || uniqueIds.includes(Number(paramId))) {
      alreadyHaveOrder()
    } else {
      setActive(true)
    }
  }, [ref_data, goodInfo])

 

  

  if (goodInfo === null || loading === true) return <Loader />;
  if (goodInfo === "error")
    return (
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        Данный товар не найден
      </div>
    );

  const uniqueBrands = userInfo.attributes.orders.data.reduce((acc, order) => {
      const brand = order.attributes.offer.data.attributes.brandName;
      if (brand && !acc.includes(brand) && order.attributes.step5Date) {
          acc.push(brand);
      }
      return acc;
  }, []);
  const goodSellerUsername = goodInfo.attributes.brandName;
  const hasUserOrderedFromSeller = uniqueBrands.some((brand) => brand === goodSellerUsername);

  const unsuccessOrders = userInfo.attributes.orders.data.filter(
    (order) => {
      return (
        order.attributes.status !== "Оплачено" && Number(order.attributes.step) >= 6 &&
        order.attributes.offer.data.attributes.brandName === goodSellerUsername
      )
    }      
  );

  if (unsuccessOrders.length > 0 && hasUserOrderedFromSeller) {
    return (
      <div style={{ width: "100%", height: "100vh", padding: "20px" }}>
        <Back />
        <div style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
        У вас есть активная сделка у этого продавца. Как только продавец выплатит кэшбэк вы сможете купить другие его товары
        </div>
        <div onClick={() => nav("/catalog")} style={{height:"46px", width:"100%", display:"flex", justifyContent:"center", alignItems: "center", marginTop:"15px", color:"white", background:"#731358", borderRadius:"8px"}}>
            Вернуться в каталог
          </div>
      </div>
    );
  }

  if((parseInt(goodInfo.attributes.dealsLimit) <= 0 || parseInt(goodInfo.attributes.dealsDayLimitDisplay) <= 0 || goodInfo.attributes.owner.data.attributes.limitsBalance == null || Number(goodInfo.attributes.owner.data.attributes.limitsBalance) <= 0)) return <AlreadyPurchased username={goodInfo?.attributes?.brand} id={goodInfo.id} />
  return (
    <>
      {catalogScroll && <Back func={() => setSelectedGood(null)}/>}
      {statusActive ? (
        <Conditions goodInfo={goodInfo}/>
      ) : (
        <>
          {goodInfo.attributes.image?.data && (
            <img
              className="good_img"
              src={`${baseURL}${goodInfo.attributes.image.data.attributes.url}`}
              alt={goodInfo.attributes.name}
            />
          )}
          <div className="container">

            <SecondaryButton text="Проверить продавца" onClick={() => window.Telegram.WebApp?.openTelegramLink(`https://t.me/bigblacklist_bot?start=${goodInfo.attributes.brand}`)}/>
              
            {goodInfo.attributes.withInstruction === false ? <div className="button_ship" style={{padding:"12px 23px"}} onClick={() => 
              window.open(`https://t.me/${goodInfo.attributes.brand}`)}
            >
              Написать продавцу
            </div> : <div className="button_ship" style={{padding:"12px 23px"}}  onClick={() => setActive(true)}>
              Открыть инструкцию
            </div>}

            <div className="good_title">{goodInfo.attributes.name}</div>
            <b style={{ marginTop: "10px" }}>Условия сделки</b>
            <br />
            <div style={{ marginTop: "2px" }}>
              {goodInfo.attributes.description &&
                goodInfo.attributes.description}
            </div>
            <div className="inforamtion_ship">
            <div className="inforamtion">
                <div className="inforamtion_title">Продавец</div>
                <div className="inforamtion_value">
                  @{goodInfo.attributes.brand}
                  
                </div>
              </div>
              <div className="inforamtion">
                <div className="inforamtion_title">Доступно в день</div>
                <div className="inforamtion_value">
                  {goodInfo.attributes.dealsDayLimitDisplay
                    ? goodInfo.attributes.dealsDayLimitDisplay
                    : 0}{" "}
                  шт
                </div>
              </div>
              <div className="inforamtion">
                <div className="inforamtion_title">Цена на сайте WB</div>
                <div className="inforamtion_value">
                  {goodInfo.attributes.priceOnSite} руб
                </div>
              </div>
              <div className="inforamtion">
                <div className="inforamtion_title">Цена для вас</div>
                <div className="inforamtion_value">
                  {goodInfo.attributes.priceForUser} руб
                </div>
              </div>
              <div className="inforamtion">
                <div className="inforamtion_title">Скидка</div>
                <div className="inforamtion_value">
                  {parseInt(goodInfo.attributes.priceForUser) !== 0
                    ? (
                        100 -
                        (parseInt(goodInfo.attributes.priceForUser) /
                          parseInt(goodInfo.attributes.priceOnSite)) *
                          100
                      ).toFixed(2)
                    : 100}
                  %
                </div>
              </div>
            </div>

            <div className='invite__links'>
              <div className='invite__block' onClick={() => {
                  window.open(`https://t.me/share/url?url=${`https://t.me/wildberries_cashback_bot?start=${userInfo.attributes.uid}_${goodInfo.id}`}&text=`)
              }}>Отправить другу</div>
              <div className="invite__link" onClick={() => {
                  navigator.clipboard.writeText(`https://t.me/wildberries_cashback_bot?start=${userInfo.attributes.uid}_${goodInfo.id}`)
                  return toast.success("Скопировано")
              }}><img src="/link1.svg"/></div>
            </div>
            
           
          </div>
        </>
      )}
    </>
  );
};

export default Good;
