import React, { useEffect, useState } from "react";
import appAPI, { baseURL } from "../../api/service";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../helpers/loader/loader";
import Back from "../helpers/back";
import toast from "react-hot-toast";
import { Button, Cell, List, Modal, Section } from "@telegram-apps/telegram-ui";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { renderElement } from "../../helpers/renderElementRichText";
import OrdersChart from "../../helpers/ChartOrders";
import { isAdmin } from "../../App";
import { set } from "date-fns";

const AdminOffer = ({ userInfo }) => {
  const { id } = useParams();
  const nav = useNavigate();
  const [goodInfo, setGoodInfo] = useState(null);

  const [loading, setLoading] = useState(false);

  const [channels, setChannels] = useState([]);

  const [selectedChannel, setSelectedChannel] = useState(null);

  useEffect(() => {
    async function fetch() {
      const response = await appAPI.getGood(id);
      if (response.data === null) {
        return setGoodInfo("error");
      }
      setGoodInfo(response.data);

      const response1 = await appAPI.getChannels();
      if (response.data === null) {
        return setGoodInfo("error");
      }
      setChannels(response1.data);
    }
    fetch();
  }, [id]);

  async function changeInCatalog() {
    if(loading) return
    setLoading(true)
    try {
      const { image, ...attributesWithoutImage } = goodInfo.attributes;
      const res = await appAPI.updateOffer(id, {
        ...attributesWithoutImage,
        inCatalog: !goodInfo.attributes.inCatalog ? true : false,
      });

      if (!goodInfo.attributes.inCatalog)
        await appAPI.sendNotificationToSeller({
          offerId: id,
          chat_id: goodInfo.attributes.Seller.telegramId,
        });

      if (!res || res.data === null) {
        return toast.error("Ошибка при обновлении");
      }
      
      setGoodInfo({
        ...goodInfo,
        attributes: {
          ...goodInfo.attributes,
          inCatalog: !goodInfo.attributes.inCatalog ? true : false,
        },
      });

      return toast.success("Успешно обновлено");
    } catch (error) {
      console.error(error);
      toast.error("Ошибка при обновлении");
    } finally {
      setLoading(false)
    }
  }

  const handleGetPostInformation = async () => {
    try {
      const res = await appAPI.sendPostInformation({ id });
      console.log(res);
      if (res.error) {
        return toast.error("Ошибка при отправлении");
      }
      toast.success("Успешно отправлено");
    } catch (e) {
      console.log(e);
      return toast.error("Ошибка при получении поста");
    }
  };

  if (goodInfo === null) return <Loader />;

  const username = String(userInfo?.attributes?.username?.toLowerCase().trim());
  const orderBrand = String(goodInfo?.attributes?.brand?.toLowerCase().trim());
  const telegramUsername =
    window.Telegram?.WebApp?.initDataUnsafe?.user?.username
      ?.toLowerCase()
      .trim();
  const additionalOffers = userInfo.attributes.work_offers.data;

  const isUserSeller =
    goodInfo?.attributes?.creatorID?.toLowerCase().trim() ===
      userInfo?.attributes.uid ||
    (username && username === orderBrand) ||
    (telegramUsername && telegramUsername === orderBrand) ||
    additionalOffers.some((offer) => offer.id == goodInfo?.id);
  if (!isUserSeller && !isAdmin)
    return (
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        Данный товар не найден
      </div>
    );

  return (
    <div className={`main_container `}>
      <Back />
      <div className={`container`}>
        {isAdmin && (
          <div
            className="buttonTo comm"
            onClick={() =>
              window.open(`https://t.me/${goodInfo.attributes.brand}`)
            }
          >
            <img src="/tg.svg" className="icon_tg" />
            Написать продавцу
          </div>
        )}
        <div
          className="buttonTo comm"
          style={{
            margin: "5px 0",
            background: "transparent",
            border: "1px solid lightgray",
            fontWeight: "bold",
            color: "black",
          }}
          onClick={() => nav(`/orders/${goodInfo.id}`)}
        >
          Сделки с покупателями
        </div>

        <div className="header">{/*<h1>Актуальные товары</h1>*/}</div>
        <div className="offer__block__info">
          <img
            src={`${baseURL}${goodInfo.attributes.image.data.attributes.url}`}
            className="offer__block__img"
          />
          <div>
            <div className="offer__block__article">
              {goodInfo.attributes.article}
            </div>
            <div className="offer__block__name">{goodInfo.attributes.name}</div>
          </div>
        </div>

        {Number(userInfo.attributes.limitsBalance) > 0 &&
        userInfo.attributes.limitsBalance !== null ? (
          <>
            {
              <div className="button_ship" onClick={() => changeInCatalog()}>
                {goodInfo.attributes.inCatalog === true
                  ? "Приостановать"
                  : "Опубликовать"}
              </div>
            }
          </>
        ) : (
          <>
            {
              <div className="button_ship" onClick={() => nav("/balance")}>
                Пополните кабинет
              </div>
            }
          </>
        )}

        {/*String(window.Telegram.WebApp.initDataUnsafe.user.id) === "336286853" &&  <div className="button_ship" onClick={() => handleGetPostInformation()}>
        Опубликовать пост
        </div>*/}

        <div
          className="buttonTo comm"
          style={{
            margin: "10px 0",
            background: "transparent",
            border: "1px solid lightgray",
            fontWeight: "bold",
            color: "black",
          }}
          onClick={async () => {
            nav("/admin/offers/edit/" + goodInfo.id);
          }}
        >
          Редактировать
        </div>

        {isAdmin && (
          <>
            {/*isAdmin && <><input readOnly className="ref__link" value={`https://t.me/wildberries_cashback_bot?start=${goodInfo.attributes.owner.data.attributes.uid}_${goodInfo.id}`} onClick={() => {
            }}/>
            <div className="button_ship" onClick={() => {
              navigator.clipboard.writeText(`https://t.me/wildberries_cashback_bot?start=${goodInfo.attributes.owner.data.attributes.uid}_${goodInfo.id}`)
              return toast.success("Скопировано")
            }}>
              Скопировать ссылку на товар
            </div></>*/}
          </>
        )}

        <div className="inforamtion_ship" style={{ marginTop: "20px" }}>
          <div className="inforamtion">
            <div className="inforamtion_title">Цена на сайте</div>
            <div className="inforamtion_value">
              {goodInfo.attributes.priceOnSite} руб
            </div>
          </div>
          <div className="inforamtion">
            <div className="inforamtion_title">Цена для покупателя</div>
            <div className="inforamtion_value">
              {goodInfo.attributes.priceForUser} руб
            </div>
          </div>
          <div className="inforamtion">
            <div className="inforamtion_title">Кол-во выкупов</div>
            <div className="inforamtion_value">
              {goodInfo.attributes.dealsLimit
                ? goodInfo.attributes.dealsLimit
                : 0}{" "}
              шт
            </div>
          </div>

          <div className="inforamtion">
            <div className="inforamtion_title">Выкупы на сутки</div>
            <div className="inforamtion_value">
              {goodInfo.attributes.dealsDayLimit
                ? goodInfo.attributes.dealsDayLimit
                : 0}
              /
              {goodInfo.attributes.dealsDayLimitDisplay
                ? goodInfo.attributes.dealsDayLimit -
                  goodInfo.attributes.dealsDayLimitDisplay
                : 0}{" "}
              шт
            </div>
          </div>
        </div>

        {goodInfo.attributes.orders.data &&
          goodInfo.attributes.orders.data.length > 0 && (
            <>
              <p
                style={{
                  margin: "15px 0",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Кол-во выкупов
              </p>
              <OrdersChart orders={goodInfo.attributes.orders.data} />
            </>
          )}

        <div className="button_ship" onClick={() => nav("/admin/channels")}>
          Купить рекламу
        </div>
        {/*channels.length > 0 && <Section header="Channels for post">
              {channels && channels.map((channel => {
                return (
                  <Cell description={"Оформлено сделок: "+channel?.attributes?.referrals_orders.data?.filter((t) => t.attributes.step6Date !== null).length} 
                  onClick={() => 
                    setSelectedChannel(channel)
                  }>
                    {channel?.attributes?.username}
                  </Cell>
                )
              }))}
            </Section>*/}
      </div>

      <Modal
        style={{ zIndex: "1000000000" }}
        header={<ModalHeader>Информация о канале</ModalHeader>}
        open={selectedChannel}
        onOpenChange={(open) => {
          if (!open) setSelectedChannel(null);
        }}
      >
        <>
          <List style={{ paddingBottom: "30px" }}>
            {selectedChannel?.attributes?.channel_information && (
              <div>
                {selectedChannel?.attributes.channel_information?.map(
                  (element, index) => (
                    <React.Fragment key={index}>
                      {renderElement(element)}
                    </React.Fragment>
                  )
                )}
              </div>
            )}
            {selectedChannel?.attributes?.referrals_orders?.data &&
              selectedChannel?.attributes?.referrals_orders.data.length > 0 && (
                <>
                  <p
                    style={{
                      margin: "15px 0",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    Кол-во выкупов
                  </p>
                  <OrdersChart
                    orders={selectedChannel?.attributes.referrals_orders.data}
                  />
                </>
              )}
            <Button
              loading={loading}
              stretched
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                try {
                  const res = await appAPI.sendPostInformation({
                    id: id,
                    ref: selectedChannel?.attributes,
                    sendTo: window.Telegram.WebApp.initDataUnsafe.user.id,
                  });
                  if (res?.error) return toast.error(res?.error);
                  return toast.success("Пост отправлен в чат");
                } finally {
                  setLoading(false);
                }
              }}
            >
              Получить пост
            </Button>
          </List>
        </>
      </Modal>
    </div>
  );
};

export default AdminOffer;
