import React from 'react'
import style from './page.module.css'
import { useNavigate } from 'react-router-dom'
import { Stories } from '../../helpers/stories/stories'
import { Blocks } from '../../helpers/blocks/blocks'
import toast from 'react-hot-toast'

const Page = ({userInfo}) => {
    const nav = useNavigate()
    return (
            <div className={style.container}>
                <>
                    <div>
                        <div className={style.stories_block}>
                        <Stories /> 
                        </div>
                        <Blocks />
                    </div> 
                    
                    <div className={style.my_orders} onClick={() => nav("/earn")}>
                        Реферальная программа
                    </div>
                    
                    <div className={style.button_go} onClick={() => window.open("https://t.me/+ksTMnLBKzFE3M2Nk")}>
                        <div className={style.f_block}>
                            <img src="/tg.svg"  className={style.f_block_icon}/>
                            <div className={style.f_block_text}>
                                <div className={style.f_block_title}>@wbcashback_go</div>
                                <div className={style.f_block_value}>Подпишись на канал</div>
                            </div>
                        </div>
                        <svg style={{width:"24px",height:"24px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#0F0F0F"></path> </g></svg>
                    </div>
                    <div className={style.button_go} onClick={() => window.open("https://t.me/HelpWBcashback_bot")}>
                        <div className={style.f_block}>
                            <img src="/teh.svg"  className={style.f_block_icon}/>
                            <div className={style.f_block_text}>
                                <div className={style.f_block_title}>Техподдержка</div>
                                <div className={style.f_block_value}>Оперативно ответим на все вопросы</div>
                            </div>
                        </div>
                        <svg style={{width:"24px",height:"24px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#0F0F0F"></path> </g></svg>
                    </div>
                </>
            </div>
    )
}

export default Page