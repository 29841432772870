import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import appAPI from "../../api/service";
import Back from "../helpers/back";
import { Link } from "@telegram-apps/telegram-ui";

const Conditions = ({ goodInfo }) => {
  const [accept, setAccept] = useState(false);
  const [accept1, setAccept1] = useState(false);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  


  const location = useLocation()

  function getQueryParams(data) {
    return new URLSearchParams(location.search).get(data) ? new URLSearchParams(location.search).get(data) : ""
  }
  
  return (
    <>
      <Back />
      <div className="title_page">
        <div style={{ textAlign: "center" }}>Правила и условия</div>
      </div>
      <div className="container">
        {/*goodInfo.attributes?.brand && (
          <div
            className="buttonTo comm"
            onClick={() =>
              window.open(`https://t.me/${goodInfo.attributes?.brand}`)
            }
          >
            <img src="/tg.svg" className="icon_tg" />
            Написать продавцу
          </div>
        )}

        {<div className="hint_block_warn">Внимательно прочитайте условия</div>}
        {goodInfo.attributes?.description && (
          <div className="description_good">
            {goodInfo.attributes?.description}
          </div>
        )*/}
        <b>
          Перед тем как начнем, пожалуйста, внимательно прочитайте все условия:
        </b>
        <div className="conditions_title" style={{ marginTop: "20px" }}>
          Правила
        </div>
        <div className="conditions_ol" style={{ marginBottom: "20px", paddingLeft:"16px" }}>
          Вы заключаете сделку с продавцом {goodInfo?.attributes?.brand ? <Link onClick={() => window.Telegram.WebApp?.openTelegramLink(`https://t.me/${goodInfo.attributes.brand}`)}>@{goodInfo?.attributes?.brand}</Link> : ""}. Бот не несет никакой ответсвенности за выплату. 
          <br /><br />
          При задержках оплаты и любые другие вопросы по кэшбэку решайте напрямую с продавцом
          <br /><br />
          Если продавец окажется мошенникам будет создана отдельная группа куда будут добавлены все обманутые покупатели и продавец. 
          <br /><br />
          Бот - это пошаговая инструкция. Мы не несем ответвенность за не выплату
          <br /><br />
          Продолжая, вы заключаете сделку и соглашаетесь с этими условиями
         {/* <li>Инструкция состоит из 7 шагов</li>
          <li>Оформление заказа только на 5м шаге</li>
          <li>Купить можно только 1 шт</li>
          <li>Нельзя возвращать товар обратно</li>
          <li>За нарушение кэшбэк выплачен не будет</li>*/}
        </div>
        <div className="conditions_title">Условия сделки</div>
        <ol className="conditions_ol" style={{ marginBottom: "50px" }}>
          <li>Оплата за исключенный отзыв: Да</li>
          <li>Цена на сайте: {goodInfo.attributes.priceOnSite} р</li>
          <li>Цена для вас: {goodInfo.attributes.priceForUser} р</li>
          {goodInfo.attributes?.cashbackType && (
            <li>Кэшбэк: {goodInfo.attributes?.cashbackType}</li>
          )}
          {goodInfo.attributes?.description && (
            <li>{goodInfo.attributes?.description}</li>
          )}
        </ol>
        {/*<div className="inforamtion" style={{ border: "none" }}>
          <div className="inforamtion_title">Цена в магазине</div>
          <div className="inforamtion_value">
            {goodInfo.attributes.priceOnSite} руб
          </div>
        </div>*/}
        <label className="conditions_accept">
          <input
            type="checkbox"
            onChange={() => setAccept(!accept)}
            checked={accept}
            className="custom-checkbox"
          />
          <span></span>
          <div>Я соглашаюсь с условиями сделки</div>
        </label>
        <label className="conditions_accept">
          <input
            type="checkbox"
            onChange={() => setAccept1(!accept1)}
            checked={accept1}
            className="custom-checkbox"
          />
          <span></span>
          <div>Даю согласие на обработку персональных данных</div>
        </label>

        {accept && accept1 ? (
          <div
            className="buttonTo"
            onClick={async () => {
              if (loading == true) return;
              setLoading(true);
              const res = await appAPI.setOrder(window.Telegram.WebApp.initDataUnsafe.user.id, goodInfo.id, getQueryParams("ref") || "");
              console.log(res);
              if (res.success === true) {
                toast.success("Сделка создана");
                setLoading(false);
                nav(`/deal/${res.orderID}`);
              } else {
                toast.error("Попробуйте позже");
              }
            }}
          >
            Продолжить
          </div>
        ) : (
          <div className="buttonTo" style={{ background: "lightgray" }}>
            Продолжить
          </div>
        )}

        <div style={{margin:"10px 0", textAlign: "center"}} onClick={() => window.open("https://vkvideo.ru/video216729851_456239033?list=ln-rkHrzZpzvQRQoi79J7")}>Как работает инструкция</div>

        {/*<iframe src="https://vkvideo.ru/video_ext.php?oid=216729851&id=456239033&hd=2&hash=8a16bfe3b70aef31" className="video__iframe" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>*/}

      </div>
    </>
  );
};
export default Conditions;
