import { Button } from "@telegram-apps/telegram-ui";
import { useEffect } from "react";

export function MainButton({
  text = "Продолжить",
  onClick,
  disabled,
  loading,
}) {
  /*
    let MainButton = window.Telegram?.WebApp?.MainButton

    useEffect(() => {
        if(!MainButton) return 
        MainButton.setParams({
            position: "top"
        })
        MainButton?.show()
        MainButton?.onClick(onClick)
        MainButton?.setText(text)

        return () => {
            MainButton?.hide()
            MainButton?.offClick(onClick)
        }
        
    }, [onClick])

    useEffect(() => {
        if(!MainButton) return 
        if(loading) {
            MainButton?.showProgress()
        } else {
            MainButton?.hideProgress()
        }
        
    }, [loading])


    useEffect(() => {
        if(disabled) {
            MainButton?.disable()
        } else {
            MainButton?.enable()
        }
    }, [disabled])*/

  return (
    <Button
      stretched
      className="order__accept__button"
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {text}
    </Button>
  );
}

export function SecondaryButton({ text = "Отклонить", onClick, loading, disabled }, ...options) {
  /*
    let SecondaryButton = window.Telegram?.WebApp?.SecondaryButton
    useEffect(() => {
        if(!SecondaryButton) return 
        SecondaryButton.setParams({
            position: "bottom",
            text_color: "#FFFFFF",
            color: "#DC143C"
        })
        SecondaryButton?.show()
        SecondaryButton?.onClick(onClick)
        SecondaryButton?.setText(text)
        return () => {
            SecondaryButton?.hide()
            SecondaryButton?.offClick()
        }
        
    }, [])

    useEffect(() => {
        if(!SecondaryButton) return 
        if(loading) {
            SecondaryButton?.showProgress(loading)
            SecondaryButton?.disable()
        } else {
            SecondaryButton?.hideProgress()
            SecondaryButton?.enable()
        }
        
    }, [loading])*/

  return (
    <Button
      stretched
      mode="gray"
      className="order__accept__button"
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {text}
    </Button>
  );
}
