import React, { useEffect, useState } from "react";
import appAPI, { baseURL } from "../../api/service";
import Back from "../helpers/back";
import Loader from "../helpers/loader/loader";
import { Avatar, List, Modal, Section, Cell } from "@telegram-apps/telegram-ui";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { renderElement } from "../../helpers/renderElementRichText";
import OrdersChart from "../../helpers/ChartOrders";
import toast from "react-hot-toast";
import { isAdmin } from "../../App";

const ChannelsWithOffers = ({ userInfo }) => {
  
  const [offers, setOffers] = useState(null);
  const [channels, setChannels] = useState([]); // Все каналы
  const [filteredChannels, setFilteredChannels] = useState([]); // Отфильтрованные каналы
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        if (isAdmin) {
          const response = await appAPI.getAdminGoodsUser(1, 1000, {
            article: "",
            status: "active",
            isAdmin
          });
          setOffers(response.data.sort((a, b) => {
            const dateA = new Date(a.attributes.createdAt);
            const dateB = new Date(b.attributes.createdAt);
            return dateB - dateA;
          }));
        } else {
          setOffers([
            ...userInfo.attributes.offers.data,
            ...userInfo.attributes.work_offers.data,
          ].sort((a, b) => {
            const dateA = new Date(a.attributes.createdAt);
            const dateB = new Date(b.attributes.createdAt);
            return dateB - dateA;
          }));
        }
        const response1 = await appAPI.getChannels();
        if (!response1.data || response1.error) {
          return toast.error(response1.error);
        }
        setChannels(response1.data.sort((a,b) => a.attributes.referrals_orders.data.length < b.attributes.referrals_orders.data.length));
        setFilteredChannels(response1.data.sort((a,b) => a.attributes.referrals_orders.data.length < b.attributes.referrals_orders.data.length)); 
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, []);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // Логика фильтрации
  useEffect(() => {
    if (!search) {
      setFilteredChannels(channels); // Если поиск пуст, показываем все каналы
    } else {
      const filtered = channels.filter((channel) =>
        channel?.attributes?.username
          ?.toLowerCase()
          .includes(search.toLowerCase())
      );
      setFilteredChannels(filtered); // Обновляем стейт отфильтрованных данных
    }
  }, [search, channels]);


  if (offers === null || channels === null) return <Loader />;
  return (
    <List className={`main_container`}>
      <Back />
      <div className="search_block">
        <label
          className={`input_search ${isFocused && "active_input"}`}
          tabIndex="0"
        >
          <div className={`search_icon`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z" />
            </svg>
          </div>
          <input
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {search !== "" && (
            <div
              className="close_icon"
              onClick={() => {
                setSearch("");
                setFilteredChannels(channels); // Сброс фильтрации
              }}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" fill="none" />
                <path
                  d="M7 17L16.8995 7.10051"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 7.00001L16.8995 16.8995"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </label>
      </div>

      {filteredChannels.length > 0 && (
        <Section header="Channels for post">
          {filteredChannels.map((channel) => (
            <Cell
              key={channel.id}
              description={
                "Оформлено сделок: " +
                channel?.attributes?.referrals_orders?.data?.filter((t) => t.attributes.step6Date !== null).length
              }
              onClick={() => setSelectedChannel(channel)}
            >
              {channel?.attributes?.username}
            </Cell>
          ))}
        </Section>
      )}
      <Modal
        style={{ zIndex: "1000000000" }}
        header={<ModalHeader>Информация о канале</ModalHeader>}
        open={selectedChannel}
        onOpenChange={(open) => {
          if (!open) setSelectedChannel(null);
        }}
      >
        <>
          <List style={{ paddingBottom: "30px" }}>
            {selectedChannel?.attributes?.channel_information && (
              <div>
                {selectedChannel?.attributes.channel_information?.map(
                  (element, index) => (
                    <React.Fragment key={index}>
                      {renderElement(element)}
                    </React.Fragment>
                  )
                )}
              </div>
            )}
            {selectedChannel?.attributes?.referrals_orders?.data &&
              selectedChannel?.attributes?.referrals_orders.data.length > 0 && (
                <>
                  <p
                    style={{
                      margin: "15px 0",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    Кол-во выкупов
                  </p>
                  <OrdersChart
                    orders={selectedChannel?.attributes.referrals_orders.data}
                  />
                </>
              )}
            {offers &&
              offers.sort((a, b) => {
                const aActive = a.work?.attributes?.inCatalog ? 1 : 0;
                const bActive = b.work?.attributes?.inCatalog ? 1 : 0;
                return bActive - aActive; 
              }).map((work) => (
                <Cell
                  key={work.id}
                  onClick={async () => {
                    if (loading) return;
                    setLoading(true);
                    try {
                      const res = await appAPI.sendPostInformation({
                        id: work?.id,
                        ref: selectedChannel?.attributes,
                        sendTo: window.Telegram.WebApp.initDataUnsafe.user.id,
                      });
                      if (res?.error) return toast.error(res?.error?.message || "Попробуйте позже");
                      return toast.success("Пост отправлен в чат");
                    } finally {
                      setLoading(false);
                    }
                  }}
                  style={{ padding: "5px 0px" }}
                  subhead={`Осталось сделок: ${work.attributes?.dealsLimit}, ${
                    Number(work?.attributes?.dealsDayLimit) +"/"+
                      (Number(work?.attributes?.dealsDayLimit) -
                      Number(work?.attributes?.dealsDayLimitDisplay))
                  }`}
                  before={
                    <Avatar
                      src={
                        baseURL + work?.attributes?.image?.data?.attributes?.url
                      }
                    />
                  }
                >
                  {work.attributes.name}
                </Cell>
              ))}
          </List>
        </>
      </Modal>
    </List>
  );
};

export default ChannelsWithOffers;
