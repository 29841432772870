export const getOrderStep = (step) => {
    const steps = {
        1: "Скрин корзины",
        2: "Найдите товар",
        3: "Добавьте товар в избранное",
        4: "Укажите реквизиты",
        5: "Оформите заказ",
        6: "Получение товара",
        7: "Оставьте отзыв",
        8: "Отчет на проверке"
    };

    return steps[step] || step;
};

