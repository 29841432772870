import { useLocation, useNavigate, useParams } from "react-router-dom"
import Loader from "../helpers/loader/loader";
import { useEffect, useState } from "react";
import appAPI, { baseURL } from "../../api/service";
import Back from "../helpers/back";
import toast from "react-hot-toast";
import axios from "axios";
import moment from 'moment-timezone';


export const BlogerCreate = ({userInfo}) => {
  const nav = useNavigate();

  const [success, setSuccess] = useState(false)
  const location = useLocation()

  function getQueryParams(data) {
    return new URLSearchParams(location.search).get(data) ? new URLSearchParams(location.search).get(data) : ""
  }

  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);

  const [values, setValues] = useState({
    nameBloger: "",
    networkName: "Instagram",
    channelName: "", 
    networkLink: "", 
    themeChannel: "", 
    targetAudience: "", 
    region: "", 
    priceText:"", 
    img1: "-",
    img2: "-",
    img3: "-",
  });
  const [loading, setLoading] = useState(false)

  const handleImageUpload = (image, setImageFunc, imageKey) => {
    if (!image) return;

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setImageFunc({ img: fileReader.result, file: image });
      setValues((prevValues) => ({
        ...prevValues,
        [imageKey]: image,
      }));
    };
    fileReader.readAsDataURL(image);
  };

  const handleSetImage = (image) => handleImageUpload(image, setImage, "img1");
  const handleSetCheck = (image) => handleImageUpload(image, setImage2, "img2");
  const handleSetImage3 = (image) => handleImageUpload(image, setImage3, "img3")


  let toastId;


  const handleSubmit = async () => {
    if(loading === true) return 

    console.log(values)
    
    const isAllFieldsFilled = Object.values({...values}).every(
      (val) => val !== "" && val !== null
    );

    if (isAllFieldsFilled/* && image && image2 && image3*/) {
      

      setLoading(true)
      try {
        toastId = toast.loading("Загрузка")
        /*const formData = new FormData();
        formData.append("files", image.file);

        const response = await axios.post(`${baseURL}/api/upload`, formData, {
          headers: {
            Authorization:
              "Bearer c6d135cb58633d18a966ec9789671870117d4e50877c51f82c286ecf76b0d1798f4a5b25822c07bc03867828e6b64dc82b741001383859a9e98adf1526f5b000f16b6ecd42ed1bba3a7374dc8ed44e3ec1f0073fdf7ba7bdde6c21a4b4b5cdfe4df3375d2116903dbbea527797e10d7fbbd9b24b1785077889b542ee45bde900",
            "Content-Type": "multipart/form-data",
          },
        });

        if (!response.data[0]) {
          return toast.error("Ошибка при загрузке картинки");
        }

        const formData1 = new FormData();
        formData1.append("files", image2.file);

        const response1 = await axios.post(`${baseURL}/api/upload`, formData1, {
            headers: {
              Authorization:
                "Bearer c6d135cb58633d18a966ec9789671870117d4e50877c51f82c286ecf76b0d1798f4a5b25822c07bc03867828e6b64dc82b741001383859a9e98adf1526f5b000f16b6ecd42ed1bba3a7374dc8ed44e3ec1f0073fdf7ba7bdde6c21a4b4b5cdfe4df3375d2116903dbbea527797e10d7fbbd9b24b1785077889b542ee45bde900",
              "Content-Type": "multipart/form-data",
            },
        });

        if (!response1.data[0]) {
            return toast.error("Ошибка при загрузке картинки");
        }

        console.log(image3)
        const formData2 = new FormData();
        formData2.append("files", image3.file);

        const response2 = await axios.post(`${baseURL}/api/upload`, formData2, {
            headers: {
              Authorization:
                "Bearer c6d135cb58633d18a966ec9789671870117d4e50877c51f82c286ecf76b0d1798f4a5b25822c07bc03867828e6b64dc82b741001383859a9e98adf1526f5b000f16b6ecd42ed1bba3a7374dc8ed44e3ec1f0073fdf7ba7bdde6c21a4b4b5cdfe4df3375d2116903dbbea527797e10d7fbbd9b24b1785077889b542ee45bde900",
              "Content-Type": "multipart/form-data",
            },
        });

        if (!response2.data[0]) {
            return toast.error("Ошибка при загрузке картинки");
        }
*/

        const data = {
            blogerName: values.nameBloger,
            socialNetworkName: values.networkName,
            socialNetworkLink: values.networkLink,
            channelName: values.channelName,
            themeChannel: values.themeChannel,
            targetAudience: values.targetAudience,
            region: values.region,
            priceText: values.priceText,
            owner: userInfo.id,
            userID: window.Telegram.WebApp.initDataUnsafe.user.id,
            status: "Новая",
            image1url: "https://api.wbcashback.ru/uploads/2024_09_09_16_20_25_d6c217b3c5.jpg" /*`${baseURL}${response[0].url}`*/,
            image2url: "https://api.wbcashback.ru/uploads/2024_09_09_16_20_25_d6c217b3c5.jpg" /*`${baseURL}${response1[0].url}`*/,
            image3url: "https://api.wbcashback.ru/uploads/2024_09_09_16_20_25_d6c217b3c5.jpg" /*`${baseURL}${response2[0].url}'*/,
            image1: 37308 /*response[0].id*/,
            image2: 37308 /*response1[0].id*/,
            image3: 37308 /*response2[0].id*/,
            createdAt:  moment.tz("Europe/Moscow").format(),
            adminUsernameFrontend: window?.Telegram?.WebApp?.initDataUnsafe?.user?.username || "not-found-username"
        };

        if(getQueryParams("ref")) {
          data["refID"] = Number(getQueryParams("ref"))
        }

        

        const res = await appAPI.createBloger(data);
        if (!res || res.data === null) {
          return toast.error("Ошибка при создании");
        }
        toast.dismiss()
        setSuccess(true)
        return toast.success("Успешно");
      } catch (error) {
        console.error(error);
        toast.error("Ошибка при создании");
      } finally {
        setLoading(false)
        toast.dismiss(toastId)
        toastId = ""
      }
    } else {
      toast.error("Заполните все поля");
    }
  };

  return (
    
    <div style={{paddingLeft:"16px", paddingRight:"16px"}}>
      {!success ? <>
      <div
        className="title_page"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width:"100%"
        }}
      >
        <div style={{color:"transparent"}}>Создать</div>
        <div>Анкета блогера</div>
        <div
          onClick={handleSubmit}
          style={{
            width: "fit-content",
            textAlign: "end",
            cursor: "pointer",
            fontSize: "0.75rem",
            color: "#731358",
            fontWeight: "600",
            textTransform: "uppercase",
            background:"white",
            padding:'10px',
            borderRadius:"10px"
          }}
        >
          Отправить
          </div>
      </div>
      
      <div className="input_question_block">
        <label className="input_question">
          <div>
            Имя блогера
              <div className="require_question">Обязательное</div>
            </div>
          <input
            type="text"
            placeholder="Введите имя блогера"
            value={values.nameBloger}
            onChange={(e) => setValues({ ...values, nameBloger: e.target.value })}
          />
        </label>
      </div>

      <div className="input_question_block">
        <label className="input_question">
          <div>
          Название соцсети
              <div className="require_question">Обязательное</div>
            </div>
          <select
            value={values.networkName}
            onChange={(e) => setValues({ ...values, networkName: e.target.value })}
          >
            <option value="Instagram">Instagram</option>
            <option value="ВКонтакте">ВКонтакте</option>
            <option value="YouTube">YouTube</option>
            <option value="TikTok">TikTok</option>
            <option value="Telegram">Telegram</option>
            <option value="Facebook">Facebook</option>
          </select>
        </label>
      </div>

      <div className="input_question_block">
        <label className="input_question">
          <div>
          Название канала
              <div className="require_question">Обязательное</div>
            </div>
          <input
            type="text"
            placeholder="Введите название канала"
            value={values.channelName}
            onChange={(e) => setValues({ ...values, channelName: e.target.value })}
          />
        </label>
      </div>

      <div className="input_question_block">
        <label className="input_question">
          <div>
          Ссылка на соцсеть
              <div className="require_question">Обязательное</div>
            </div>
          <input
            type="text"
            placeholder="Введите ссылку на соцсеть"
            value={values.networkLink}
            onChange={(e) => setValues({ ...values, networkLink: e.target.value })}
          />
        </label>
      </div>

      <div className="input_question_block">
        <label className="input_question">
          <div>
          Тематика канала
              <div className="require_question">Обязательное</div>
            </div>
          <textarea
            placeholder="Введите тематику канала"
            value={values.themeChannel}
            onChange={(e) => setValues({ ...values, themeChannel: e.target.value })}
          />
        </label>
      </div>

      <div className="input_question_block">
        <label className="input_question">
          <div>
          Целевая аудитория
              <div className="require_question">Обязательное</div>
            </div>
          <textarea
            placeholder="Опишите целевую аудиторию"
            value={values.targetAudience}
            onChange={(e) => setValues({ ...values, targetAudience: e.target.value })}
          />
        </label>
      </div>

      <div className="input_question_block">
        <label className="input_question">
          <div>
              Прайс
              <div className="require_question">Обязательное</div>
            </div>
          <textarea
            placeholder="Прайс за ваши услуги"
            value={values.priceText}
            onChange={(e) => setValues({ ...values, priceText: e.target.value })}
          />
        </label>
      </div>

      <div className="input_question_block">
        <label className="input_question">
        <div>
             Регион
              <div className="require_question">Обязательное</div>
            </div>
          <input
            type="text"
            placeholder="Укажите регион"
            value={values.region}
            onChange={(e) => setValues({ ...values, region: e.target.value })}
          />
        </label>
      </div>


        {/*<div className="image_question_block">
          <label className="input_question">
            <div>
              Просмотры сторис<div className="require_question">Обязательное</div>
            </div>
            <label>
              <div>
                <div className="image_picker">
                  <div className="image_picker_info">
                    {image && <img className="image_select" src={image.img} />}
                    <div className="text_file_image">
                      {image ? image.file.name : "Выберите изображение..."}
                    </div>
                  </div>
                  <div>
                    {image && (
                      <svg
                        onClick={(e) => {
                          e.preventDefault();
                          setImage(null);
                          setValues({ ...values, img1: null });
                        }}
                        className="image_picker_close"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" fill="none" />
                        <path
                          d="M7 17L16.8995 7.10051"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 7.00001L16.8995 16.8995"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleSetImage(e.target.files[0])}
                placeholder="Выберите изображение..."
                style={{ display: "none" }}
              />
            </label>
          </label>
        </div>





        <div className="image_question_block" style={{marginTop:"10px"}}>
          <label className="input_question">
            <div>
              Просмотры рилс <div className="require_question">Обязательное</div>
            </div>
            <label>
              <div>
                <div className="image_picker">
                  <div className="image_picker_info">
                    {image2 && <img className="image_select" src={image2.img} />}
                    <div className="text_file_image">
                      {image2 ? image2.file.name : "Выберите изображение..."}
                    </div>
                  </div>
                  <div>
                    {image2 && (
                      <svg
                        onClick={(e) => {
                          e.preventDefault();
                          setImage2(null);
                          setValues({ ...values, img2: null });
                        }}
                        className="image_picker_close"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" fill="none" />
                        <path
                          d="M7 17L16.8995 7.10051"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 7.00001L16.8995 16.8995"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleSetCheck(e.target.files[0])}
                placeholder="Выберите изображение..."
                style={{ display: "none" }}
              />
            </label>
          </label>
        </div>


        <div className="image_question_block" style={{marginTop:"10px"}}>
          <label className="input_question">
            <div>
              Общая статистика обхватов <div className="require_question">Обязательное</div>
            </div>
            <label>
              <div>
                <div className="image_picker">
                  <div className="image_picker_info">
                    {image3 && <img className="image_select" src={image3.img} />}
                    <div className="text_file_image">
                      {image3 ? image3.file.name : "Выберите изображение..."}
                    </div>
                  </div>
                  <div>
                    {image3 && (
                      <svg
                        onClick={(e) => {
                          e.preventDefault();
                          setImage3(null);
                          setValues({ ...values, img3: null });
                        }}
                        className="image_picker_close"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" fill="none" />
                        <path
                          d="M7 17L16.8995 7.10051"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 7.00001L16.8995 16.8995"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleSetImage3(e.target.files[0])}
                placeholder="Выберите изображение..."
                style={{ display: "none" }}
              />
            </label>
          </label>
        </div>*/}

        <div
            className="buttonTo"
            onClick={handleSubmit}
            style={{ marginTop: "16px" }}
          >
            Отравить заявку
          </div>

          </> : <div className="container">
        <p>
          <b>Заявка отправлена на модерацию
          </b>
          <br />
          <div
            className="buttonTo"
            onClick={() => window.open("https://t.me/ruslanamantayev")}
            style={{ marginTop: "16px" }}
          >
            Связаться с админом
          </div>
        </p>

        <div
          className="buttonTo"
          onClick={() => window.open("https://t.me/+Nx66BJz6pcxkOWMy")}
          style={{
            marginTop: "16px",
            background: "none",
            color: "black",
            border: "1px solid #731358",
          }}
        >
          Подписаться на канал
        </div>
      </div>}
    </div>
    )
}