import { Navigate } from "react-router-dom";
import Loader from "../helpers/loader/loader";
import { useStatistics } from "./hooks/use-statistics";
import { isAdmin } from "../../App";
import { splitNumber } from "../../helpers/splitNumbers";
import { Cell, Info, List, Section, Text } from "@telegram-apps/telegram-ui";
import Back from "../helpers/back";
import { ChartData } from "./components/chart-data";

export function AdminStatistics({ userInfo }) {
  const { data, loading } = useStatistics();

  if (!isAdmin) return <Navigate to={"/"} />;
  if (loading) return <Loader />;
  return (
    <List>
      <Back />
      <Text weight="2" style={{ display: "block" }}>
        Статистика за 30 дней
      </Text>
      <Section className="section">
        <Cell
          style={{ padding: "0px" }}
          description="Общая сумма к оплате"
          after={
            <Info subtitle="юзеров" type="text">
              {splitNumber(data?.users.length)}
            </Info>
          }
        >
          Новые юзеры
        </Cell>
        {data?.users.length > 0 && <ChartData data={data?.users} field={"createdAt"} />}
        <Cell
          style={{ padding: "0px" }}
          description="Общая сумма к оплате"
          after={
            <Info subtitle="товаров" type="text">
              {splitNumber(data?.offers.length)}
            </Info>
          }
        >
          Новые товары
        </Cell>
        {data?.offers.length > 0 && <ChartData data={data?.offers} field={"createdAt"} />}
        <Cell
          style={{ padding: "0px" }}
          description="Общая сумма к оплате"
          after={
            <Info subtitle="сделок" type="text">
              {splitNumber(data?.orders.length)}
            </Info>
          }
        >
          Новые оформленные сделки
        </Cell>
        {data?.orders.length > 0 && <ChartData data={data?.orders} field={"step5Date"} />}
      </Section>
    </List>
  );
}
